<template>
  <div class="pl-13 pr-13  rounded-card">
    <v-flex>
      <v-dialog
          v-model="dialogLoading"
          persistent
          width="350"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-title primary-title class="justify-center">
            {{ this.loadingText }}
            <v-progress-circular
                :size="50"
                dark
                :width="5"
                color="white"
                indeterminate
            ></v-progress-circular>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-layout align-center justify-space-around row>
      <v-row class="text-muted justify-content-around pb-3">
        <v-col class="p-0">
          Algemene gegevens
          Telefonisten
        </v-col>

      </v-row>

      <v-row id="graphCard" class="mb-2">

      <v-flex md4>
        <v-col >


            <LineChartTeleTeamNoLegend :title="'Sale %'" :data="this.getDataPerField('sale_per')"
                                                 :label="getLabel"></LineChartTeleTeamNoLegend>

        </v-col>
      </v-flex>

      <v-flex  md4>
        <v-col >

            <BarChartTeleTeamNoLegend :title="'Afspraken'" :data="getDataPerField('afspraken')"
                                                 :label="getLabel"></BarChartTeleTeamNoLegend>

        </v-col>
      </v-flex>

      <v-flex md4>
        <v-col>

            <LineChartTeleTeamNoLegend :title="'Live Sale'" :data="getDataPerField('bonus')"
                                                 :label="getLabel"></LineChartTeleTeamNoLegend>

        </v-col>
      </v-flex>
      </v-row>

      <v-row id="graphCard" class="mb-6">

      <v-flex  md6>
        <v-col >
            <LineChartTeleTeamNoLegend :title="'Conversie %'" :data="getDataPerField('conversie')"
                                                 :label="getLabel"></LineChartTeleTeamNoLegend>
        </v-col>
      </v-flex>

      <v-flex  md6>
        <v-col >
            <LineChartTeleTeamNoLegend :title="'Gewerkte Uren'" :data="getDataPerField('working_hours')"
                                                 :label="getLabel"></LineChartTeleTeamNoLegend>

        </v-col>
      </v-flex>
      </v-row>
    </v-layout>

    <v-divider/>

    <v-layout justify-start row>
      <v-row class="text-muted justify-content-around pb-2">
        <v-col>
          Telefonisten overzicht
        </v-col>
      </v-row>


      <v-flex class="pb-2 mb-2 mb-8" md3 v-for="i in this.getUsersOverview" :key="i.username">
        <v-card @click="openTeleUserPage(i); teleUserStatsDialog = true;" class="rounded-card" elevation="1" hover
                ripple outlined>
          <v-col>
            <v-row>
              <v-col cols="8" class="p-0">
                <v-card-title class="pb-0 fix" >
                  {{ i.user }}
                </v-card-title>
              </v-col>

              <v-col cols="4" class="p-0">
                <v-card-subtitle class="text-dark" >
                  {{ Number(getConversie(i.username)).toFixed(2) }} %
                </v-card-subtitle>
              </v-col>
            </v-row>

            <v-divider class="mt-6" inset/>
            <v-card-text class="">Live:</v-card-text>
            <v-row no-gutters class="text-center align-center justify-center">
              <v-col class="text-body-1" col="4">
                {{ getLivePerField(i.username, 'afspraken') }}
                <v-card-subtitle v-if="getLivePerField(i.username, 'afspraken') > 1" class="p-0 m-0">Afspraken
                </v-card-subtitle>
                <v-card-subtitle v-else class="p-0 m-0">Afspraak</v-card-subtitle>
                <v-icon size="25">mdi-handshake-outline</v-icon>
              </v-col>

              <v-col class="text-body-1" col="4">
                {{ getLivePerField(i.username, 'bonus') }}
                <v-card-subtitle class="p-0 m-0">Live Sale</v-card-subtitle>
                <span style="font-size: 125%; color:#008000">⬆</span>
              </v-col>

              <v-col class="text-body-1" col="4">
                {{ Number(getLivePerField(i.username, 'sale_per')).toFixed(2) }}
                <v-card-subtitle class="p-0 m-0">Sale</v-card-subtitle>
                <v-icon size="18">mdi-percent</v-icon>
              </v-col>
            </v-row>

            <v-divider/>

            <v-row class="text-center align-center justify-center">
              <BarChartTeleTeamNoLegend height="200px" :title="'Afspraken'" :data="getData(i, 'afspraken')"
                                                   :label="getDataLabel(i)"></BarChartTeleTeamNoLegend>
            </v-row>


            <v-row class="text-center align-center justify-center">
              <LineChartTeleTeamNoLegend height="200px" :title="'Conversie %'" :data="getData(i, 'conversie')"
                                                   :label="getDataLabel(i)"></LineChartTeleTeamNoLegend>
            </v-row>

          </v-col>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="teleUserStatsDialog">
      <v-card>

        <v-toolbar dark
                   dense
                   color="primary">
          {{ this.selectedTeleUser }}
          <v-spacer/>
          <v-card-actions>
            <v-btn
                text
                @click="teleUserStatsDialog = false; selectedTeleUser = ''; selectedTeleUsername = ''"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-toolbar>

        <TelefonistKpi :teleUser="selectedTeleUsername"/>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {Helper as helper} from "@/helper";
import TelefonistKpi from "@/views/viz/TelefonistKpi";
import LineChartTeleTeamNoLegend from "@/views/viz/components/teleTeam/LineChartTeleTeamNoLegend";
import BarChartTeleTeamNoLegend from "@/views/viz/components/teleTeam/BarChartTeleTeamNoLegend";

export default {
  name: "TeleTeamGeneralOverview",
  components: {
    LineChartTeleTeamNoLegend,
    BarChartTeleTeamNoLegend,
    TelefonistKpi,
  },
  data: () => ({
    loadingText: "Gegevens worden verzameld",
    dialogLoading: true,
    teleUserStatsDialog: false,
    selectedTeleUser: '',
    selectedTeleUsername: '',
    currDate: new Date().toISOString().substr(0, 10),

  }),
  beforeCreate() {
    this.dialogLoading = true;
  },
  created() {
    this.$store.dispatch('solar/fetchGeneralOverviewTeleTeam');
    this.dialogLoading = false;
  },
  computed: {
    getUsersOverview() {
      let q = this.$store.getters['solar/getGeneralOverviewTeleTeam'].tele_user_overview;

      return q != null ? q : [];
    },
    getOverview() {
      let q = this.$store.getters['solar/getGeneralOverviewTeleTeam'].tele_team_overview;

      return q != null ? q : [];
    },
    getLabel() {
      let q = this.$store.getters['solar/getGeneralOverviewTeleTeam'].tele_team_overview;

      if (q) {

        return q.map(qq => new Date(qq.planning_date).getFullYear() + " " + this.getMonthName(qq.planning_date));
      }

      return null;
    },

  },
  methods: {
    getMonthName(date) {
      return helper.extractMonth(date);
    },
    getDataPerField(field) {
      let q = this.getOverview

      if (q) {
        let gg = {
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          fill: false,
          data: q.map(qq => Number(qq[field]).toFixed(2))
        }
        return [gg];
      }

      return [];
    },
    getTotalPerField(user, field) {
      let q = this.getUsersOverview.filter(w => w.username === user);
      return q.map(e => e.user_overview.map(t => t[field]).reduce((a, b) => a + b))[0];
    },
    getConversie(user) {
      let q = this.getUsersOverview.filter(w => w.username === user);

      let afspraken = q.map(e => e.user_overview.map(t => t['afspraken']).reduce((a, b) => a + b, 0))[0];
      let wokring_hours = q.map(e => e.user_overview.map(t => t['working_hours']).reduce((a, b) => a + b, 0))[0]

      return afspraken / wokring_hours;
    },
    getData(user, field) {
      let q = user.user_overview;


      if (q) {
        let gg = {
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          fill: false,
          data: q.map(qq => Number(qq[field]).toFixed(2))
        }
        return [gg];
      }

      return [];
    },
    getDataLabel(user) {
      let q = user.user_overview;

      if (q) {

        return q.map(qq => new Date(qq.planning_date).getFullYear() + " " + this.getMonthName(qq.planning_date));
      }

      return null;
    },
    getLivePerField(user, field) {
      let q = this.getUsersOverview.filter(w => w.username === user);

      let totaal = q.map(e => e.user_overview.filter(
          t => new Date(t['planning_date']).getMonth() === new Date().getMonth()
              && new Date(t['planning_date']).getFullYear() === new Date().getFullYear())
          .map(r => r[field]))[0][0]

      return totaal != null ? totaal : 0;
    },
    openTeleUserPage(i) {
      this.selectedTeleUser = i.user;
      this.selectedTeleUsername = i.username;
    }
  },
}
</script>

<style scoped>

#graphCard {
  background: rgba(255, 255, 255, 0.96);
  border-radius: 16px;
}

#primaryText{
  color: #ED6D28;
}


.fix {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}

.rounded-card {
  border-radius: 16px;
}


</style>
