<template>
  <v-container class="mt-0 pt-0">
    <v-date-picker
        v-model="picker"
        type="month"
        width="100%"
        @change="updateDate"
        no-title
    ></v-date-picker>

    <!--                  :single-expand="singleExpand"-->
    <!--                  :expanded.sync="expanded"-->
    <!--                  show-expand-->
    <v-data-table v-if="getUsersOverview"

                  :headers="headers"
                  :items="getUsersOverview"
                  class="elevation-6"
                  item-key="name"
                  :search="search"
                  :custom-filter="filterText"
                  @item-expanded="updateUser"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Zoeken"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.netto="{item}">

        {{ item.netto }}

      </template>

      <template v-slot:item.days="{item}">

        {{ item.days }}

      </template>

      <template v-slot:item.afspraak="{item}">

        {{ item.afspraak }}

      </template>

      <template v-slot:item.sale="{item}">

        {{ item.sale }}

      </template>

      <template v-slot:item.afspraak_per="{item}">

        {{ roundPercentage(item.afspraak_per) }} %

      </template>

      <template v-slot:item.sale_per="{item}">

        {{ roundPercentage(item.sale_per) }} %

      </template>

      <template slot="body.append">

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}" class="font-weight-bold">
          <v-layout justify-center>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}" v-text="'Totaal'"/>
          </v-layout>

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
          <td class="font-weight-bold" v-text="'Netto: '"/>
          <v-layout justify-center>
            <v-chip>
              <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                  v-text="this.getTotalField('netto')"/>
            </v-chip>
          </v-layout>
        </tr>

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
          <td class="font-weight-bold" v-text="'Dagen: '"/>
          <v-layout justify-center>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                v-text="this.getTotalField('days')"/>
          </v-layout>
        </tr>

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
          <td class="font-weight-bold" v-text="'Afspraken: '"/>
          <v-layout justify-center>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                v-text="this.getTotalField('afspraak')"/>
          </v-layout>
        </tr>

        <tr v-if="this.isMobile" :class="{'v-data-table__mobile-table-row':this.isMobile}">
          <td class="font-weight-bold" v-text="'Sale: '"/>
          <v-layout justify-center>
            <td :colspan="`${this.isMobile?6:1}`" :class="{'v-data-table__mobile-row':this.isMobile}"
                v-text="this.getTotalField('sale')"/>
          </v-layout>
        </tr>

        <tr v-if="this.isMobile" :class="{'v-data-table__mobile-table-row':this.isMobile}">
          <td class="font-weight-bold" v-text="'Afspraak %: '"/>
          <v-layout justify-center>
            <td :colspan="`${this.isMobile?6:1}`" :class="{'v-data-table__mobile-row':this.isMobile}"
                v-text="Number(this.getTotalPer('afspraak')).toFixed(2)"/>
          </v-layout>
        </tr>

        <tr v-if="this.isMobile" :class="{'v-data-table__mobile-table-row':this.isMobile}">
          <td class="font-weight-bold" v-text="'Sale %: '"/>
          <v-layout justify-center>
            <td :colspan="`${this.isMobile?6:1}`" :class="{'v-data-table__mobile-row':this.isMobile}"
                v-text="Number(this.getTotalPer('sale')).toFixed(2)"/>
          </v-layout>
        </tr>


        <tr v-else>

          <td v-text="'Totaal'" class="font-weight-bold"/>

          <td v-text="'🔆'"/>
          <td v-text="this.getTotalField('netto')"/>
          <td v-text="this.getTotalField('days')"/>
          <td v-text="this.getTotalField('afspraak')"/>
          <td v-text="this.getTotalField('sale')"/>


          <td v-text="Number(this.getTotalPer('afspraak')).toFixed(2)"/>

          <td v-text="Number(this.getTotalPer('sale')).toFixed(2)"/>


        </tr>

      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: "UserOverviewKpi",
  components: {},
  data() {
    return {
      expanded: [],
      curUser: '',
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      singleExpand: true,
      search: '',
      headers: [
        {text: 'Naam', value: 'name'},
        {text: 'Team', value: 'user_id__team_id__team'},
        {text: 'Netto leads', value: 'netto'},
        {text: 'Aantal dagen', value: 'days'},
        {text: 'Afspraak', value: 'afspraak'},
        {text: 'Sale', value: 'sale'},
        {text: 'Afspraak %', value: 'afspraak_per'},
        {text: 'Sale %', value: 'sale_per'},
        // {text: 'Details', value: 'data-table-expand'},
      ],
    }
  },
  async mounted() {
    let date = this.picker;
    await this.$store.dispatch("leads/getUserOverviewKpi", {date});
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    getUsersOverview() {
      return this.$store.getters["leads/userOverviewKpi"].user_overview != null
          ? this.$store.getters["leads/userOverviewKpi"].user_overview.details_overview
          : null;
    },
    getScores() {
      return this.$store.getters["leads/userOverviewKpi"].user_overview != null
          ? this.$store.getters["leads/userOverviewKpi"].user_overview
          : null;
    }
  },
  methods: {
    roundPercentage(percentage) {
      return percentage !== null ? parseFloat(percentage).toFixed(2) : 0;
    },
    filterText(value, search) {
      const regex = /\(\w.+\)/i;

      return search != null && typeof value === 'string' &&
          value.toString().replace(regex, "").toLocaleLowerCase().indexOf(search) !== -1
    },
    async refreshList(date) {
      await this.$store.dispatch("leads/getUserOverviewKpi", {date});
    },
    updateUser(a) {
      this.curUser = a.item.name;
      this.getUserStats();
    },
    updateDate(a) {
      this.picker = a;
      this.refreshList(this.picker);
      this.getUserStats();
    },
    async getUserStats() {
      let user = this.curUser;
      let date = this.picker;
      await this.$store.dispatch('leads/getUserStats', {user, date});
    },
    getTotalField(fieldName) {
      return this.getUsersOverview.map(q => q[fieldName]).reduce((a, b) => a + b, 0)
    },
    getTotalPer(fieldName) {
      return (this.getUsersOverview.map(q => q[fieldName]).reduce((a, b) => a + b, 0) * 100) / this.getTotalField('netto')
    }
  }
}
</script>

<style scoped>

</style>