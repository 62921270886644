<template>

  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="slide-x-transition"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-btn
          color="secondary"
          dark
          large
          v-bind="attrs"
          v-on="on"
          id="lead-aanmaken"
      >
        {{ leadinvoertext }}
        <v-icon size="35" class="ml-3">mdi-heat-pump-outline</v-icon>
      </v-btn>

    </template>

    <v-card>
      <v-toolbar
          dark
          color="secondary"
      >
        <v-btn
            icon
            dark
            @click="dialog = false"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title color="secondary">{{ nieuweLeadText }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!--    START FORM    -->
      </v-toolbar>
      <v-form ref="leadFormRef" lazy-validation>
        <v-container justify="center">
          <v-row justify="center" align="center">

            <v-banner><b>Aanhef</b></v-banner>
            <v-col cols="12" md="10">
              <v-radio-group v-model="form.fields.aanhef"
                             :rules="form.rules.verplicht"
                             row
              >
                <v-radio v-for="n in refTable.Aanhef" :key=n.id
                         :label=n.optie
                         :value=n.optie
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-banner><b>Persoonlijke gegevens</b></v-banner>
            <v-col cols="6" md="2" sm="2" xs="3">
              <v-text-field
                  v-model="form.fields.voorletters"
                  :rules="form.rules.voorlettersRules"
                  label="Voorletter(s)"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="3" sm="3" xs="3">
              <v-text-field
                  v-model="form.fields.tussenvoegsel"
                  :rules="form.rules.tussenvoegselRules"
                  label="Tussenvoegsel"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5" sm="6" xs="12">
              <v-text-field
                  v-model="form.fields.achternaam"
                  :rules="form.rules.achernaamRules"
                  label="Achternaam"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="4" sm="4" xs="8">
              <v-text-field
                  v-model="form.fields.postcode"
                  :rules="form.rules.postcodeRules"
                  label="Postcode"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="3" sm="4" xs="6">
              <v-text-field
                  v-model="form.fields.huisnummer"
                  :rules="form.rules.huisnummerRules"
                  label="Huisnummer"
              ></v-text-field>
            </v-col>

            <v-col cols="8" md="3" sm="4" xs="8">
              <v-text-field
                  v-model="form.fields.huisNrToevoeging"
                  :rules="form.rules.huisNrToevoegingRules"
                  label="Nummer toevoeging"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="10">
              <v-text-field
                  v-model="form.fields.tel_mobiel"
                  :rules="form.rules.mobielnummerRules"
                  label="Mobiel Nummer"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="10">
              <v-text-field
                  v-model="form.fields.tel_vast"
                  :rules="form.rules.vastnummerRules"
                  label="Vast Nummer"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="10">
              <v-text-field
                  v-model="form.fields.emailaddress"
                  :rules="form.rules.emailRules"
                  label="Email"
              ></v-text-field>
            </v-col>

            <v-banner><b>Type verwarming</b></v-banner>
            <v-col cols="12" md="10">
              <v-radio-group v-model="form.fields.typeVerwarming"
                             :rules="form.rules.verplicht"
                             row
              >
                <v-radio v-for="n in refTable.TypeVerwarming" :key=n.id
                         :label=n.optie
                         :value=n.optie
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-banner><b>Type isolatie</b></v-banner>
            <v-col cols="12" md="10">
              <v-radio-group v-model="form.fields.typeIsolatie"
                             :rules="form.rules.verplicht"
                             row
              >
                <v-radio v-for="n in refTable.TypeIsolatie" :key=n.id
                         :label=n.optie
                         :value=n.optie
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-banner><b>Huidig gasverbruik</b></v-banner>
            <v-col cols="1" md="3">
              <v-text-field  v-model="form.fields.gasverbruik"
                             :rules="form.rules.verplicht"
                             type="number" label="m³" >

              </v-text-field>
            </v-col>

            <v-banner><b>Extra gegevens</b></v-banner>
            <v-col cols="12" md="10">
              <v-textarea
                  v-model="form.fields.comment"
                  :rules="form.rules.commentRules"
                  label="Opmerking"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="10" align="right">
              <v-btn
                  id="saveBtn"
                  elevation="2"
                  large
                  color="secondary"
                  :disabled="!form.valid"
                  @click="leadOpslaan()"
                  :loading="this.loading"
              >
                {{ this.opslaanText }}
              </v-btn>
            </v-col>

          </v-row>

        </v-container>

      </v-form>

      <!--   END FORM   -->

    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['refTable'],
  name: "lead-form-duurzaam-warmtepomp",

  data() {
    return {
      leadinvoertext: "Lead Warmtepomp",
      nieuweLeadText: "Nieuwe lead Warmtepomp",
      opslaanText: "Lead aanmaken",
      dialog: false,
      loading: false,

      form: {
        valid: true,
        rules: {
          verplicht: [
            v => !!v || 'Optie is Verplicht'
          ],
          voorlettersRules: [
            v => {
              if (v) {
                v => /^[a-zA-Z -]+$/.test(v) || 'Ongeldige voorletter(s)'
              }
            }
          ],
          tussenvoegselRules: [
            v => {
              if (v) {
                v => /^[a-zA-Z -]+$/.test(v) || 'Ongeldige tussenvoegsel'
              }
            }
          ],
          achernaamRules: [
            v => !!v || 'Achternaam is verplicht',
            v => /^[a-zA-Z -]+$/.test(v) || 'Ongelidge achternaam',
          ],
          postcodeRules: [
            v => !!v || 'Postcode is verplicht',
            v => /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(v) || 'Ongelidge postcode',
          ],
          huisnummerRules: [
            v => !!v || 'Huisnummer is verplicht',
            v => Number(v) > 0 || 'Ongeldige huisnummer',
          ],
          huisNrToevoegingRules: [
            v => {
              if (v) {
                if (v.length < 7) {
                  return true;
                } else {
                  return 'Ongeldige Toevoeging';
                }
              }
              return true;
            }
          ],
          mobielnummerRules: [
            v => {
              if (v) {
                if (/^\+(?:[0-9] ?){6,14}[0-9]$/.test(v) || /(06)(\d){8}$/.test(v)) {
                  return true;
                } else {
                  return 'Ongeldige mobiel nummer';
                }
              }
              return true;
            }
          ],
          vastnummerRules: [
            v => {
              if (v) {
                if (/^((\+|00(\s|\s?\\-\s?)?)31(\s|\s?\\-\s?)?(\(0\)?)?|0)[1-9]((\s|\s?\\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(v)) {
                  return true;
                } else {
                  return 'Ongeldig Nederlands vast telefoonnummer';
                }
              }
              return true;
            },
          ],
          emailRules: [
            // v => !!v || 'E-mail is verplicht',
            v => {
              if (v) {
                if (/.+@.+\..{2,}/.test(v)) {
                  return true;
                } else {
                  return 'Ongeldig e-mail';
                }
              }
              return true;
            },
          ],
          commentRules: [
            v => {
              if (v) {
                if (v.length < 500) {
                  return true;
                } else {
                  return 'Kifesh ben je boek auteur geworden?';
                }
              }
              return true;
            }
          ],
        },
        fields: {
          typeLead: "wp",
          aanhef: '',
          voorletters: '',
          tussenvoegsel: '',
          achternaam: '',
          postcode: '',
          huisnummer: '',
          huisNrToevoeging: '',
          tel_mobiel: '',
          tel_vast: '',
          emailaddress: '',
          typeVerwarming: '',
          typeIsolatie: '',
          gasverbruik: '',
          comment: '',
          is_duurzaam_warmtepomp: 1
        }
      }
    }
  },
  methods: {
    async leadOpslaan() {

      if (this.$refs.leadFormRef.validate()) {
        this.loading = true;
        if (this.form.fields.tel_mobiel === '' && this.form.fields.tel_vast === '') {
          this.$fire({
            position: 'top-end',
            type: 'warning',
            text: "Mobiel of vast nummer verplicht",
            showConfirmButton: false,
            timer: 2000
          })
          this.loading = false;
          return false;
        }

        this.form.fields.postcode = this.form.fields.postcode.toUpperCase();
        this.form.fields.postcode = this.form.fields.postcode.replaceAll(" ", "");


        await this.$store.dispatch('leads/postLead', this.form.fields).then(res => {

          if (res.status === 206) {
            this.$fire({
              position: 'top-end',
              type: 'warning',
              text: 'LET OP !! Lead is aangemaakt maar heeft aandacht nodig. Noteer de volgende cijfers op je lead blaadje: '
                  + Object.keys(res.data.error)[0],
              showConfirmButton: true
            })
          } else {
            this.$fire({
              position: 'top-end',
              type: 'success',
              text: 'Lead aangemaakt',
              showConfirmButton: false,
              timer: 2000
            });
          }
          this.loading = false;
          this.$refs.leadFormRef.reset();
          this.dialog = false;
        }).catch(err => {
          if (err.response.status === 409) {
            this.$fire({
              position: 'top-end',
              type: 'warning',
              text: err.response.data.error,
              showConfirmButton: false,
              timer: 3000
            })
          } else {
            this.$fire({
              position: 'top-end',
              type: 'error',
              text: ('Server fout: neem contact op met je teamleider', err.response.data),
              showConfirmButton: false,
              timer: 4000
            })
          }

          this.loading = false;
        });

      } else {
        this.$fire({
          position: 'top-end',
          type: 'warning',
          text: 'Niet alle verplichte velden zijn (correct) ingevuld',
          showConfirmButton: false,
          timer: 2000
        })
        this.loading = false;
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');

#lead-aanmaken {
  font-family: Poppins;
  /*position: relative;*/
}

#saveBtn {
  font-family: Poppins;
}


</style>
