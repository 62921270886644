<template>
  <div>
    <v-flex>
      <v-dialog
          v-model="dialog"
          persistent
          width="350"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-title primary-title class="justify-center">
            {{ this.loadingText }}
            <v-progress-circular
                :size="50"
                dark
                :width="5"
                color="white"
                indeterminate
            ></v-progress-circular>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-flex>


    <GmapMap
        :center="this.center"
        :zoom="7"
        mapTypeId='hybrid'
        style='width:100%;  height: 800px;'
    >
      <GmapCluster
          :maxZoom="8"
          :zoomOnClick="true"
      >

        <GmapMarker
            :key="index"
            v-for="(m, index) in this.getGeo"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            @click="openMarker(m.id)"
            @closeclick="openMarker(null)"
            :icon="m.icon"
        >

          <GmapInfoWindow
              :closeclick="true"
              @closeclick="openMarker(null)"
              :opened="openedMarkerID === m.id"
          >
            <div class="pb-2 pr-2">
              <div>
                Lead datum: <b>{{ m.request_date }}</b>
              </div>
              <div v-if="m.datum_adviesgesprek && isAdminOrBeheer">
                Adviesgesprek: <b>{{ m.datum_adviesgesprek }}</b>
              </div>
              <div v-if="isAdminOrBeheer">
                Laatst geüpdated: <b>{{ m.updated_at }}</b>
              </div>
              <div>
                Lead status: <b>{{ m.lead_status }}</b>
              </div>
              <div>
                Huisnummer: <b>{{ m.house_number }}</b>
              </div>
              <div>
                Lead eigenaar: <b>{{ m.lead_owner }}</b>
              </div>
              <div v-if="isAdminOrBeheer">
                Adviseur: <b>{{ m.account_manager }}</b>
              </div>
              <div v-if="isAdminOrBeheer">
                SolarId:
                <b @click="goToSolarWebsite(m.solar_id)">
                  <u>{{ m.solar_id }}</u>
                </b>
              </div>

            </div>
          </GmapInfoWindow>
        </GmapMarker>
      </GmapCluster>
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'MarkerMap',
  components: {},
  data: () => ({
    loadingText: "Markers worden ingeladen...",
    openedMarkerID: null,
    dialog: false,
    // currStatus: [],
    // currAdviseur: [],
    // currOrigin: [],
    // currOwner: [],
    center: {lat: 51.97765118, lng: 5.32806885}
  }),
  async created() {
    this.dialog = true;
    if (this.$store.getters['solar/getGeoCords'].length < 100) {
      let t = "true";
      await this.$store.dispatch('solar/fetchGeoCords', {t} );
      this.dialog = false;
    }

    this.dialog = false;
  },
  computed: {
    isMobile() {
      return this.$isMobile();
    },
    isAdminOrBeheer() {
      return this.$store.getters['auth/getUser'].groups.includes('admin', 'beheer');
    },
    getGeo() {
      return this.$store.getters['solar/getGeoCords'];
    },
  },
  methods: {
    openMarker(id) {
      this.openedMarkerID = id;
    },
  }
}
</script>
