<template>
  <v-container>

    <v-sheet class="d-inline-block ml-8"
             v-for="(item,i) in this.monthWeeklyDaily"
             :key="i"
    >
      <v-card-title>Week {{ i }}</v-card-title>
      <v-card-text>
        <v-timeline
            dense
        >
          <v-timeline-item
              v-for="a in item"
              :key="a.gepland_op"
              small
          >
            <div @click="aa(a)">
              <div class="font-weight-normal">
                <b><u>{{ a.gepland_op }}</u></b>
              </div>
              <div class="font-weight-normal">
                Afspraken: {{a.count}}
              </div>
              <div class="font-weight-normal">
                Sale: {{a.sale}}
              </div>
              <div class="font-weight-normal">
                Gewerkte uren: {{a.working_hours}}
              </div>
              <div class="font-weight-normal">
                  Conversie: {{Number(a.conversie).toFixed(2)}}
              </div>


            </div>
          </v-timeline-item>
        </v-timeline>

      </v-card-text>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: "TeleTeamStats",
  props: ['monthWeeklyDaily'],
  data: () => ({
    headers: [
      {text: 'Naam', value: "name"},
      {text: 'Leads', value: 'leads'}
    ],
  }),
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 350
        case 'sm':
          return 450
        case 'md':
          return 550
        case 'lg':
          return 700
        case 'xl':
          return 800
      }
      return 0;
    }
  },
  methods: {
    aa(){
    //  get afspraken list
    }
  }
}
</script>

<style scoped>
.a {
  width: auto;
}
</style>