<script>
import * as VueChartJs from "vue-chartjs";

export default {
  extends: VueChartJs.Pie,
  props: ['labels', 'data', 'colors', 'title'],
  watch: {
    'data'() {
      this.render()
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
          {
            labels: this.labels,
            datasets: [{
              data: this.data,
              backgroundColor: this.colors,
              borderWidth: [1, 1],
              hoverOffset: 4
            }],

          },
          {
            pieceLabel: {
              mode: 'percentage',
              precision: 1
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              onClick: (e) => e.stopPropagation(),
              display: true,
              position: 'bottom',
              fullWidth: true,
              labels: {
                boxWidth: 10,
                padding: 10,
                font: {
                  family: 'Poppins'
                }
              }
            },
            title: {
              display: true,
              text: this.title,
              fontFamily: 'Poppins'
            }
            , tooltips: {
              mode: 'index',
              enabled: true,
              titleAlign: 'center',
              footerAlign: 'center',
              bodyFontFamily: 'Poppins',
              callbacks: {
                footer: ((tooltipItems, datas) => {

                  let curr = datas.datasets[0].data[tooltipItems[0].index]

                  let totaal = datas.datasets[0].data.reduce((a, b) => a + b, 0);

                  return Number(curr * 100 / totaal).toFixed(2) + " %";
                })
              },
            }
          }
      )
    }
  }
};
</script>

<style scoped>

</style>
