<template>
  <div>
    <v-container class="mt-0 pt-0 mb-0 pb-0">

      <div v-if="this.isAdmin || this.isBeheer" class="pb-2">
        <MonthlyLeadProgressBar :progress="getMonthlyLeadProgressBar"/>
      </div>
      <v-dialog v-model="dialog" :width="width">
        <v-data-table
            :headers="headers"
            :items="getUSerPeriodTableData"
            class="elevation-6"
            item-key="name">
          <template #item.full_name="{ item }">
            {{ item.user_id__first_name }} {{ item.user_id__last_name }}
          </template>

          <template v-slot:item.credit="{item}">

            <v-chip @click="getCreditLeads(item.user_id__first_name, item.user_id__last_name, item.date)">

              {{ item.credit }}

            </v-chip>


          </template>

        </v-data-table>
      </v-dialog>

      <v-dialog v-model="dialogCredit" :width="width">
        <v-data-table
            :headers="headersCredit"
            :items="this.getCreditsUser()"
            class="elevation-6"
            item-key="id">

        </v-data-table>
      </v-dialog>

      <v-layout row class="pb-2">

        <v-flex md3 class="p-2">
          <v-card elevation="7" class="rounded-card">
            <v-container>

              <v-col class="pb-0">
                <div class="primary--text display-2 d-flex">
                  {{ getTotalLeads(getTeamDataDay) }}
                  <v-icon size="40" class="pl-2">mdi-book-account-outline</v-icon>
                </div>
                <h4>Vandaag</h4>
                <h4 class="d-flex justify-start">
                  {{ getTotalPpl(getTeamDataDay) }}
                  <v-icon class="pl-1">mdi-account-group</v-icon>
                </h4>
                <v-divider/>
              </v-col>

              <v-col v-for="i in getTeamDataDay" :key="i.user_id__team_id__team" class="pb-4">
                <strong>{{ i.user_id__team_id__team }}:</strong>

                <v-row>
                  <v-col class="pb-1">
                    {{ i.num_lopers }}
                    <v-icon @click="getUsersPeriodCount(i.user_id__team_id__team, 'today')">mdi-account-group</v-icon>
                  </v-col>
                  <v-col class="">
                    {{ i.id__count }}
                    <v-icon>mdi-book-account-outline</v-icon>
                  </v-col>
                </v-row>

              </v-col>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md3 class="p-2">
          <v-card elevation="7" class="rounded-card">
            <v-container>

              <v-col class="pb-0">
                <div class="primary--text display-2 d-flex">
                  {{ getTotalLeads(getTeamDataYesterday) }}
                  <v-icon size="40" class="pl-2">mdi-book-account-outline</v-icon>
                </div>
                <h4>Gisteren</h4>
                <h4 class="d-flex justify-start">
                  {{ getTotalPpl(getTeamDataYesterday) }}
                  <v-icon class="pl-1">mdi-account-group</v-icon>
                </h4>
                <v-divider/>
              </v-col>

              <v-col v-for="i in getTeamDataYesterday" :key="i.user_id__team_id__team" class="pb-4">
                <strong>{{ i.user_id__team_id__team }}:</strong>

                <v-row>
                  <v-col class="pb-1">
                    {{ i.num_lopers }}
                    <v-icon @click="getUsersPeriodCount(i.user_id__team_id__team, 'yesterday')">mdi-account-group
                    </v-icon>
                  </v-col>
                  <v-col class="">
                    {{ i.id__count }}
                    <v-icon>mdi-book-account-outline</v-icon>
                  </v-col>
                </v-row>

              </v-col>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md3 class="p-2">
          <v-card elevation="7" class="rounded-card">
            <v-container>

              <v-col class="pb-0">
                <div class="primary--text display-2 d-flex">
                  {{ getTotalLeads(getTeamDataWeek) }}
                  <v-icon size="40" class="pl-2">mdi-book-account-outline</v-icon>
                </div>
                <h4>Deze week</h4>
                <h4 class="d-flex justify-start">
                  {{ getTotalPpl(getTeamDataWeek) }}
                  <v-icon class="pl-1">mdi-account-group</v-icon>
                </h4>
                <v-divider/>
              </v-col>

              <v-col v-for="i in getTeamDataWeek" :key="i.user_id__team_id__team" class="pb-4">
                <strong>{{ i.user_id__team_id__team }}:</strong>

                <v-row>
                  <v-col class="pb-1">
                    {{ i.num_lopers }}
                    <v-icon @click="getUsersPeriodCount(i.user_id__team_id__team, 'week')">mdi-account-group</v-icon>
                  </v-col>
                  <v-col class="">
                    {{ i.id__count }}
                    <v-icon>mdi-book-account-outline</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md3 class="p-2">
          <v-card elevation="7" class="rounded-card">
            <v-container>

              <v-col class="pb-0">
                <div class="primary--text display-2 d-flex">
                  {{ getTotalLeads(getTeamDataMonth) }}
                  <v-icon size="40" class="pl-2">mdi-book-account-outline</v-icon>
                </div>
                <h4>Deze maand</h4>
                <h4 class="d-flex justify-start">
                  {{ getTotalPpl(getTeamDataMonth) }}
                  <v-icon class="pl-1">mdi-account-group</v-icon>
                </h4>
                <v-divider/>
              </v-col>

              <v-col v-for="i in getTeamDataMonth" :key="i.user_id__team_id__team" class="pb-4">
                <strong>{{ i.user_id__team_id__team }}:</strong>

                <v-row>
                  <v-col class="pb-1">
                    {{ i.num_lopers }}
                    <v-icon @click="getUsersPeriodCount(i.user_id__team_id__team, 'month')">mdi-account-group</v-icon>
                  </v-col>
                  <v-col class="">
                    {{ i.id__count }}
                    <v-icon>mdi-book-account-outline</v-icon>
                  </v-col>
                </v-row>

              </v-col>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>
    </v-container>

    <v-layout align-center justify-space-around row class="pb-4 mb-4 pl-13 pr-13">
      <v-card elevation="7" class="rounded-graph">

        <BarChart v-if="getTotalMonthlyLeadsPerDayBarChart" :progress="getTotalMonthlyLeadsPerDayBarChart"
                  :height="400">
        </BarChart>
      </v-card>
    </v-layout>

  </div>
</template>


<script>

import MonthlyLeadProgressBar from './components/MontlyLeadProgressBar'
import BarChart from "@/views/viz/components/BarChart";

export default {
  name: "DoorLeads",
  components: {BarChart, MonthlyLeadProgressBar},
  data: () => ({
    dashData: '',
    headersCredit: [
      {text: 'Reden', value: "reden"},
    ],
    dialogCredit: false,
    getTeamDataMonthPpl: '',
    dialog: false,
    expanded: [],
    period: '',
    singleExpand: true,
    search: '',
    headers: [
      {text: 'Naam', value: "full_name", align: "left"},
      {text: 'Leads', value: 'id__count'},
      {text: 'Credit(s)', value: 'credit'}
    ]
  }),
  async mounted() {
    await this.$store.dispatch("leads/getLeadOverview");
  },
  async created() {
    // auto update the store with newest data
    await setInterval(() => {
      this.$store.dispatch("leads/getLeadOverview");
    }, 900000); // 15 min
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 350
        case 'sm':
          return 450
        case 'md':
          return 550
        case 'lg':
          return 700
        case 'xl':
          return 800
      }
      return 0;
    },
    getMonthlyLeadProgressBar() {
      return this.getTotalLeads(this.getTeamDataMonth) != null
          ? this.getTotalLeads(this.getTeamDataMonth) * 100 / 1750
          : 0;
    },
    getTotalMonthlyLeadsPerDayBarChart() {
      return this.$store.getters["leads/leadOverviewData"].user_stats != null
          ? this.$store.getters["leads/leadOverviewData"].user_stats.leads_total_month_per_day
          : null;
    },
    getTeamDataDay() {
      return this.$store.getters["leads/leadOverviewData"].user_stats != null
          ? this.$store.getters["leads/leadOverviewData"].user_stats.team_total_today
          : 0;
    },
    getTeamDataYesterday() {
      return this.$store.getters["leads/leadOverviewData"].user_stats != null
          ? this.$store.getters["leads/leadOverviewData"].user_stats.team_total_yesterday
          : 0;
    },
    getTeamDataWeek() {
      let total = this.$store.getters["leads/leadOverviewData"].user_stats != null
          ? this.$store.getters["leads/leadOverviewData"].user_stats.team_total_week
          : 0;

      return total.length ? total : 0;
    },
    getTeamDataMonth() {
      return this.$store.getters["leads/leadOverviewData"].user_stats != null
          ? this.$store.getters["leads/leadOverviewData"].user_stats.team_total_month
          : 0;
    },
    getUSerPeriodTableData() {
      return this.$store.getters["leads/getUserPeriodCount"] != null
          ? this.$store.getters["leads/getUserPeriodCount"].user_period_count
          : null
    },
    isAdmin() {
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('admin') : false;
    },
    isBeheer() {
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('admin') : false;
    }
  },
  methods: {
    getTotalLeads(leads) {
      return leads.length ? leads.map(item => item.id__count).reduce((prev, next) => prev + next) : 0;
    },
    getTotalPpl(leads) {
      return leads.length ? leads.map(item => item.num_lopers).reduce((prev, next) => prev + next) : 0;
    },
    async getUsersPeriodCount(team, period) {
      await this.$store.dispatch("leads/getUserPeriodCount", {team, period});
      this.period = period;
      this.dialog = true;
    },
    getCreditsUser() {
      let creditList = this.$store.getters["leads/getCreditPerUser"];
      return creditList != null ? creditList : [];
    },
    async getCreditLeads(firstName, lastName, date) {
      let user = firstName + " " + lastName;
      let period = this.period;
      await this.$store.dispatch("leads/getCreditPerUser", {user, date, period});
      this.dialogCredit = true;
    },
  }
}
</script>

<style scoped>

/*.fullWitdh {*/
/*  width: 100%;*/
/*}*/

.rounded-card {
  border-radius: 16px;
}

.rounded-graph {
  background: #fffffff2;
  border-radius: 16px;
}

/*.cardStat {*/
/*  background-color: white;*/
/*}*/

</style>
