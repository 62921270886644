<script>

import {Bar} from "vue-chartjs";
import {Helper as helper} from "@/helper";

export default {
  extends: Bar,
  name: "BarChartAccountManagerSale",
  props: ['data', 'label'],
  watch: {
    'data'() {
      this.render()
    }
  },
  mounted() {
    this.render();
  },
  computed: {

  },
  methods: {

    render() {

      this.renderChart(
          {
            labels: this.label,
            datasets: [
              {

                backgroundColor: "#F27029",
                data: this.data
              }
            ]
          },
          {
            responsive: true, maintainAspectRatio: false,
            scales: {
              xAxes: [{
                categoryPercentage: 0.4,
                barPercentage: 0.4
              }],
              yAxes: [{
                ticks: {
                  min: 0,
                  stepSize: 1
                }
              }]
            },
            tooltips: {
              mode: 'index',
              enabled: true,
              titleAlign: 'center',
              footerAlign: 'center'
            },
            title: {
              display: true,
              text: 'Sales',
              fontFamily: 'Poppins'
            },
            legend: {
              onClick: (e) => e.stopPropagation(),
              display: false,
              position: 'bottom',
              fullWidth: true,
              labels: {
                boxWidth: 10,
                padding: 10,
                fontFamily: 'Poppins'
              }
          }
          }
      );

    },
    getMonthName(date) {
      return helper.extractMonth(date);
    }
  }
}
</script>

<style scoped>

</style>
