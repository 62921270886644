<template>
  <v-container class="mt-0 pt-0">
    <v-date-picker
        v-model="picker"
        type="month"
        width="100%"
        @change="updateDate"
        no-title
        min="2021-06"
    ></v-date-picker>

    <v-data-table v-if="this.getTeamDetailOverview"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        :headers="headers"
        :items="getTeamDetailOverview"
        class="elevation-6"
        item-key="user_id__team__team"
        :search="search"
        :custom-filter="filterText"
        @item-expanded="updateTeam"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Zoeken"
            class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.leads="{item}">
        <v-chip
            :color="getColorLead(item.leads)"
            dark
        >
          {{ item.leads }}
        </v-chip>
      </template>

      <template v-slot:item.netto="{item}">
        <v-chip
            :color="getColorAvgNetto(item.netto)"
            dark
        >
          {{ item.netto }}
        </v-chip>
      </template>

      <template v-slot:item.credit="{item}">
        <v-chip
            :color="calcCreditColor(item.credit)"
            dark
        >
          {{ item.credit }}
        </v-chip>
      </template>

      <template v-slot:item.nettoPercent="{item}">
        <v-chip
            :color="calcNettoPercentColor(calcNettoPercent(item.leads, item.netto))"
            dark
        >
          {{ calcNettoPercent(item.leads, item.netto) }} %
        </v-chip>
      </template>

      <template v-slot:item.days="{item}">
        <v-chip
            :color="getColorAvgDay(item.days)"
            dark
        >
          {{ item.days }}
        </v-chip>
      </template>


      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <TeamLeadStats v-if="getStats" :monthWeeklyDaily="getStats" />
        </td>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>

import TeamLeadStats from "@/views/viz/components/TeamLeadStats";
export default {
  name: "TeamOverview",
  components: {TeamLeadStats},
  data() {
    return {
      expanded: [],
      curTeam: '',
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      singleExpand: true,
      search: '',
      headers: [
        {text: 'Team', value: 'user_id__team__team'},
        {text: 'Leads', value: 'leads'},
        {text: 'Netto leads', value: 'netto'},
        {text: 'Credits', value: 'credit'},
        {text: 'Netto %', value: 'nettoPercent', sortable: false},
        {text: 'Gewerkte dagen', value: 'days'},
        {text: 'Details', value: 'data-table-expand'},
      ],
    }
  },
  async created() {
    let date = this.picker;
    await this.$store.dispatch("leads/getTeamOverview", {date});
  },
  computed: {
    getTeamDetailOverview() {
      return this.$store.getters['leads/teamOverview'].team_overview != null
          ? this.$store.getters['leads/teamOverview'].team_overview.details_overview
          : null;
    },
    getScores() {
      return this.$store.getters["leads/teamOverview"].team_overview != null
          ? this.$store.getters["leads/teamOverview"].team_overview
          : null;
    },
    getStats() {
      return this.$store.getters['leads/teamStats'].team_stats != null
          ? this.$store.getters['leads/teamStats'].team_stats.month_weekly
          : null;
    }
  },
  methods: {
    calcNettoPercentColor(netto){
      if(netto > 99.0) return "green";
      else if (netto <= 95.0 ) return "red";
      else return "orange";
    },
    async refreshList(date) {
      await this.$store.dispatch("leads/getTeamOverview", {date});
    },
    calcNettoPercent(leads, netto) {
      return (( netto / leads ) * 100).toFixed(1);
    },
    calcCreditColor(credit) {
      if (credit > 0) return "red";
      else return "green";
    },
    filterText(value, search) {
      return search != null && typeof value === 'string' &&
          value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },
    updateTeam(a) {
      this.curTeam = a.item.user_id__team__team;
      // update team details by date
      this.getTeamStats()
    },
    calcAverage(numList) {
      return numList.reduce((a, b) => (a + b)) / numList.length;
    },
    getColorLead(leadNum) {
      let avg = this.getScores.lead_avg;
      let max = this.getScores.lead_max;
      let min = this.getScores.lead_min;

      return this.calcColor(max, min, avg, leadNum);
    },
    calcColor(max, min, avg, num) {
      if (num > (max + avg) / 2) return "green"
      else if (num < avg && num > ((min + avg) / 2)) return "orange"
      else if (num < (min + avg) / 2) return "red";
      return "blue";
    },
    getColorAvgNetto(netto) {
      let a = this.getTeamDetailOverview;

      let max = Math.max.apply(Math, a.map(function (o) {
        return o.netto;
      }))
      let min = Math.min.apply(Math, a.map(function (o) {
        return o.netto;
      }))
      let avg = Math.round.apply(Math, a.map(function (o) {
        return o.leads + o.netto;
      })) / a.length

      return this.calcColor(max, min, avg, netto);
    },
    updateDate(a) {
      this.picker = a;
      this.refreshList(this.picker);
      this.getTeamStats();
    },
    getColorAvgDay(avgDayNum) {

      let a = this.getTeamDetailOverview;

      let max = Math.max.apply(Math, a.map(function (o) {
        return o.days;
      }))

      let min = Math.min.apply(Math, a.map(function (o) {
        return o.days;
      }))

      let avg =this.calcAverage(a.map(aa => aa.days))

      return this.calcColor(max, min, avg, avgDayNum);
    },
    async getTeamStats() {
      let date = this.picker;
      let team = this.curTeam;

      await this.$store.dispatch('leads/getTeamStats', {team, date});
    }

  }
}
</script>

<style scoped>
.fullWitdh {
  width: 100%;
}
</style>