<template>
  <v-container class="mt-0 pt-0">
    <v-flex>
      <v-dialog
          v-model="dialogLoading"
          persistent
          width="350"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-title primary-title class="justify-center">
            {{ this.loadingText }}
            <v-progress-circular
                :size="50"
                dark
                :width="5"
                color="white"
                indeterminate
            ></v-progress-circular>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-flex>


    <v-row class="text-muted justify-content-around">
      <v-col>
        {{ this.title + getMonthName(this.currentDate) }}
      </v-col>
      <v-col align="right">
        <v-btn outlined fab class="align-right"
               color="primary"
               @click="dialog = true"
        >
          <v-icon>mdi-filter-plus-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >


      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-toolbar-title>Filter op datum</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <FilteredKpi></FilteredKpi>

      </v-card>
    </v-dialog>


    <v-divider/>

    <v-layout align-center justify-space-around row>

      <v-flex md4 class="p-2">
        <v-card elevation="5" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-2 d-flex justify-start">
                  {{ getGlobalTotal.netto }}

                </div>
                <h4 class="d-flex justify-start">Netto Leads</h4>
                {{ Number(getGlobalTotal.netto_per).toFixed(2) }} %
              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-book-check-outline</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex md4 class="p-2">
        <v-card elevation="5" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-2 d-flex justify-start">
                  {{ getGlobalTotal.afspraken }}
                </div>
                <h4 class="d-flex justify-start">Afspraken</h4>
                <br/>
              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-handshake-outline</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex md4 class="p-2">
        <v-card elevation="5" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-2 d-flex justify-start">
                  {{ getGlobalTotal.sale }}
                </div>
                <h4 class="d-flex justify-start">Sale</h4>
                <br/>
              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-briefcase-check-outline</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex md4 class="p-2">
        <v-card elevation="5" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-2 d-flex justify-start">
                  {{ getGlobalTotal.credit }}
                </div>
                <h4 class="d-flex justify-start">Credit leads</h4>
                {{ Number(getGlobalTotal.credit_per).toFixed(2) }} %
              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-book-cancel-outline</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

<!--      <v-flex md4 class="p-2">-->
<!--        <v-card elevation="5" class="rounded-card">-->
<!--          <v-container fluid>-->
<!--            <v-row>-->
<!--              <v-col>-->
<!--                <div class="primary&#45;&#45;text display-2 d-flex justify-start">-->
<!--                  {{ getGlobalTotal.bruto }}-->
<!--                </div>-->
<!--                <h4 class="d-flex justify-start">Bruto Leads</h4>-->
<!--                <br/>-->
<!--              </v-col>-->

<!--              <v-col align-self="center" class="icons">-->
<!--                <v-icon size="48">mdi-book-account-outline</v-icon>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-container>-->
<!--        </v-card>-->
<!--      </v-flex>-->

      <v-flex md4 class="p-2">
        <v-card elevation="5" class="rounded-card">
          <v-container fluid>
            <v-row>

              <div class="text-capitalize v-btn d-flex justify-content-center">
                Actief personeel
                <v-icon class="persIcon">mdi-account-group-outline</v-icon>
              </div>

              <v-col class="d-flex justify-start">
                <v-chip outlined>
                  {{ getGlobalUsers.lopers }} Enquêteurs
                </v-chip>
                <v-icon class="persIcon">mdi-badge-account-outline</v-icon>
              </v-col>

              <v-col class="d-flex justify-start">
                <v-chip outlined>
                  {{ getGlobalUsers.captain }} Team Captains
                </v-chip>
                <v-icon class="persIcon">mdi-account-supervisor-outline</v-icon>
              </v-col>

              <v-col class="d-flex justify-start">
                <v-chip outlined @click="getTeleTeamUsers">
                  {{ getGlobalUsers.telefonist }} Telefonisten
                </v-chip>
                <v-icon class="persIcon">mdi-phone-classic</v-icon>
              </v-col>

              <v-col class="d-flex justify-start">
                <v-chip outlined @click="getAccountManagers">
                  {{ getGlobalUsers.account_managers }} Adviseurs
                </v-chip>
                <v-icon class="persIcon">mdi-briefcase-account-outline</v-icon>
              </v-col>

            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex md4 class="p-2">
        <v-card elevation="5" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-1 d-flex justify-start">
                  {{ Number(getGlobalTotal.sale_per).toFixed(2) }}
                </div>
                <h6 class="d-flex justify-start">Afsrpaak / Sale</h6>
                <div class="primary--text display-1 d-flex justify-start">
                  {{ Number(getGlobalTotal.sale_per_lead).toFixed(2) }}
                </div>
                <h6 class="d-flex justify-start">Netto lead / Sale</h6>

              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-percent</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>


    </v-layout>

    <v-dialog max-width="290" transition="dialog-bottom-transition" v-model="accountManagerDialog">
      <v-card>
        <v-col class="justify-center" v-for="i in activeAccountManagers" :key="i.username">
          <v-btn width="100%" outlined color="primary" @click="selectAccountManager(i)">
            {{ i.first_name }} {{ i.last_name }}
          </v-btn>

        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog max-width="290" transition="dialog-bottom-transition" v-model="teleTeamDialog">
      <v-card>
        <v-col v-for="i in activeTeleTeamUsers" :key="i.username">
          <v-btn @click="selectTeleTeamUser(i)"
                 outlined
                 color="primary"
                 width="100%"

          >{{ i.first_name }} {{ i.last_name }}
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>


    <v-dialog v-model="teleTeamUserStatsDialog">
      <v-card>
        <v-toolbar dark
                   dense
                   color="primary">
          {{ this.selectedTeleTeamUserFirstName }} {{ this.selectedTeleTeamUserLastName }}
          <v-spacer/>
          <v-card-actions>
            <v-btn
                text
                @click="teleTeamUserStatsDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-toolbar>

        <TelefonistKpi :teleUser="selectedTeleTeamUserUsername"/>

      </v-card>
    </v-dialog>

    <v-dialog v-model="accountManagerStatsDialog">
      <v-card>

        <v-toolbar dark
                   dense
                   color="primary">
          {{ this.selectedAccountManagerFirstName }} {{ this.selectedAccountManagerLastName }}
          <v-spacer/>
          <v-card-actions>
            <v-btn
                text
                @click="accountManagerStatsDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-toolbar>

        <AccountManagerKpi :account-manager="selectedAccountManagerUsername"/>

      </v-card>
    </v-dialog>


    <v-divider/>
    <v-container class="mt-0 pt-0">
      <v-data-table v-if="this.getGlobalTotalPovince"
                    :headers="headersProvince"
                    :items="getGlobalTotalPovince"
                    class="elevation-6"
                    item-key="month"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Provincies</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.netto_per="{item}">
          <v-chip
              outlined
          >
            {{ Number(item.netto_per).toFixed(2) }} %
          </v-chip>
        </template>

        <template v-slot:item.credit_per="{item}">
          <v-chip
              outlined
          >
            {{ Number(item.credit_per).toFixed(2) }} %
          </v-chip>
        </template>

        <template v-slot:item.live_sale="{item}">
          <v-chip
              outlined
          >
            <span v-html="liveSaleArrowUp(item.live_sale)"></span>
          </v-chip>
        </template>


      </v-data-table>
    </v-container>
    <v-divider/>
    <v-container class="mt-0 pt-0">
      <v-data-table v-if="this.getGlobalTotalPerSource"
                    :headers="headersSource"
                    :items="getGlobalTotalPerSource"
                    class="elevation-6"
                    item-key="lead_origin"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Bron</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:item.netto_per="{item}">
          <v-chip
              outlined
          >
            {{ Number(item.netto_per).toFixed(2) }} %
          </v-chip>
        </template>

        <template v-slot:item.credit_per="{item}">
          <v-chip
              outlined
          >
            {{ Number(item.credit_per).toFixed(2) }} %
          </v-chip>
        </template>
        <template v-slot:item.live_sale="{item}">

          <!--          {{ item.sale }}-->
          <v-chip
              outlined
          >
            <span v-html="liveSaleArrowUp(item.live_sale)"></span>
          </v-chip>
        </template>

      </v-data-table>
    </v-container>
    <v-divider/>
    <v-container class="mt-0 pt-0">
      <v-data-table v-if="this.getGlobalTotalPerCampagne"
                    :headers="headersCampagne"
                    :items="getGlobalTotalPerCampagne"
                    class="elevation-6"
                    item-key="lead_origin"
      >
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>Campagne</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.netto_per="{item}">
          <v-chip
              outlined
          >
            {{ Number(item.netto_per).toFixed(2) }} %
          </v-chip>
        </template>

        <template v-slot:item.credit_per="{item}">
          <v-chip
              outlined
          >
            {{ Number(item.credit_per).toFixed(2) }} %
          </v-chip>
        </template>
        <template v-slot:item.live_sale="{item}">

          <!--          {{ item.sale }}-->
          <v-chip
              outlined
          >
            <span v-html="liveSaleArrowUp(item.live_sale)"></span>
          </v-chip>
        </template>

      </v-data-table>
    </v-container>

  </v-container>


</template>

<script>

import FilteredKpi from "@/views/viz/components/FilteredKpi";
import {Helper as helper} from "@/helper";
import AccountManagerKpi from "@/views/viz/AccountManagerKpi";
import TelefonistKpi from "@/views/viz/TelefonistKpi";


export default {
  name: "Kpi",
  components: {TelefonistKpi, AccountManagerKpi, FilteredKpi},
  data: () => ({
    dialog: false,
    title: "Live overzicht van ",
    selectedAccountManagerUsername: '',
    selectedAccountManagerFirstName: '',
    selectedAccountManagerLastName: '',
    selectedTeleTeamUserUsername: '',
    selectedTeleTeamUserFirstName: '',
    selectedTeleTeamUserLastName: '',
    teleTeamUserStatsDialog: false,
    teleTeamDialog: false,
    loadingText: "Gegevens worden verzameld",
    dialogLoading: true,
    selectedYear: new Date().getFullYear(),
    currentDate: new Date().toISOString().substr(0, 7),
    dateStart: "2020-04",
    dateEnd: new Date().toISOString().substr(0, 7),
    accountManagerDialog: false,
    accountManagerStatsDialog: false,
    headersSource: [
      {text: 'Bron', value: 'lead_origin'},
      {text: 'Leads', value: 'bruto'},
      {text: 'Netto Leads', value: 'netto'},
      {text: 'Netto %', value: 'netto_per'},
      {text: 'Credits', value: 'credit'},
      // {text: 'Credit %', value: 'credit_per'},
      {text: 'Afspraken', value: 'afspraken'},
      {text: 'Live sale', value: 'live_sale'},
      // {text: 'Afspraak %', value: 'afspraken_per'},
      // {text: 'Sale', value: 'sale'},
      // {text: 'Sale %', value: 'sale_per'}
    ],
    headersProvince: [
      {text: 'Provincie', value: 'province'},
      {text: 'Leads', value: 'bruto'},
      {text: '(Netto) Leads', value: 'netto'},
      {text: 'Netto %', value: 'netto_per'},
      // {text: 'Credits', value: 'credit'},
      // {text: 'Credit %', value: 'credit_per'},
      {text: 'Afspraken', value: 'afspraken'},
      {text: 'Live sale', value: 'live_sale'},
      // {text: 'Afspraak %', value: 'afspraken_per'},
      // {text: 'Sale', value: 'sale'},
      // {text: 'Sale %', value: 'sale_per'}
    ],
    headersCampagne: [
      {text: 'Website', value: 'campagne_id'},
      {text: 'Leads', value: 'bruto'},
      {text: '(Netto) Leads', value: 'netto'},
      {text: 'Netto %', value: 'netto_per'},
      // {text: 'Credits', value: 'credit'},
      // {text: 'Credit %', value: 'credit_per'},
      {text: 'Afspraken', value: 'afspraken'},
      {text: 'Live sale', value: 'live_sale'},
      // {text: 'Afspraak %', value: 'afspraken_per'},
      // {text: 'Sale', value: 'sale'},
      // {text: 'Sale %', value: 'sale_per'}
    ],
  }),
  beforeCreate() {
    this.dialogLoading = true;
  },
  async mounted() {
    await this.$store.dispatch('solar/fetchKpiOverviewGlobal');
    let startDate = this.dateStart;
    let endDate = this.dateEnd;
    await this.$store.dispatch("solar/fetchKpiOverview", {startDate, endDate});
  },
  async created() {
    await setInterval(() => {
      this.$store.dispatch('solar/fetchKpiOverviewGlobal');

    }, 900000); // 15 min

    this.dialogLoading = false;
  },
  computed: {
    getGlobalTotal() {
      let t = this.$store.getters["solar/getKpiOverviewGlobal"].global_total;
      return t != null ? t : 0;
    },
    getGlobalTotalPovince() {
      let t = this.$store.getters["solar/getKpiOverviewGlobal"].global_total_per_province;
      return t != null ? t : 0;
    },
    getGlobalTotalPerSource() {
      let t = this.$store.getters["solar/getKpiOverviewGlobal"].global_total_per_source;
      return t != null ? t : 0;
    },
    getGlobalTotalPerCampagne() {
      let t = this.$store.getters["solar/getKpiOverviewGlobal"].global_total_per_campagne;
      return t != null ? t : 0;
    },
    getGlobalUsers() {
      let t = this.$store.getters["solar/getKpiOverviewGlobal"].active_users;
      return t != null ? t : 0;
    },
    getLeadUpdateStatus() {
      let a = this.$store.getters['solar/getLeadUpdateStatus'];
      return a.status != null ? a.status.latest_update_info : null;
    },
    activeAccountManagers() {
      let a = this.$store.getters['solar/getActiveAccountManagers'];

      return a != null ? a.account_managers : []
    },
    activeTeleTeamUsers() {
      let a = this.$store.getters['solar/getActiveTeleTeamUsers'];

      return a != null ? a.users : []
    }
  },
  methods: {
    getYears() {
      let global = this.$store.getters["solar/getKpiOverviewGlobal"].global_total_per_month;
      return global != null ? global.map(q => new Date(q.month).getFullYear()) : [];
    },
    getMonthName(date) {
      return helper.extractMonth(date);
    },
    liveSaleArrowUp(n) {
      return n > 0 ? '<span style="color:#008000">⬆</span>' + "+" + n : n;
    },
    async getAccountManagers() {
      await this.$store.dispatch("solar/fetchActiveAccountManager");
      this.accountManagerDialog = true;
    },
    selectAccountManager(user) {
      this.selectedAccountManagerUsername = user.username;
      this.selectedAccountManagerFirstName = user.first_name;
      this.selectedAccountManagerLastName = user.last_name;
      this.accountManagerStatsDialog = true;

    },
    async getTeleTeamUsers() {
      await this.$store.dispatch("solar/fetchActiveTeleTeamUsers");
      this.teleTeamDialog = true;
    },
    selectTeleTeamUser(user) {
      this.selectedTeleTeamUserUsername = user.username;
      this.selectedTeleTeamUserFirstName = user.first_name;
      this.selectedTeleTeamUserLastName = user.last_name;
      this.teleTeamUserStatsDialog = true;

    },
  }
}
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
}

.icons {
  padding: inherit;
  margin-bottom: 25px;
  margin-left: 20px;
}

#dialog{
  background: #f9f6f3d4;
}

.persIcon {
  margin-left: 10%;
}
</style>
