<template>
  <div id="panel">
    <v-app-bar-nav-icon class="p-4" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    <v-navigation-drawer
        v-model="drawer"
        app

        temporary

        id="optionBar"

        :width="325"
    >

      <!--        v-show="$vuetify.breakpoint.lgAndUp"-->
      <v-footer shaped v-if="this.getDailyQuote">

       <v-card-subtitle>
         {{ this.getDailyQuote.text }}
       </v-card-subtitle>

        <v-card-text class="font-italic">
          {{ this.getDailyQuote.author }}
        </v-card-text>

      </v-footer>

      <v-treeview
          shaped
          transition
          :items="items" open-on-click dense activatable hoverable @update:active="this.navigate">
      </v-treeview>
      <v-footer
          fixed
          class="justify-center"
      >
        <v-img max-height="40"
               max-width="30"
               src="../assets/logo-single.png"
        />
      </v-footer>
    </v-navigation-drawer>


    <component v-bind:is="this.component"/>

  </div>

</template>

<script>

import Map from "@/views/viz/Map";
import Leads from "@/views/lead/Leads";
import MarkerMap from "@/views/viz/MarkerMap";
import OnlineLeads from "@/views/viz/OnlineLeads";
import UserOverview from "@/views/viz/UserOverview";
import UserOverviewKpi from "@/views/viz/UserOverviewKpi";
import DoorLeads from "@/views/viz/DoorLeads";
import Admin from "@/views/Admin";
import TeamOverview from "@/views/viz/TeamOverview";
import TeamOverviewKpi from "@/views/viz/TeamOverviewKpi";
import Kpi from "@/views/viz/Kpi";
import AccountManagerKpi from "@/views/viz/AccountManagerKpi";
import TelefonistKpi from "@/views/viz/TelefonistKpi";
import WorkedWithoutLeads from "@/views/viz/WorkedWithoutLeads";
import AccountManagerAnalyzePage from "@/views/viz/AccountManagerAnalyzePage";
import AccountManagerGeneralOverview from "@/views/viz/AccountManagerGeneralOverview";
import AnalyzeMap from "@/views/viz/AnalyzeMap";
import TeleTeamGeneralOverview from "@/views/viz/TeleTeamGeneralOverview";
import DistrictMap from "@/views/viz/DistrictMap.vue";
import RemoveDistrict from "@/views/viz/RemoveDistrict.vue";

export default {
  name: "DashBoard",
  components: {
    Kpi, TeamOverview, TeamOverviewKpi, DoorLeads, Map, Leads,
    MarkerMap, OnlineLeads, UserOverview, Admin, UserOverviewKpi, AccountManagerKpi, TelefonistKpi, WorkedWithoutLeads,
    AccountManagerAnalyzePage, AccountManagerGeneralOverview, AnalyzeMap, TeleTeamGeneralOverview, DistrictMap, RemoveDistrict
  },
  data() {
    return {
      component: '',
      items: [],
      drawer: false,
      itemsOriginal: [
        {
          id: 'Admin', name: 'Admin', groups: [
            'admin'
          ]
        },
        {
          id: 'DoorLeads', name: 'Leads', groups: [
            'admin', 'beheer', 'loperscaptain'
          ]
        },
        // {
        //   id: 'OnlineLeads', name: 'Online leads', groups: [
        //     'admin', 'beheer'
        //   ]
        // },
        {
          id: 'enq', name: 'Enquêteur', groups: [
            'admin', 'beheer', 'loperscaptain'
          ],
          children: [
            {
              id: 'UserOverview', name: 'Lopers overzicht'
            },
            {
              id: 'TeamOverview', name: 'Team overzicht'
            }
          ]
        },
        {
          id: 'Districts', name: 'Wijkenmaker', icon: 'mdi-google-maps', groups: [
            'admin', 'beheer', 'loperscaptain'
          ],
          children: [
            {
              id: 'DistrictMap', name: 'Maak wijk'
            },
            {
              id: 'RemoveDistrict', name: 'Verwijder wijk'
            },
          ]
        },
        // {
        //   id: 'MarkerMap', name: 'Marker Map', icon: 'mdi-google-maps', groups: [
        //     'admin', 'beheer', 'loperscaptain'
        //   ]
        // },
        {
          id: 'AnalyzeMap', name: 'Analyze Map', icon: 'mdi-google-maps', groups: [
            'admin', 'beheer'
          ]
        },
        {
          id: 'Kpi', name: 'KPI\'s', groups: [
            'admin', 'beheer'
          ],
          children: [
            {
              id: 'Kpi', name: 'Algemeen Overzicht'
            },
            {
              id: 'UserOverviewKpi', name: 'Enquêteurs KPI'
            },
            {
              id: 'TeamOverviewKpi', name: 'Team KPI (Enquêteur)'
            },
          ]
        },

        {
          id: 'AccountManagerKpi', name: 'Mijn Adviseur Kpi\'s', groups: [
            'accountmngr', 'accountmngrcaptain'
          ]
        },
        {
          id: 'adv', name: 'Adviseur', groups: [
            'admin', 'beheer', 'accountmngrcaptain'
          ],
          children: [
            {
              id: 'AccountManagerGeneralOverview', name: 'Overzicht'
            },
            {
              id: 'AccountManagerAnalyzePage', name: 'Analyse'
            },

          ]
        },

        // {
        //   id: 'tele', name: 'Telefonist', groups: [
        //     'admin', 'beheer', 'teleteamcaptain'
        //   ],
        //   children: [
        //     {
        //       id: 'TeleTeamGeneralOverview', name: 'Overzicht'
        //     },
        //   ]
        // },
        // {
        //   id: 'TelefonistKpi', name: 'Mijn TeleTeam Kpi\'s', groups: [
        //     'teleteam', 'teleteamcaptain'
        //   ]
        // },
        {
          id: 'WorkedWithoutLeads', name: 'Geen leads', groups: [
            'admin', 'beheer', 'loperscaptain'
          ]
        },
        {
          id: 'Leads', name: 'Nieuwe Lead', groups: [
            'admin', 'beheer', 'loperscaptain', 'accountmngr', 'accountmngrcaptain', 'duurzaam', 'lopers'
          ]
        },
      ],
    };
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  async mounted() {
    this.items = this.itemsOriginal
        .filter(q =>
            q.groups
                .some(r =>
                    this.getUserGroup.includes(r)
                )
        );
    await this.$store.dispatch('dashboard/fetchDailyQuotes');

    //TODO: revert this
    this.component = this.items[0].id;
    // this.drawer = true;
  },
  computed: {
    getUserGroup() {
      return this.$store.getters['auth/getUser'].groups;
    },
    getDailyQuote() {
      return this.$store.getters['dashboard/getQuote'] != null ? this.$store.getters['dashboard/getQuote'].quote : null;
    }
  },
  methods: {
    navigate(value) {
      if (value.length) {
        this.component = value[0];
        this.drawer = false;
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

#panel {
  background: #fffefddb;
  height: 100%;
}

#optionBar {
  font-size: larger;
  font-family: Poppins;;
  /*opacity: 0.9;*/
  background: #d5d3d0ed;

}
</style>
