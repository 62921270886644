import SolarService from '../services/solar.service';

export const solar = {
    state: {
        geocordsList: [],
        updating: false,
        uploading: false,
        leadUpdateStatus: {},
        kpiOverview: {},
        kpiOverviewGlobal: {},
        accountManagerKpi: {},
        teleTeamKpi: {},
        pipelineStats: {},
        activeManagers: {},
        teleTeamStats: {},
        activeTeleTeamUsers: {},
        analyzeAccountManger: {},
        generalOverviewAccoutManager: {},
        generalOverviewTeleTeam: {},
    },
    namespaced: true,
    actions: {
        postCsv({commit}, file) {
            commit('FILE_UPLOADING', true)
            SolarService.postCsv(file);
        },
        fetchGeoCords({commit}, {mobile, start, end, currStatus}) {
            return SolarService.getGeoCords(mobile, start, end, currStatus).then(
                d => {
                    commit('SET_GEO_CORDS', d);
                    return Promise.resolve(d);
                }
            )
        },
        fetchLeadUpdateStatus({commit},) {
            return SolarService.getLeadUpdateStatus().then(
                d => {
                    commit('SET_LEAD_UPDATE_STATUS', d);
                    return Promise.resolve();
                }
            ).then()
        },
        forceUpdateLeadUpdateStatus({commit},) {
            commit('SET_UPDATING', true);
            return SolarService.forceUpdateLeadUpdateStatus().then(
                r => {
                    commit('SET_LEAD_UPDATE_STATUS', r)
                    commit('SET_UPDATING', false)
                    return Promise.resolve();
                })
        },

        fetchKpiOverview({commit}, {startDate, endDate}) {
            return SolarService.getKpiOverview(startDate, endDate).then(
                d => {
                    commit('SET_KPI_OVERVIEW', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchKpiOverviewGlobal({commit},) {
            return SolarService.getKpiOverviewGlobal().then(
                d => {
                    commit('SET_KPI_OVERVIEW_GLOBAL', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchAccountManagerKpi({commit}, ) {
            return SolarService.getAccountManagerKpi().then(
                d => {
                    commit('SET_KPI_ACCOUNT_MANAGER', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchAccountManagerByNameKpi({commit}, {accountManager}) {
            return SolarService.getAccountManagerKpi(accountManager).then(
                d => {
                    commit('SET_KPI_ACCOUNT_MANAGER', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchTeleTeamKpi({commit},) {
            return SolarService.getTeleTeamKpi().then(
                d => {
                    commit('SET_KPI_TELE_TEAM', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchTeleTeamKpiByName({commit}, user) {
            return SolarService.getTeleTeamKpi(user).then(
                d => {
                    commit('SET_KPI_TELE_TEAM', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchPipelineStats({commit}, {date}) {
            return SolarService.getPipelineStats(null, date).then(
                d => {
                    commit('SET_PIPELINE_STATS', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchPipelineStatsByName({commit}, {name, date}) {
            if(date){
                return SolarService.getPipelineStats(name, date).then(
                    d => {
                        commit('SET_PIPELINE_STATS', d);
                        return Promise.resolve(d);
                    }
                )
            }else {
                return SolarService.getPipelineStats(name, null).then(
                    d => {
                        commit('SET_PIPELINE_STATS', d);
                        return Promise.resolve(d);
                    }
                )
            }
        },

        fetchActiveAccountManager({commit},) {
            return SolarService.getActiveAccountManagers().then(
                d => {
                    commit('SET_ACTIVE_ACCOUNT_MANAGERS', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchTeleTeamStats({commit}, {date}) {
            return SolarService.getTeleTeamStats(null, date).then(
                d => {
                    commit('SET_TELE_TEAM_STATS', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchTeleTeamStatsByName({commit}, {name, date}) {
            return SolarService.getTeleTeamStats(name, date).then(
                d => {
                    commit('SET_TELE_TEAM_STATS', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchActiveTeleTeamUsers({commit},) {
            return SolarService.getActiveTeleTeamUsers().then(
                d => {
                    commit('SET_ACTIVE_TELE_TEAM_USERS', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchAnalyzeAccountManager({commit},) {
            return SolarService.getAnalyzeAccountManager().then(
                d => {
                    commit('SET_ANALYZE_ACCOUNT_MANAGER', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchGeneralOverviewAccountManager({commit},) {
            return SolarService.getGeneralOverviewAccountManager().then(
                d => {
                    commit('SET_GENERAL_OVERVIEW_ACCOUNT_MANAGER', d);
                    return Promise.resolve(d);
                }
            )
        },

        fetchGeneralOverviewTeleTeam({commit},) {
            return SolarService.getTeleTeamOverview().then(
                d => {
                    commit('SET_GENERAL_OVERVIEW_TELE_TEAM', d);
                    return Promise.resolve(d);
                }
            )
        },

    },
    mutations: {
        SET_UPDATING(state, current) {
            state.updating = current;
        },
        FILE_UPLOADING(state, a) {
            state.uploading = a;
        },
        SET_GEO_CORDS(state, geoCords) {
            state.geocordsList = geoCords;
        },
        SET_LEAD_UPDATE_STATUS(state, leadUpdateStatus) {
            state.leadUpdateStatus = leadUpdateStatus;
        },
        SET_KPI_OVERVIEW(state, kpiOverView) {
            state.kpiOverview = kpiOverView;
        },
        SET_KPI_OVERVIEW_GLOBAL(state, kpiOverViewGlobal) {
            state.kpiOverviewGlobal = kpiOverViewGlobal;
        },
        SET_KPI_ACCOUNT_MANAGER(state, accountManagerKpi) {
            state.accountManagerKpi = accountManagerKpi;
        },
        SET_KPI_TELE_TEAM(state, teleTeamKpi) {
            state.teleTeamKpi = teleTeamKpi;
        },
        SET_PIPELINE_STATS(state, pipelineStats) {
            state.pipelineStats = pipelineStats;
        },
        SET_ACTIVE_ACCOUNT_MANAGERS(state, activeManagers) {
            state.activeManagers = activeManagers;
        },
        SET_TELE_TEAM_STATS(state, teleTeamStats){
            state.teleTeamStats = teleTeamStats;
        },
        SET_ACTIVE_TELE_TEAM_USERS(state, activeTeleTeamUsers) {
            state.activeTeleTeamUsers = activeTeleTeamUsers;
        },
        SET_ANALYZE_ACCOUNT_MANAGER(state, analyzeAccountManger) {
            state.analyzeAccountManger = analyzeAccountManger;
        },
        SET_GENERAL_OVERVIEW_ACCOUNT_MANAGER(state, generalOverviewAccoutManager) {
            state.generalOverviewAccoutManager = generalOverviewAccoutManager;
        },
        SET_GENERAL_OVERVIEW_TELE_TEAM(state, generalOverviewTeleTeam) {
            state.generalOverviewTeleTeam = generalOverviewTeleTeam;
        },
    },
    getters: {
        getUploadStatus: state => {
            return state.uploading;
        },
        getGeoCords: state => {
            return state.geocordsList;
        },
        getLeadUpdateStatus: state => {
            return state.leadUpdateStatus;
        },
        getUpdatingStatus: state => {
            return state.updating;
        },
        getKpiOverview: state => {
            return state.kpiOverview;
        },
        getKpiOverviewGlobal: state => {
            return state.kpiOverviewGlobal;
        },
        getAccountManagerKpi: state => {
            return state.accountManagerKpi;
        },
        getTeleTeamKpi: state => {
            return state.teleTeamKpi;
        },
        getPipelineStats: state => {
            return state.pipelineStats;
        },
        getActiveAccountManagers: state => {
            return state.activeManagers;
        },
        getTeleTeamStats: state => {
            return state.teleTeamStats;
        },
        getActiveTeleTeamUsers: state => {
            return state.activeTeleTeamUsers;
        },
        getAccountMangerAnalyze: state => {
            return state.analyzeAccountManger;
        },
        getGeneralOverviewAccoutManager: state => {
            return state.generalOverviewAccoutManager;
        },
        getGeneralOverviewTeleTeam: state => {
            return state.generalOverviewTeleTeam;
        }
    },
}