import Delaunator from "delaunator";

class Utils {
    constructor() {
    }

    calculateDistance(point1, point2) {
        return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
    }

    wrapGift(points) {
        points.sort((a, b) => b.x - a.x);

        let gift = [points[0]];
        let curPoint = points[0];
        let finishPoint = curPoint
        let prevPoint = {x: curPoint.x, y: curPoint.y - 1000000};

        let running = true;
        while (running) {
            let nextPoint;
            let minAngle = Math.PI * 2;
            points.forEach(p => {
                if (p !== curPoint) {
                    let angle = this.findCornerAngle(curPoint, prevPoint, p);
                    if (angle < minAngle) {
                        minAngle = angle;
                        nextPoint = p;
                    }
                }
            });
            if (nextPoint === finishPoint) {
                running = false;
            } else {
                prevPoint = curPoint;
                curPoint = nextPoint;
                gift.push(curPoint);
            }
        }

        return gift;
    }

    // finds angle of clockwise-wound corner
    // in a clockwise-wound corner:
    // cp is the corner point
    // p1 is the counter-clockwise point
    // p2 is the clockwise point
    findCornerAngle(cp, p1, p2) {
        let v1 = this.seg2ToVec2(cp, p1);
        let v2 = this.seg2ToVec2(cp, p2);
        let dot = this.findDotProduct(v1, v2);
        let det = this.findDeterminant(v1, v2);
        let a = Math.atan2(det, dot);

        return a < 0 ? Math.abs(a) : Math.PI * 2 - a;
    }

    // Converts 2-dimensional segmented line to 2-dimensional vector
    seg2ToVec2(p1, p2) {
        return {
            x: p2.x - p1.x,
            y: p2.y - p1.y,
        }
    }

    // returns dot product of 2 vectors
    findDotProduct(v1, v2) {
        return (v1.x * v2.x) + (v1.y * v2.y);
    }

    // returns determinant of 2 vectors
    findDeterminant(v1, v2) {
        return (v1.x * v2.y) - (v1.y * v2.x);
    }

    triangulate(points) {
        const coords = points.map(point => [point.x, point.y]);

        const delaunay = Delaunator.from(coords);
        const triangles = delaunay.triangles;

        const result = [];
        for (let i = 0; i < triangles.length; i += 3) {
            result.push([points[triangles[i]], points[triangles[i + 1]], points[triangles[i + 2]]]);
        }

        return result;
    }

    convertTrianglesToGraph(triangles) {
        let nodeMap = new Map();
        triangles.forEach(t => {
            t.forEach((p, i) => {
                if(nodeMap.has(p.id)) {
                    nodeMap.get(p.id).connections.set(t[(i + 1) % t.length].id, this.calculateDistance(p, t[(i + 1) % t.length]));
                    nodeMap.get(p.id).connections.set(t[(i + 2) % t.length].id, this.calculateDistance(p, t[(i + 2) % t.length]));
                } else {
                    nodeMap.set(p.id, {
                        ...p,
                        connections: new Map([
                            [t[(i + 1) % t.length].id, this.calculateDistance(p, t[(i + 1) % t.length])],
                            [t[(i + 2) % t.length].id, this.calculateDistance(p, t[(i + 2) % t.length])],
                        ])
                    })
                }
            })
        })
        return nodeMap;
    }

    createClump(startingPointId, size, nodeMap) {
        let clump = new Map();
        let allPoints = Array.from(nodeMap.values())
        const startingPoint = nodeMap.get(startingPointId);

        clump.set(startingPointId, startingPoint);
        nodeMap.delete(startingPointId);

        let toTestEdges = [];
        startingPoint.connections.forEach((v, k) => {
            if(!clump.has(k)) {
                toTestEdges.push({
                    id: k,
                    dist: v
                })
            }
        })

        for(let i = 0; clump.size < size; i++) {
            toTestEdges.sort((a, b) => a.dist - b.dist);
            let toTestEdge = toTestEdges.shift();

            let newPointFound = false;
            while(!newPointFound) {
                if (!clump.has(toTestEdge.id)) {
                    let toAddNode = nodeMap.get(toTestEdge.id);
                    clump.set(toTestEdge.id, toAddNode)
                    nodeMap.delete(toTestEdge.id);

                    toAddNode.connections.forEach((v, k) => {
                        if (!clump.has(k)) {
                            toTestEdges.push({
                                id: k,
                                dist: v
                            })
                        }
                    })

                    newPointFound = true;
                } else {
                    toTestEdge = toTestEdges.shift();
                }
            }
        }

        const clumpGift = this.wrapGift(Array.from(clump.values()));
        const allClumpPoints = this.determineMultiplePointsInPolygon(allPoints, clumpGift);
        const finalClumpMap = new Map();
        clumpGift.forEach(p => finalClumpMap.set(p.id, p))
        allClumpPoints.forEach(p => finalClumpMap.set(p.id, p))
        const finalClumpPoints = Array.from(finalClumpMap.values()).sort((a, b) => a.cd - b.cd).slice(0, size);

        return finalClumpPoints;
    }

    determineMultiplePointsInPolygon(points, pol) {
        let pointsInPolygon = []

        let toTestElements = points.map(p => { return {...p, minY: p.y, type: "point"}});
        let pp = pol[pol.length - 1];
        pol.forEach(p => {
            let edge = {
                source: p.y === pp.y ? (p.x < pp.x ? p : pp) : (p.y < pp.y ? p : pp),
                target: p.y === pp.y ? (p.x < pp.x ? p : pp) : (p.y > pp.y ? p : pp),
                type: "edge"
            }
            edge.minY = edge.source.y;
            toTestElements.push(edge);
            pp = p;
        })

        toTestElements.sort((a, b) => {
            if(a.minY === b.minY) {
                return a.type.localeCompare(b.type);
            } else {
                return a.minY - b.minY;
            }
        })

        let sweepY = Number.MIN_SAFE_INTEGER;
        let toTestEdges = [];
        for(let i = 0; i < toTestElements.length; i++) {
            let element = toTestElements[i];
            sweepY = element.minY;
            toTestEdges = toTestEdges.filter(e => e.target.y > sweepY);

            if(element.type === "edge") {
                toTestEdges.push(element);
            } else {
                let intersectionCount = 0;

                toTestEdges.forEach(e => {
                    if(this.testLinesIntersecting({
                        p1: element,
                        p2: {x: element.x + 1000000000, y: element.y}
                    }, {
                        p1: e.source,
                        p2: e.target
                    })) {
                        intersectionCount++;
                    }
                })

                if(intersectionCount % 2 === 1) {
                    pointsInPolygon.push(element);
                }
            }
        }
        return pointsInPolygon;
    }

    testLinesIntersecting(l1, l2) {
        return (this.counterClockwiseWinding(l1.p1, l2.p1, l2.p2) !== this.counterClockwiseWinding(l1.p2, l2.p1, l2.p2))
            && (this.counterClockwiseWinding(l1.p1, l1.p2, l2.p1) !== this.counterClockwiseWinding(l1.p1, l1.p2, l2.p2));
    }

    counterClockwiseWinding(p1, p2, p3) {
        return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x);
    }

    balanceClusters(clusters, nodeMap) {
        clusters.forEach((c, i) => {
            c.forEach(p => nodeMap.get(p.id).cluster = i)
        })

        let clusterMap = new Map();

        clusters.forEach((c, i) => {
            let cluster = {
                id: i,
                centroid: this.findPointCloudCentroid(c),
                members: new Map(),
                externalEdges: new Map(),
                originalCluster: [...c]
            };

            c.forEach(p => {
                cluster.members.set(p.id, p);
                p.connections.forEach((c, k) => {
                    let cp = nodeMap.get(k);
                    if(cp.cluster !== i) {
                        if(!cluster.externalEdges.has(k) || c < cluster.externalEdges.get(k).dist) {
                            cluster.externalEdges.set(k, {
                                id: k,
                                originId: p.id,
                                dist: c,
                                cluster: cp.cluster
                            })
                        }
                    }
                })
            })

            clusterMap.set(i, cluster)
        })

        // change this in order to adjust maximum distance for candidates
        // first value is in meters
        const maxDist = 200 * 0.0000147;
        let safety = 500;
        while(!this.testClusterSizeUniformity(clusterMap) && safety > 0) {
            safety--;
            clusterMap.forEach(cluster => {
                cluster.centroid = this.findPointCloudCentroid(Array.from(cluster.members.values()));
                cluster.externalEdges = new Map();

                Array.from(cluster.members.values()).forEach(p => {
                    // cluster.members.set(p.id, p);
                    p.connections.forEach((c, k) => {
                        let cp = nodeMap.get(k);
                        if(cp.cluster !== cluster.id) {
                            if(!cluster.externalEdges.has(k) || c < cluster.externalEdges.get(k).dist) {
                                cluster.externalEdges.set(k, {
                                    id: k,
                                    originId: p.id,
                                    dist: c,
                                    cluster: cp.cluster
                                })
                            }
                        }
                    })
                })

                clusterMap.set(cluster.id, cluster)
            })
            const clusterArray = Array.from(clusterMap.values()).sort((a, b) => b.members.size - a.members.size);

            let processedClusters = new Map();
            clusterArray.forEach(cluster => {
                const edgeArray = Array.from(cluster.externalEdges.values()).sort((a, b) => {
                    let aOriginNode = nodeMap.get(a.originId);
                    let aOtherClusterCentroid = clusterMap.get(nodeMap.get(a.id).cluster).centroid
                    let aDist = this.calculateDistance(aOriginNode, aOtherClusterCentroid);

                    let bOriginNode = nodeMap.get(b.originId);
                    let bOtherClusterCentroid = clusterMap.get(nodeMap.get(b.id).cluster).centroid
                    let bDist = this.calculateDistance(bOriginNode, bOtherClusterCentroid);

                    return aDist - bDist
                });

                for(let i = 0; i < edgeArray.length; i++) {
                    const edge = edgeArray[i];

                    if(edge.dist < maxDist) {
                        let connectedCluster = clusterMap.get(edge.cluster);

                        if (!processedClusters.has(connectedCluster.id) && connectedCluster.members.size < cluster.members.size) {
                            let toTransferNode = nodeMap.get(edge.originId)

                            // set new cluster designation
                            toTransferNode.cluster = connectedCluster.id;
                            // transfer node to other cluster
                            connectedCluster.members.set(toTransferNode.id, toTransferNode);
                            // delete node from old cluster
                            cluster.members.delete(toTransferNode.id);
                            // delete edges to node from other cluster
                            connectedCluster.externalEdges.delete(toTransferNode.id);
                            // delete edge from old cluster
                            cluster.externalEdges.forEach((e, k) => {
                                if (e.originId === toTransferNode.id) {
                                    cluster.externalEdges.delete(k)
                                }
                            })

                            // add new external edges to other cluster and establish new edges in old cluster
                            toTransferNode.connections.forEach((v, con) => {
                                let cp = nodeMap.get(con);
                                if (cp.cluster !== toTransferNode.cluster) {
                                    if (!connectedCluster.externalEdges.has(con) || v < connectedCluster.externalEdges.get(con).dist) {
                                        connectedCluster.externalEdges.set(con, {
                                            id: con,
                                            originId: toTransferNode.id,
                                            dist: v,
                                            cluster: cp.cluster
                                        })
                                    }

                                    if (cp.cluster === cluster.id) {
                                        if (!cluster.externalEdges.has(toTransferNode.id) || v < cluster.externalEdges.get(toTransferNode.id).dist) {
                                            cluster.externalEdges.set(con, {
                                                id: toTransferNode.id,
                                                originId: con,
                                                dist: v,
                                                cluster: connectedCluster.id
                                            })
                                        }
                                    }
                                }
                            })

                            break;
                        }
                    }
                }
                processedClusters.set(cluster.id, true);
            })
        }

        return Array.from(clusterMap.values()).map(c => {
            let cluster = Array.from(c.members.values());
            cluster.centroid = c.centroid;
            cluster.originalCluster = c.originalCluster;
            return cluster
        })
    }

    testClusterSizeUniformity(clusterMap) {
        let uniformSize = true;

        const clusterArray = Array.from(clusterMap.values());

        for(let i = 1; i < clusterArray.length; i++) {
            if(clusterArray[0].members.size !== clusterArray[i].members.size) {
                uniformSize = false;
                break;
            }
        }

        return uniformSize;
    }

    findPointCloudCentroid(points) {
        let sumPoint = {
            x: 0,
            y: 0
        }

        points.forEach(p => {
            sumPoint.x += p.x;
            sumPoint.y += p.y;
        })

        return {
            x: sumPoint.x / points.length,
            y: sumPoint.y / points.length,
        }
    }

    openGift(points, maxLength = 0.000002) {
        let giftAndRemainder = this.wrapGiftWithRemainder(points);
        let segs = [];

        if(!this.polygonWindingClockwise(giftAndRemainder.gift)) {
            giftAndRemainder.gift.reverse();
        }

        giftAndRemainder.gift.forEach((p, i) => {
            let seg = {
                source: p,
                target: giftAndRemainder.gift[i === giftAndRemainder.gift.length - 1 ? 0 : i + 1]
            }
            seg.length = this.calculateDistance(seg.source, seg.target);

            segs.push(seg)
        });

        segs.sort((a, b) => b.length - a.length);

        let safety = 1000;

        while(segs[0].length > maxLength && safety > 0) {
            let minAngle = 4 * Math.PI;
            let newPoint;

            giftAndRemainder.remainder.forEach(p => {
                let cor1 = this.findCornerAngle(segs[0].source, p, segs[0].target);
                let cor2 = this.findCornerAngle(segs[0].target, segs[0].source, p);

                let curAngle = cor1 + cor2;
                if(curAngle < 0.5 * Math.PI && curAngle < minAngle) {
                    minAngle = curAngle;
                    newPoint = p;
                }
            })

            if(newPoint) {
                let seg1 = {
                    source: segs[0].source,
                    target: newPoint
                }
                seg1.length = this.calculateDistance(seg1.source, seg1.target);

                let seg2 = {
                    source: newPoint,
                    target: segs[0].target
                }
                seg2.length = this.calculateDistance(seg2.source, seg2.target);

                giftAndRemainder.remainder.splice(giftAndRemainder.remainder.indexOf(newPoint), 1);

                segs.shift();
                segs = [...[seg1, seg2], ...segs];
                segs.sort((a, b) => b.length - a.length);
            }

            safety--;
        }

        let openedGift = [segs[0].target];
        while(openedGift.length < segs.length) {
            openedGift.push(segs.find(s => s.source === openedGift[openedGift.length - 1]).target);
        }

        giftAndRemainder.gift = this.removeCollinearPoints(openedGift);

        return giftAndRemainder.gift;
    }

    wrapGiftWithRemainder(points) {
        let gift = this.wrapGift(points);
        return {
            gift: gift,
            remainder: points.filter(p => gift.indexOf(p) === -1)
        }
    }

    polygonWindingClockwise(points) {
        let winding = 0;

        points.forEach((p, i) => {
            let n = i === points.length - 1 ? 0 : i + 1;
            winding += (points[n].x - p.x) * (points[n].y + p.y);
        });

        return winding < 0;
    }

    offsetPolygon(pol, offset) {
        if(offset === 0) {
            return [pol];
        }

        let edges = [];

        pol.forEach((n, i) => {
            let nextNode = pol[i === pol.length - 1 ? 0 : i + 1];
            edges.push({
                source: n,
                target: nextNode,
                offset: offset
            })
        })

        return this.offsetPolygonPerEdge(edges, offset < 0)
    }

    offsetPolygonPerEdge(edges, outerOffset = false, dethorn = false) {
        if(!edges.some(e => e.offset !== 0)) {
            return {res: [edges.map(e => e.source)]};
        }

        let offsetEdges = this.offsetEdges(edges, outerOffset, dethorn);
        let pointMap = this.intersectEdgesByLineSweeping(offsetEdges);
        let allCycles = this.findOffsetFaces(pointMap);

        let result = [];

        allCycles.forEach(c => {
            let winding = 0;
            let pointInCycle = this.createPointInPolygon(c);
            if(pointInCycle) {
                offsetEdges.forEach(e => {
                    if(this.testLinesIntersecting({p1: pointInCycle, p2: {x: Number.MAX_SAFE_INTEGER, y: pointInCycle.y}}, {p1: e.source, p2: e.target})) {
                        winding += e.winding;
                    }
                })
                if(winding === 1) {
                    if(outerOffset) {
                        if(!this.polygonWindingClockwise(c)) {
                            result.push(c.reverse())
                        }
                    } else {
                        if (this.polygonWindingClockwise(c)) {
                            result.push(c);
                        }
                    }
                }
            }
        })

        return result;
    }

    offsetEdges(edges, outerOffset, dethorn) {
        while(edges[0].offset === 0) {
            edges.push(edges.shift());
        }

        let offsetEdges = [];

        edges.forEach((e, i) => {
            if(e.offset === 0) {
                let prevEdge = edges[i === 0 ? edges.length - 1 : i - 1];

                if(prevEdge.offset !== 0) {
                    let prevTarget;
                    if(offsetEdges.length) {
                        prevTarget = offsetEdges[offsetEdges.length - 1].target;
                    } else {
                        let prevEdgeAngle = this.findVectorAngle(this.seg2ToVec2(prevEdge.source, prevEdge.target));
                        let prevEdgeRadPoint = this.findRadialPoint(prevEdge.offset, prevEdgeAngle + Math.PI * 0.5);
                        prevTarget = this.combinePoints(prevEdge.target, prevEdgeRadPoint);
                    }

                    offsetEdges.push({
                        source: prevTarget,
                        target: e.source,
                        winding: this.edgeWinding(prevTarget, e.source)
                    })
                }

                offsetEdges.push({
                    source: e.source,
                    target: e.target,
                    winding: this.edgeWinding(e.source, e.target)
                })

                if(!dethorn) {
                    let nextEdge = edges[i === edges.length - 1 ? 0 : i + 1];
                    if (nextEdge.offset !== 0) {
                        let nextEdgeAngle = this.findVectorAngle(this.seg2ToVec2(nextEdge.source, nextEdge.target));
                        let nextEdgeRadPoint = this.findRadialPoint(nextEdge.offset, nextEdgeAngle + Math.PI * 0.5);
                        let nextSource = this.combinePoints(nextEdge.source, nextEdgeRadPoint);

                        offsetEdges.push({
                            source: e.target,
                            target: nextSource,
                            winding: this.edgeWinding(e.target, nextSource)
                        })
                    }
                }
            } else {

                let angle = this.findVectorAngle(this.seg2ToVec2(e.source, e.target));
                let radPoint = this.findRadialPoint(e.offset, angle + Math.PI * 0.5);
                let nextEdge = edges[i === edges.length - 1 ? 0 : i + 1];

                let nextEdgeCorner = this.findCornerAngle(e.target, e.source, nextEdge.target);

                let concaveTarget;
                if (outerOffset) {
                    concaveTarget = nextEdgeCorner <= Math.PI;
                } else {
                    concaveTarget = nextEdgeCorner >= Math.PI;
                }

                let source = this.combinePoints(e.source, radPoint);
                let target = this.combinePoints(e.target, radPoint);

                let nextEdgeAngle = this.findVectorAngle(this.seg2ToVec2(nextEdge.source, nextEdge.target));
                let nextEdgeRadPoint = this.findRadialPoint(nextEdge.offset, nextEdgeAngle + Math.PI * 0.5);
                let nextSource = this.combinePoints(nextEdge.source, nextEdgeRadPoint);

                if(dethorn) {
                    let prevEdge = edges[i === 0 ? edges.length - 1 : i - 1];

                    if(e.offset > prevEdge.offset) {
                        let prevEdgeCorner = this.findCornerAngle(e.source, prevEdge.source, e.target);

                        if(prevEdgeCorner >= 0.5 * Math.PI && prevEdgeCorner < Math.PI) {
                            let prevEdgeAngle = this.findVectorAngle(this.seg2ToVec2(prevEdge.source, prevEdge.target));
                            let prevEdgeRadPoint = this.findRadialPoint(prevEdge.offset, prevEdgeAngle + Math.PI * 0.5);
                            let prevSource = this.combinePoints(prevEdge.source, prevEdgeRadPoint);
                            let prevTarget = this.combinePoints(prevEdge.target, prevEdgeRadPoint);

                            let extendedRadPoint = this.findRadialPoint(1000000, angle + Math.PI);
                            let extendedSource = this.combinePoints(source, extendedRadPoint);

                            let intersectionPoint = this.intersectionPointSeg2(prevSource, prevTarget, extendedSource, target);

                            if(intersectionPoint) {
                                let safetyExtension = this.findRadialPoint(e.offset / 2, angle + Math.PI);
                                source =  this.combinePoints(intersectionPoint, safetyExtension);
                            }
                        }


                        offsetEdges.push({
                            source: e.source,
                            target: source,
                            winding: this.edgeWinding(e.source, source)
                        })
                    }

                    if(e.offset > nextEdge.offset) {
                        if (nextEdgeCorner >= 0.5 * Math.PI && nextEdgeCorner < Math.PI) {
                            let nextTarget = this.combinePoints(nextEdge.target, nextEdgeRadPoint);

                            let extendedRadPoint = this.findRadialPoint(1000000, angle);
                            let extendedTarget = this.combinePoints(target, extendedRadPoint);

                            let intersectionPoint = this.intersectionPointSeg2(source, extendedTarget, nextSource, nextTarget)

                            if (intersectionPoint) {
                                let safetyExtension = this.findRadialPoint(e.offset / 2, angle);
                                target = this.combinePoints(intersectionPoint, safetyExtension)
                            }
                        }
                    }
                }

                offsetEdges.push({
                    source: source,
                    target: target,
                    winding: this.edgeWinding(source, target)
                })

                if (concaveTarget) {
                    if (!this.samePoint(target, nextSource) && e.offset === nextEdge.offset) {
                        let midAngle = this.findMidAngle(radPoint, nextEdgeRadPoint) + (e.offset < 0 ? Math.PI : 0);
                        let midRadPoint = this.findRadialPoint((e.offset + nextEdge.offset) / 2, midAngle);
                        let midPoint = this.combinePoints(e.target, midRadPoint);

                        offsetEdges.push({
                            source: target,
                            target: midPoint,
                            winding: this.edgeWinding(target, midPoint)
                        })

                        offsetEdges.push({
                            source: midPoint,
                            target: nextSource,
                            winding: this.edgeWinding(midPoint, nextSource)
                        })
                    } else  {
                        offsetEdges.push({
                            source: target,
                            target: nextSource,
                            winding: this.edgeWinding(target, nextSource)
                        })
                    }
                } else {
                    if (e.offset) {
                        offsetEdges.push({
                            source: target,
                            target: e.target,
                            winding: this.edgeWinding(target, e.target)
                        })

                        if(!dethorn || e.offset >= nextEdge.offset) {
                            offsetEdges.push({
                                source: e.target,
                                target: nextSource,
                                winding: this.edgeWinding(e.target, nextSource)
                            })
                        }
                    }
                }
            }
            // }
        });

        return offsetEdges;
    }

    findVectorAngle(v) {
        return Math.atan2(v.y, v.x);
    }

    intersectEdgesByLineSweeping(edges) {
        // TODO: temp code for restructuring edge list
        let newEdges = [];
        edges.forEach(e => {
            let s = e.source;
            let t = e.target;
            newEdges.push({
                source: s.x === t.x ? (s.y < t.y ? s : t) : (s.x < t.x ? s : t),
                target: s.x === t.x ? (s.y > t.y ? s : t) : (s.x > t.x ? s : t),
                ip: []
            })
        })
        // TODO: temp code end

        let pointMap = new Map();
        // TODO: temp code thingy: edges.forEach(e => {
        newEdges.forEach(e => {
            let sx = +e.source.x.toFixed(8);
            let sy = +e.source.y.toFixed(8);
            let sourceId = `${sx === 0 ? 0 : sx}|${sy === 0 ? 0 : sy}`;
            if(!e.source.id) {
                e.source.id = sourceId;
            }

            if(pointMap.has(sourceId)) {
                pointMap.get(sourceId).edges.push(e);
            } else {
                pointMap.set(sourceId, {
                    id: sourceId,
                    x: e.source.x,
                    y: e.source.y,
                    edges: [e]
                });
            }

            let tx = +e.target.x.toFixed(8);
            let ty = +e.target.y.toFixed(8);
            let targetId = `${tx === 0 ? 0 : tx}|${ty === 0 ? 0 : ty}`;
            if(!e.target.id) {
                e.target.id = targetId;
            }

            if(pointMap.has(targetId)) {
                pointMap.get(targetId).edges.push(e);
            } else {
                pointMap.set(targetId, {
                    id: targetId,
                    x: e.target.x,
                    y: e.target.y,
                    edges: [e]
                });
            }
        });

        let eventList = Array.from(pointMap.values()).sort((a, b) => {
            if(a.x === b.x) {
                return a.y - b.y;
            } else {
                return a.x - b.x;
            }
        });
        let sweepStatus = [];

        for(let i = 0; i < eventList.length; i++) {
            let eventPoint = eventList[i];

            eventPoint.edges.forEach(e => {
                if(this.samePoint(eventPoint, e.source)) {
                    let newEdge = {
                        edge: e,
                    }
                    sweepStatus.forEach(s => {
                        let ip = this.intersectionPointSeg2(newEdge.edge.source, newEdge.edge.target, s.edge.source, s.edge.target);
                        if(ip && !this.samePoint(ip, s.edge.source, 8) && !this.samePoint(ip, s.edge.target, 8)) {
                            let ipx = +ip.x.toFixed(8);
                            let ipy = +ip.y.toFixed(8);
                            let ipId = `${ipx === 0 ? 0 : ipx}|${ipy === 0 ? 0 : ipy}`;

                            s.edge.ip.push({
                                id: ipId,
                                x: ip.x,
                                y: ip.y,
                                dist: this.calculateDistance(s.edge.source, ip)
                            });
                            newEdge.edge.ip.push({
                                id: ipId,
                                x: ip.x,
                                y: ip.y,
                                dist: this.calculateDistance(newEdge.edge.source, ip)
                            });
                        }
                    })
                    sweepStatus.push(newEdge);
                } else if(this.samePoint(eventPoint, e.target)) {
                    sweepStatus.splice(sweepStatus.findIndex(s => s.edge === e), 1);
                }
            });
        }

        let graphMap = new Map()
        newEdges.forEach(e => {
            let edgePoints = [...[e.source], ...e.ip.sort((a, b) => a.dist - b.dist), ...[e.target]];

            for(let i = 0; i < edgePoints.length - 1; i++) {
                let cp = edgePoints[i];
                let np = edgePoints[i + 1];

                if(graphMap.has(cp.id)) {
                    graphMap.get(cp.id).connectedPoints.push(np.id);
                } else {
                    graphMap.set(cp.id, {
                        id: cp.id,
                        x: cp.x,
                        y: cp.y,
                        connectedPoints: [np.id]
                    });
                }

                if(graphMap.has(np.id)) {
                    graphMap.get(np.id).connectedPoints.push(cp.id);
                } else {
                    graphMap.set(np.id, {
                        id: np.id,
                        x: np.x,
                        y: np.y,
                        connectedPoints: [cp.id]
                    });
                }
            }
        })

        return graphMap;
    }

    findOffsetFaces(pointMap) {
        let pointPool = Array.from(pointMap.values());
        let checkedEdges = new Map();
        let cycles = [];

        while(pointPool.length > 0) {
            let faceCandidates = [];
            let startingPoint = pointPool.shift();

            startingPoint.connectedPoints.forEach(pid => {
                let p = pointMap.get(pid);
                if(!checkedEdges.has(`${startingPoint.id},${p.id}`)) {
                    let candidate = {
                        closedCycle: false,
                        finishPoint: startingPoint,
                        previousPoint: startingPoint,
                        currentPoint: p,
                        points: [startingPoint, p],
                        cycleEdgeMap: new Map([[startingPoint.id + p.id, true]])
                    }

                    faceCandidates.push(candidate);
                    checkedEdges.set(`${startingPoint.id},${p.id}`, true);
                }
            });

            faceCandidates.forEach(c => {
                let runningCycle = true;
                while (runningCycle) {
                    let minAngle = Math.PI * 2;
                    let closestClockwisePoint;
                    c.currentPoint.connectedPoints.forEach(pid => {
                        let p = pointMap.get(pid)
                        if (p.id !== c.previousPoint.id) {
                            if (p.id === c.finishPoint.id) {
                                c.closedCycle = true;
                                runningCycle = false;
                                checkedEdges.set(`${c.currentPoint.id},${p.id}`, true);
                            } else {
                                let angle = this.findCornerAngle(c.currentPoint, c.previousPoint, p);
                                if (angle < minAngle) {
                                    minAngle = angle;
                                    closestClockwisePoint = p;
                                }
                            }
                        }
                    });

                    if (!c.closedCycle && closestClockwisePoint) {
                        if(c.cycleEdgeMap.has(closestClockwisePoint.id + c.currentPoint.id)) {
                            runningCycle = false;
                        } else {
                            c.cycleEdgeMap.set(c.currentPoint.id + closestClockwisePoint.id, true);
                            c.previousPoint = c.currentPoint;
                            c.currentPoint = closestClockwisePoint;
                            c.points.push(closestClockwisePoint);
                            checkedEdges.set(`${c.previousPoint.id},${c.currentPoint.id}`, true);
                        }
                    } else {
                        if(c.closedCycle) {
                            if (c.cycleEdgeMap.has(c.finishPoint.id + c.currentPoint.id)) {
                                c.closedCycle = false;
                            }
                        }
                        runningCycle = false;
                    }
                }
            })

            faceCandidates.filter(c => c.closedCycle).forEach((c) => {
                cycles.push(c.points);
            })
        }

        return cycles;
    }

    createPointInPolygon(sourcePol) {
        let pol = [...sourcePol];
        let pointInPolygon = false;

        if(pol.length > 2) {
            if (!this.polygonWindingClockwise(pol)) {
                pol = pol.reverse();
            }

            for (let i = 0; i < pol.length; i++) {
                let p = pol[i];
                let pp = pol[i === 0 ? pol.length - 1 : i - 1];
                let np = pol[i === pol.length - 1 ? 0 : i + 1];

                let validEar = false;

                if (this.findCornerAngle(p, pp, np) < Math.PI) {
                    validEar = true;
                    pol.forEach(cp => {
                        if (cp !== p && cp !== pp && cp !== np) {
                            if (this.determinePointInPolygon(cp, [pp, p, np])) {
                                validEar = false;
                            }
                        }
                    })
                }

                if (validEar) {
                    pointInPolygon = {
                        x: (pp.x + np.x) / 2,
                        y: (pp.y + np.y) / 2
                    }

                    pointInPolygon = {
                        x: (p.x + pointInPolygon.x) / 2,
                        y: (p.y + pointInPolygon.y) / 2,
                    }
                    i = pol.length;
                }
            }
        }

        return pointInPolygon;
    }

    findRadialPoint(r, a) {
        return {
            x: r * Math.cos(a),
            y: r * Math.sin(a)
        }
    }

    combinePoints(p1, p2) {
        return {x: p1.x + p2.x, y: p1.y + p2.y}
    }

    edgeWinding(p1, p2) {
        let angle = this.findVectorAngle(this.seg2ToVec2(p1, p2));
        return (angle > 0 && angle < Math.PI) ? 1 : -1
    }

    intersectionPointSeg2(p1, p2, p3, p4) {
        let intersectionPoint = false;

        let r = this.seg2ToVec2(p1, p2);
        let s = this.seg2ToVec2(p3, p4);

        let uNumerator = this.crossProductVec2(
            this.seg2ToVec2(p1, p3
            ), r);
        let denominator = this.crossProductVec2(r, s);

        let u = uNumerator / denominator;
        let t = this.crossProductVec2(
            this.seg2ToVec2(p1, p3), s) / denominator;

        if((t >= 0) && (t <= 1) && (u >= 0) && (u <= 1)) {
            intersectionPoint = {
                x: p1.x + t * r.x,
                y: p1.y + t * r.y
            };
        }

        return intersectionPoint;
    }

    crossProductVec2(v1, v2) {
        return v1.x * v2.y - v1.y * v2.x;
    }

    samePoint(p1, p2, decimalCount = false) {
        if(decimalCount) {
            return p1.x.toFixed(decimalCount) === p2.x.toFixed(decimalCount) && p1.y.toFixed(decimalCount) === p2.y.toFixed(decimalCount);
        }
        return p1.x === p2.x && p1.y === p2.y;
    }

    findMidAngle(v1, v2) {
        let v1n = this.normalizeVector(v1);
        let v2n = this.normalizeVector(v2);
        let cv = this.combinePoints(v1n, v2n);
        return Math.atan2(cv.y, cv.x);
    }

    normalizeVector(v) {
        let mag = this.findVectorLength(v);
        return {x: v.x / mag, y: v.y / mag};
    }

    findVectorLength(v) {
        return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2));
    }

    determinePointInPolygon(p, pol) {
        let intersections = 0;
        let pSeg = {
            p1: p,
            p2: {
                x: p.x + 100000000,
                y: p.y
            }};

        pol.forEach((pp, i) => {
            let polSeg = {
                p1: pp,
                p2: i === pol.length - 1 ? pol[0] : pol[i + 1]
            }

            if (this.testLinesIntersecting(pSeg, polSeg)) {
                intersections++;
            }
        });

        return intersections % 2 === 1;
    }

    removeCollinearPoints(polygon) {
        let i = 0;
        while (i < polygon.length) {
            const p1 = polygon[i];
            const p2 = polygon[(i + 1) % polygon.length];
            const p3 = polygon[(i + 2) % polygon.length];

            if (this.areCollinear(p1, p2, p3)) {
                polygon.splice((i + 1) % polygon.length, 1);
            } else {
                i++;
            }
        }

        return polygon;
    }

    areCollinear(p1, p2, p3) {
        let p1p3Length = this.calculateDistance(p1, p3).toFixed(8);
        let p1p2p3Length = (this.calculateDistance(p1, p2) + this.calculateDistance(p2, p3)).toFixed(8);

        let dif = p1p3Length - p1p2p3Length;

        return dif === 0;
    }
}

const instance = new Utils();
export default instance;