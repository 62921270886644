<template>
  <v-container>
    <v-flex>
      <v-banner>
        <h5>2solar update status</h5>
      </v-banner>
      <v-banner>Last updated: {{ this.getLeadUpdateStatus.date }}</v-banner>
      <v-banner>Updated records: {{ this.getLeadUpdateStatus.updated }}</v-banner>
      <v-banner>Newly created records: {{ this.getLeadUpdateStatus.created }}</v-banner>
      <v-banner>
        Errors: {{ this.getLeadUpdateStatus.amount_errorrs }}
        <br>
        Error list: {{ this.getLeadUpdateStatus.errors }}
      </v-banner>
    </v-flex>
    <v-flex v-if="!loadingState">
      <v-btn @click="forceUpdateLeads()">FORCE DB UPDATE</v-btn>
    </v-flex>
    <v-flex v-else>
      <v-layout
          row
          fill-height
          justify-center
          align-center
          v-if="this.loadingState"
      >

        <v-progress-circular
            :size="80"
            color="primary"
            indeterminate
        />

      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>

export default {
  name: "Admin",
  components: {},
  data() {
    return {
      leadUpdateStatus: "",
      updateAbleLeads: "",
      csvUpdateStatus: "",
      dialog: false
    }
  },
  async created() {
    this.dialog = true;
    await this.$store.dispatch('solar/fetchLeadUpdateStatus');
    // this.getUpdateAbleLeads();
    this.getCsvUploadStatus();
  },
  computed: {
    loadingState() {
      return this.$store.getters['solar/getUpdatingStatus']
    },
    getLeadUpdateStatus() {
      let a = this.$store.getters['solar/getLeadUpdateStatus'];
      return a.status != null ? a.status.latest_update_info : [];
    },
  },
  methods: {
    async forceUpdateLeads() {
      await this.$store.dispatch('solar/forceUpdateLeadUpdateStatus');
      this.getLeadUpdateStatus();
    },

    // getUpdateAbleLeads() {
    //   let a = this.$store.getters['solar/getLeadUpdateStatus'];
    //   this.updateAbleLeads = a.status != null ? a.status.update_able_leads : null;
    // },
    getCsvUploadStatus() {
      let a = this.$store.getters['solar/getLeadUpdateStatus'];
      this.csvUpdateStatus = a.status != null ? a.status.csv_update_status : null;
    },
    async updateAll() {
      await this.$store.dispatch('solar/fetchLeadUpdateStatus');
      this.getLeadUpdateStatus();
      // this.getUpdateAbleLeads();
      this.getCsvUploadStatus();
    }
  }
}
</script>

<style scoped>

</style>