import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import IdleVue from "idle-vue";
import VueSimpleAlert from "vue-simple-alert";
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import vuetify from './plugins/vuetify'
// import VueGoogleHeatmap from 'vue-google-heatmap';
import VueMobileDetection from 'vue-mobile-detection';
import VueGoogleHeatmap from "vue-google-heatmap";

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.use(VueSimpleAlert);

const eventsHub = new Vue();

export const idleTime = 600 * 100 * 10 * 2; //20 min

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAx4xB9rBdGx_u-uhtQktdRvBZovpZOq6w',
        // libraries: ["places"],
    }
});
Vue.component('GmapCluster', GmapCluster);

Vue.use(VueGoogleHeatmap,
    {
        apiKey: 'AIzaSyAx4xB9rBdGx_u-uhtQktdRvBZovpZOq6w'
    }
)

Vue.use(Vuex);

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    router,
    idleTime: idleTime,
    startAtIdle: false
});
Vue.use(VueMobileDetection);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');