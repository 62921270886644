<script>
import {Bar} from "vue-chartjs";
import {Helper as helper} from "@/helper";

export default {
  extends: Bar,
  props: ['data', 'labels', 'title'],
  mounted() {
    this.init();
  },
  watch: {
    'data'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.renderChart(
          {
            labels: this.labels,
            datasets: this.data,
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            options: {
              scales: {
                type: 'percent',
                yAxes: [{
                  ticks: {

                  },
                  gridLines: {
                    display: false
                  }
                }],
                xAxes: [{
                  gridLines: {
                    display: true
                  }
                }]
              },
              legend: {
                display: true
              },
            },
            title: {
              display: true,
              text: this.title,
              fontFamily: 'Poppins'
            },
            legend: {
              display: true,
              position: 'top',
              fullWidth: true,
              labels: {
                boxWidth: 10,
                padding: 10,
                fontFamily: 'Poppins'
              }
            }
          }
      );
    },
    getMonthName(date) {
      console.log(parseInt(date.slice(0, 4)))
      return helper.extractMonth(date);
    },
  }
}
</script>
<style scoped>

</style>
