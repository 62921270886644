import LeadService from '../services/lead.service'

export const leads = {
    state: {
        leadList: [],
        duurzaamOverviewList: [],
        postStatus: true,
        leadOverviewData: [],
        userOverview: [],
        userOverviewKpi: [],
        teamOverview: [],
        teamOverviewKpi: [],
        userPeriodCount: [],
        userStats: [],
        teamStats: [],
        teamOverviewLopers: [],
        usersFileteredOnX: [],
        creditUser: [],
        newLead: ''
    },
    namespaced: true,
    actions: {
        getAll({commit},) {
            return LeadService.getLeads().then(
                leadJson => {
                    commit('SET_LEADS', leadJson);
                },
                error => {

                    return Promise.reject(error);
                }
            );
        }, getDuurzaamCountOverview({commit},) {
            return LeadService.getDuurzaamCountOverview().then(
                leadJson => {
                    commit('SET_DUURZAAM_OVERVIEW', leadJson);
                },
                error => {

                    return Promise.reject(error);
                }
            );
        },
        postLead({commit}, leadObj) {

            return LeadService.createLead(leadObj).then(
                lead => {
                    commit('ADD_LEADS', leadObj);
                    return Promise.resolve(lead);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getLeadOverview({commit},) {
            return LeadService.getLeadOverview().then(
                d => {
                    commit('SET_LEAD_OVERVIEW_DATA', d);
                    return Promise.resolve(d);
                }
            )
        },
        getUserOverview({commit}, {date}) {
            return LeadService.getUserOverview(date).then(
                d => {
                    commit('SET_USER_OVERVIEW', d);
                    return Promise.resolve(d);
                }
            )
        },
        getUserOverviewKpi({commit}, {date}) {
            return LeadService.getUserOverviewKpi(date).then(
                d => {
                    commit('SET_USER_OVERVIEW_KPI', d);
                    return Promise.resolve(d);
                }
            )
        },
        getTeamOverview({commit}, {date}) {
            return LeadService.getTeamOverview(date).then(
                d => {
                    commit('SET_TEAM_OVERVIEW', d)
                    return Promise.resolve(d);
                }
            )
        },
        getTeamOverviewKpi({commit}, {date}) {
            return LeadService.getTeamOverviewKpi(date).then(
                d => {
                    commit('SET_TEAM_OVERVIEW_KPI', d)
                    return Promise.resolve(d);
                }
            )
        },
        getUserPeriodCount({commit}, {team, period}) {
            return LeadService.getUserPeriodCount(team, period).then(
                d => {
                    commit('SET_USER_PERIOD_COUNT', d);
                    return Promise.resolve(d);
                }
            )
        },
        getUserStats({commit}, {date, user}) {
            return LeadService.getUserStats(date, user).then(
                d => {
                    commit('SET_USER_STATS', d);
                    return Promise.resolve(d);
                }
            )
        },
        getTeamStats({commit}, {date, team}) {
            return LeadService.getTeamStats(date, team).then(
                d => {
                    commit('SET_TEAM_STATS', d);
                    return Promise.resolve(d);
                }
            )
        },
        getTeamOverviewLopers({commit}, {team, date}) {
            return LeadService.getTeamOverviewLopers(team, date).then(
                d => {
                    commit('SET_TEAM_OVERVIEW_LOPERS', d);
                    return Promise.resolve(d);
                }
            )
        },

        getUsersPerTeamFilteredOnX({commit},) {
            return LeadService.getUsersFilteredOnX().then(
                d => {
                    commit('SET_TEAM_USERS_FILTERED_ON_X', d);
                    return Promise.resolve(d);
                }
            )
        },

        getCreditPerUser({commit},{user, date, period}) {
            return LeadService.getCreditPerUser(user, date, period).then(
                d => {
                    commit('SET_CREDIT_USER', d);
                    return Promise.resolve(d);
                }
            )
        },

        registerUserWorkedWithoutLeads({commit}, {firstName, lastName}) {
            return LeadService.registerUserWorkedWithoutLeads(firstName, lastName).then(
                () => {
                    return LeadService.getUsersFilteredOnX().then(
                        d => {
                            commit('SET_TEAM_USERS_FILTERED_ON_X', d);
                            return Promise.resolve(d);
                        }
                    )

                },
                err => {
                    return Promise.reject(err);
                }
            );
        },

    },
    mutations: {
        SET_LEADS(state, leads) {
            state.leadList = leads;
        },
        SET_DUURZAAM_OVERVIEW(state, overview) {
            state.duurzaamOverviewList = overview;
        },
        ADD_LEADS(state, lead) {
            const clone = {...lead};
            state.leadList.push(clone);
        },
        SET_LEAD_OVERVIEW_DATA(state, leadOverviewData) {
            state.leadOverviewData = leadOverviewData;
        },
        SET_USER_OVERVIEW(state, userOverview) {
            state.userOverview = userOverview;
        },
        SET_USER_OVERVIEW_KPI(state, userOverviewKpi) {
            state.userOverviewKpi = userOverviewKpi;
        },
        SET_TEAM_OVERVIEW(state, teamOverview){
            state.teamOverview = teamOverview;
        },
        SET_TEAM_OVERVIEW_KPI(state, teamOverviewKpi){
            state.teamOverviewKpi = teamOverviewKpi;
        },
        SET_USER_STATS(state, userStats) {
            state.userStats = userStats;
        },
        SET_TEAM_STATS(state, teamStats) {
            state.teamStats = teamStats;
        },
        SET_USER_PERIOD_COUNT(state, userPeriod) {
            state.userPeriodCount = userPeriod;
        },
        SET_TEAM_OVERVIEW_LOPERS(state, teamOverviewLopers) {
            state.teamOverviewLopers = teamOverviewLopers;
        },
        SET_TEAM_USERS_FILTERED_ON_X(state, usersFileteredOnX) {
            state.usersFileteredOnX = usersFileteredOnX;
        },
        SET_CREDIT_USER(state, creditUser) {
            state.creditUser = creditUser;
        }
    },
    getters: {
        leadList: state => {
            return state.leadList;
        },
        leadOverviewData: state => {
            return state.leadOverviewData;
        },
        userOverview: state => {
            return state.userOverview;
        },
        userOverviewKpi: state => {
            return state.userOverviewKpi;
        },
        teamOverview: state => {
            return state.teamOverview;
        },
        teamOverviewKpi: state => {
            return state.teamOverviewKpi;
        },
        userStats: state => {
            return state.userStats;
        },
        teamStats: state => {
            return state.teamStats;
        },
        getUserPeriodCount: state => {
            return state.userPeriodCount;
        },
        getTeamOverviewLopers: state => {
            return state.teamOverviewLopers;
        },
        getUsersFileteredOnX: state => {
            return state.usersFileteredOnX;
        },
        getCreditPerUser: state => {
            return state.creditUser;
        },
        getDuurzaamOverview: state => {
            return state.duurzaamOverviewList;
        }
    }
}