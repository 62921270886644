<script>
import {HorizontalBar} from "vue-chartjs";

export default {
  extends: HorizontalBar,
  name: "HorizontalProgressDataTable",
  props: ['item'],
  watch: {
    'item'() {
      this.render()
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
          {
            labels: ['Sale', 'In verwerking', 'Verzetten', 'Verwerkt', 'Cancelled'],
            datasets: [
              {
                label: 'Sale',
                backgroundColor: '#f56e22',
                data: [this.item.sale]
              },
              {
                label: 'Te verwerken',
                backgroundColor: '#57ac91',
                data: [this.item.in_progress]
              },
              {
                label: 'Verzetten',
                backgroundColor: '#c77d94',
                data: [this.item.verzetten]
              },
              {
                label: 'Verwerkt',
                backgroundColor: '#9e84c9',
                data: [this.item.progressed]
              },
              {
                label: 'Cancelled',
                backgroundColor: '#458cff',
                data: [this.item.cancel_lead]
              },
            ]
          },
          {

            responsive: true, maintainAspectRatio: false,
            legend: {
              display: false,
              position: 'bottom',
              fullWidth: true,
              labels: {
                boxWidth: 10,
                padding: 50
              }
            },
            scales: {
              xAxes: [{
                display: false,
                gridLines: {
                  display: false,
                  drawTicks: false,
                  drawOnChartArea: false,
                  scaleLabel: {
                    display: false,
                  }
                },
                stacked: true,
                categoryPercentage: 0.7,
                barPercentage: 0.8,
                scaleLabel: {
                  display: true,
                }
              }],
              yAxes: [{
                display: false,
                gridLines: {
                  display: false,
                  drawTicks: false,
                  drawOnChartArea: false
                },
                stacked: true
              }]
            },
            tooltips: {
              mode: 'index',
              enabled: false,
              title: "",
              titleAlign: 'center',
              footerAlign: 'center',
              // callbacks: {
              // footer: ((tooltipItems, datas) => {
              //   let idx = datas.labels.indexOf(tooltipItems[0].label);
              //   return "Totaal:" + " " + datas.datasets
              //       .flatMap(f => f.data[idx])
              //       .filter(f => f != null)
              //       .reduce((a, b) => a + b, 0);
              // })
              // }
            }
          }
      );
    }
  }
}
</script>

<style scoped>

</style>