import httpClient from "@/services/httpClient";
import authHeader from "@/services/auth-header";

const endpoint = 'dashboard/';

class Dashboard {
    async getDailyQuote() {
        return await httpClient.get(endpoint + 'dailyQuote', {headers: authHeader()}).then(res => {
            return res.data
        });
    }
}

export default new Dashboard();