<template>
  <v-row>
    <v-col>
      <v-dialog
          transition="dialog-bottom-transition"
          max-width="400"
          v-model="dialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              icon
          >
            <v-icon dark>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </template>
        <template>
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >System update
            </v-toolbar>
            <v-container>
              <v-file-input
                  label="csv 2solar export"
                  v-model="file"
                  :disabled="this.disabled"
              ></v-file-input>
            </v-container>
            <v-card-actions class="justify-end">
              <v-btn
                  text
                  @click="updateSystem();"
                  color="primary"
                  :loading="this.loading"
              >update
              </v-btn>
            </v-card-actions>


          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>

</template>

<script>

export default {
  name: "Upload2SolarCsv",
  data: () => ({
    dialog: false,
    file: null,
    loading: false,
    disabled: false,
  }),
  computed:{
    isUploading(){
      return this.$store.getters['solar/getUploadStatus']
    }
  },
  methods: {
    updateSystem() {

      let data = new FormData();
      data.append("file", this.file);

      if (this.file === null) {
        this.$fire({
          position: 'top-end',
          type: 'warning',
          text: "Geen bestand ontvangen",
          showConfirmButton: false,
          timer: 2000
        })
      }else {
        this.loading = true;
        this.dialog = false;
        this.disabled = true;

        this.$store.dispatch('solar/postCsv', data).then(
            () => {
              this.$fire({
                position: 'top-end',
                type: 'success',
                text: "Systeem wordt geupdate, dit kan even duren..",
                showConfirmButton: false,
                timer: 3500
              });
              this.file = null;
              this.loading = false;
              this.disabled = false;
            }
        ).catch(err => {
          this.$fire({
            position: 'top-end',
            type: 'error',
            text: err.response != null ? err.response.data.error : err,
            showConfirmButton: true,
            confirmButtonColor: "primary"
          });

          this.loading = false;
          this.disabled = false;
          this.file = null;
        });
      }
    },
  },
}
</script>

<style scoped>

</style>