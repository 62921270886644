import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        getUserData({commit}){
            return AuthService.getUserData().then(res => {
                commit('fetchUser', res[0]);
                return Promise.resolve(res);
            })
        }
    },
    mutations: {
        loginSuccess(state) {
            state.status.loggedIn = true;
           // state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        fetchUser(state, userData){
            state.user = userData;
        }
    },
    getters: {
        getUser: state => {
            return state.user
        }
    }
};