<template>

  <component v-bind:is="this.component"/>

</template>

<script>

import Leads from "@/views/lead/Leads";
import DashBoard from "@/views/DashBoard";
import LopersOverview from "@/views/LopersOverview";

export default {
  data() {
    return {
      component: '',

    };
  },
  components: {DashBoard, Leads, LopersOverview},
  mounted() {
    this.$store.dispatch('auth/getUserData').then(user => {
      if ((user[0].groups.includes('admin')
          || user[0].groups.includes('beheer'))
          || user[0].groups.includes('duurzaam')
          || user[0].groups.includes('loperscaptain')
          || user[0].groups.includes('accountmngr')
          || user[0].groups.includes('teleteamcaptain')
          || user[0].groups.includes('teleteam')) {
        this.component = "DashBoard";
      } else if (user[0].groups.includes('lopers')) {
        this.component = "Leads";
      } else if (user[0].groups.includes('viewer')) {
        this.component = "LopersOverview";
      }
    })

  }, computed: {}
};

</script>

<style>

</style>
