<template>
  <v-col cols="12"
         md="3"
  >
    <v-dialog v-model="dialog[lead]" width="auto">
      <template v-slot:activator="{ on }">
        <v-btn :style="leadColor" @click.stop="$set(dialog, lead, true)" v-on="on">
          <!--        <v-card-text>{{lead.first_name}} {{lead.last_name}}</v-card-text><br>-->

          <div id="leadCardTitle">

            {{ lead.aanhef }} {{ lead.achternaam }}
          </div>
        </v-btn>
      </template>

      <v-card width="auto" class="cardDetail">
        <v-container>
          <v-row justify="center">

            <v-col cols="12" sm="5">
              <v-card-text>
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="title">{{ lead.aanhef }} </v-list-item-title>
                      <v-list-item-title class="title">{{ lead.achternaam }} </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Adres</v-list-item-subtitle>
                      <v-col>
                        <v-list-item-title>{{ lead.postcode }} {{ lead.huisnummer }} {{ lead.huisNrToevoeging }}
                        </v-list-item-title>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>


                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Mobiel nummer</v-list-item-subtitle>
                      <v-col>
                        <v-list-item-title>{{ lead.tel_mobiel }}</v-list-item-title>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content v-if="lead.tel_vast">
                      <v-list-item-subtitle>Vast nummer:</v-list-item-subtitle>
                      <v-col>
                        <v-list-item-title>{{ lead.tel_vast }}</v-list-item-title>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content v-if="lead.emailaddress">
                      <v-list-item-subtitle>Email:</v-list-item-subtitle>
                      <v-col>
                        <v-list-item-title>{{ lead.emailaddress }}</v-list-item-title>
                      </v-col>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-card-text>
            </v-col>

            <v-col cols="12" sm="5">
              <v-card-text>

                <v-list-item-content v-if="lead.investering">
                  <v-card-subtitle>Investering</v-card-subtitle>
                  <v-col>
                    <v-list-item-title>{{ lead.investering }}</v-list-item-title>
                  </v-col>
                </v-list-item-content>

                <v-list-item-content v-if="lead.dakkapel">
                  <v-card-subtitle>Dakkapellen</v-card-subtitle>
                  <v-col>
                    <v-list-item-title>{{ lead.dakkapel }}</v-list-item-title>
                  </v-col>
                </v-list-item-content>

                <v-list-item-content v-if="lead.huishouden">
                  <v-card-subtitle>Huishouden</v-card-subtitle>
                  <v-col>
                    <v-list-item-title>{{ lead.huishouden }}</v-list-item-title>
                  </v-col>
                </v-list-item-content>

                <v-list-item-content v-if="lead.dienstverband">
                  <v-card-subtitle>Inkomen uit</v-card-subtitle>
                  <v-col>
                    <v-list-item-title>{{ lead.dienstverband }}</v-list-item-title>
                  </v-col>
                </v-list-item-content>

                <v-list-item-content v-if="lead.dienstverband_partner">
                  <v-card-subtitle>Inkomen Partner</v-card-subtitle>
                  <v-col>
                    <v-list-item-title>{{ lead.dienstverband_partner }}</v-list-item-title>
                  </v-col>
                </v-list-item-content>

                <v-list-item-content v-if="lead.comment">
                  <v-card-subtitle>Extra gegevens</v-card-subtitle>
                  <v-col>
                    <v-list-item-title>{{ lead.comment }}</v-list-item-title>
                  </v-col>
                </v-list-item-content>

              </v-card-text>
            </v-col>
          </v-row>

        </v-container>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  name: "LeadView",
  props: ['lead'],
  created() {

    if (this.lead.is_duurzaam_batterij) {
        this.leadColor.backgroundColor = this.$vuetify.theme.currentTheme.primary;
      }else if (this.lead.is_duurzaam_warmtepomp) {
        this.leadColor.backgroundColor =  this.$vuetify.theme.currentTheme.secondary;
      }else if (this.lead.is_duurzaam_zonnepaneel) {
        this.backgroundColor =  this.$vuetify.theme.currentTheme.accent;
      }
  },
  data() {
    return {
      dialog: {},
      leadColor: {
        backgroundColor: '#fc8813'
    }
    }
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

* {
  text-transform: capitalize !important;
}

#leadCardTitle {
  font-family: Poppins;
  font-size: 23.5px;
  color: #ffffff;
}

#cardDetail {
  font-family: Poppins;

}

</style>
