import Vue from 'vue';
import Router from 'vue-router';
import Home from "@/views/Home";
import Login from "@/views/Login";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresAuth: false
            }
         }
        // {
        //     path: '/lead',
        //     name: 'lead',
        //     component: Leads,
        //     meta: {
        //         requiresAuth: true
        //     }
        // },
        // {
        //     path: '/leadstats',
        //     name: 'leadstats',
        //     component: LeadStats,
        //     meta: {
        //         requiresAuth: true
        //     }
        // },
        // {
        //     path: '/admin',
        //     name: 'admin',
        //     // lazy-loaded
        //     component: () => import('../views/boards/BoardAdmin.vue')
        // }
    ]
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user');

    if (to.meta. requiresAuth && !loggedIn) {
        next({name: 'login'});
    }else if(!to.meta.requiresAuth && loggedIn){
        next({name: 'home'});
    } else {
        next({});
    }
});

export default router;