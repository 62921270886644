<template>
  <v-container grid-list-md fill-height id="back">
    <v-layout row wrap align-center justify-center>
      <v-flex xs7 sm8 lg5 md8 id="login">
        <v-spacer/>
        <v-card-text class="primary">
          <v-layout justify-center align-center>
            <h4 id="loginText">{{ this.loginTitle }}</h4>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout
              row
              fill-height
              justify-center
              align-center
              v-if="loading"
          >
            <v-progress-circular
                :size="80"
                color="primary"
                indeterminate
            />
          </v-layout>


          <v-form ref="form" name="login" @submit.prevent="handleLogin">
            <v-container>
              <div class="form-group">
                <v-text-field
                    v-model="user.username"
                    v-validate="'required'"
                    prepend-icon="mdi-account"
                    clearable
                    type="text"
                    placeholder="Inlognaam"
                    name="username"
                />
                <div
                    v-if="errors.has('username')"
                    class="alert alert-danger"
                    role="alert"
                >{{ this.forgotUsername }}
                </div>

                <v-text-field
                    v-model="user.password"
                    v-validate="'required'"
                    prepend-icon="mdi-lock"
                    clearable
                    type="password"
                    placeholder="Wachtwoord"
                    name="password"
                />

                <div
                    v-if="errors.has('password')"
                    class="alert alert-danger"
                    role="alert"
                >{{ this.forgotPass }}
                </div>
              </div>

              <v-container>
                <div class="form-group flex">
                  <v-btn id="btn-rounded" type="submit" class="primary" :disabled="loading">
                    <span>{{ this.loginBtnText }}</span>
                  </v-btn>
                </div>
              </v-container>

              <div
                  v-if=this.loginFailed
                  class="alert alert-danger"
                  role="alert"
              >{{ this.loginFailed }}
              </div>

            </v-container>
          </v-form>
        </v-card-text>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import User from "@/model/user";

export default {
  name: 'Login',
  data() {
    return {
      user: new User(''),
      loading: false,
      forgotPass: 'Wachtwoord vergeten ?',
      forgotUsername: 'Gebruikersnaam vergeten ?',
      loginBtnText: 'Login',
      loginTitle: 'Werknemer',
      loginFailed: ''
    };
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
               () => {
                 this.$store.dispatch('auth/getUserData');
                 this.$router.push('/')
              },
              () => {
                this.loading = false;
                this.loginFailed = 'Verkeerde inloggegevens'
              }
          );
        }
      });
    }
  }
};
</script>

<style>
#login {
  padding: 0px;
  max-width: 350px;
  background: rgba(255, 255, 255, 0.9);

}

#btn-rounded {
  width: 100%;
  border-radius: 6px;
}

#loginText {
  color: white;
}

</style>
