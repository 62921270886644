<template>
  <div
      class="outer-layer"
      :cursor-active="placingMarker"
      @mouseup="handleMapDragEnd">
      <v-form ref="districtMakerForm" lazy-validation>
        <v-row
              v-if="generationStep === 0"
              justify="space-around"
              class="input-row">
            <v-col
                cols="1"
                sm="2"
                class="p-1"
            >
              <v-text-field
                  v-model="teWerkenUren"
                  :rules="form.rules.teWerkenUren"
                  label="Te werken uren"
              ></v-text-field>
            </v-col>
            <v-col
                cols="1"
                sm="2"
                class="p-1"
            >
              <v-text-field
                  v-model="minutenPerAdres"
                  :rules="form.rules.minutenPerAdres"
                  label="Minuten per adres"
              ></v-text-field>
            </v-col>
            <v-col
                cols="1"
                sm="2"
                class="p-1"
            >
              <v-text-field
                  v-model="laatstGelopen"
                  :rules="form.rules.laatstGelopen"
                  type="date"
                  label="Laatst gelopen voor"
              ></v-text-field>
            </v-col>
            <v-col
                cols="1"
                sm="2"
                class="p-1"
            >
              <v-btn
                  id="markerButton"
                  v-if="!placingMarker"
                  elevation="2"
                  large
                  color="primary"
                  :disabled="false"
                  @click="placeMarker()"
              >
                Plaats Marker
              </v-btn>
              <v-btn
                  id="cancelButton"
                  v-if="placingMarker"
                  elevation="2"
                  large
                  color="primary"
                  :disabled="false"
                  @click="cancelPlaceMarker()"
              >
                Annuleer
              </v-btn>
            </v-col>
            <v-col
                cols="1"
                sm="2"
                class="p-1"
            >
              <v-btn
                  id="queryButton"
                  elevation="2"
                  large
                  color="primary"
                  :disabled="!form.valid || generatingDistrict"
                  @click="generateDistricts()"
              >
                {{generatingDistrict ? "Even geduld" : "Genereer wijk"}}
              </v-btn>
            </v-col>
          </v-row>
        <v-row
            v-if="generationStep === 1"
            justify="space-around"
            class="input-row">
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-btn
                id="backButton"
                elevation="2"
                large
                color="primary"
                @click="decreaseGenerationStep()"
            >
              Ga terug
            </v-btn>
          </v-col>
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-btn
                v-if="!addingLeads"
                id="addLeadsButton"
                elevation="2"
                large
                color="primary"
                @click="handleAddLeadsButton()"
            >
              Voeg adressen bij
            </v-btn>

            <v-btn
                v-if="addingLeads"
                id="stopAddLeadsButton"
                elevation="2"
                large
                color="primary"
                @click="stopAddingAndRemovingLeads()"
            >
              Bijvoegen stoppen
            </v-btn>
          </v-col>
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-btn
                v-if="!removingLeads"
                id="removeLeadsButton"
                elevation="2"
                large
                color="primary"
                @click="handleRemoveLeadsButton()"
            >
              Sluit adressen uit
            </v-btn>

            <v-btn
                v-if="removingLeads"
                id="stopRemoveLeadsButton"
                elevation="2"
                large
                color="primary"
                @click="stopAddingAndRemovingLeads()"
            >
              Uitsluiten stoppen
            </v-btn>
          </v-col>
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-text-field
                class="cluster-size-text-field"
                v-model="clusterSize"
                :disabled="true"
                label="Aantal adressen in wijk"
            ></v-text-field>
          </v-col>
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-btn
                id="finalizeButton"
                elevation="2"
                large
                color="primary"
                @click="draftDocument()"
            >
              Bekijk wijkbestand
            </v-btn>
<!--            <v-btn-->
<!--                id="finalizeButton"-->
<!--                elevation="2"-->
<!--                large-->
<!--                color="primary"-->
<!--                @click="generateDocument()"-->
<!--            >-->
<!--              Bekijk wijkbestand-->
<!--            </v-btn>-->
          </v-col>
        </v-row>
        <v-row
            v-if="generationStep === 2"
            justify="space-around"
            class="input-row">
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-btn
                elevation="2"
                large
                color="primary"
                @click="decreaseGenerationStep()"
                :disabled="generatingDocument"
            >
              Ga terug
            </v-btn>
          </v-col>
          <v-col
              cols="2"
              sm="4"
              class="p-1"
          >
            <v-text-field
                ref="documentNameField"
                v-model="documentName"
                :rules="documentNameRules"
                validate-on-mount
                label="Bestandsnaam (verplicht)"
                :disabled="generatingDocument"
            ></v-text-field>
          </v-col>
          <v-col
              cols="1"
              sm="2"
              class="p-1"
          >
            <v-btn
                v-if="!done"
                id="downloadButton"
                elevation="2"
                large
                color="primary"
                @click="generateDocument()"
                :disabled="!canDownloadDocument || generatingDocument"
            >
              {{generatingDocument ? "Even geduld" : "Download wijkbestand"}}
            </v-btn>
            <v-btn
                v-if="done"
                elevation="2"
                large
                color="primary"
                @click="startOver()"
            >
              Maak nog een wijk
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    <div
      ref="mapContainer"
      v-if="generationStep < 2"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @mousedown="handleMouseDown">
      <GmapMap
          ref="mapRef"
          :center="this.center"
          :zoom="zoomLevel"
          :options="mapOptions"
          @click="handleMapClick"
          @rightclick="handleMapRightClick"
          mapTypeId='roadmap'
          style='width:100%;  height: 800px;'
          @
          @dragstart="handleMapDragStart"
          @mousemove="handleMapMouseMove"
          @center_changed="handleCenterChanged"
          @zoom_changed="handleZoomChanged"
      >
        <GmapMarker
            ref="districtMarker"
            v-if="markerPosition && generationStep === 0"
            :position="markerPosition"
            :clickable="false"
            :draggable="true"
            @dragend="updateMarkerPosition"
        />
        <GmapPolygon
          :key="generateKey('p', index)"
          v-for="(a, index) in this.districtPolygons"
          :options="a.polygonOptions"
          :paths="a.polygon"
          :z-index="2"
          @mousemove="handleMapMouseMove"
          @click="handleMapClick"
        />
        <GmapCircle
            :key="generateKey('plc', index)"
            v-for="(a, index) in this.potentialLeads"
            :options="a.circleOptions"
            :center="a.position"
            :radius="1.5"
            :z-index="20"
        />
        <GmapCircle
            :key="generateKey('rlc', index)"
            v-for="(a, index) in this.remainingLeads"
            :options="a.circleOptions"
            :center="a.position"
            :radius="1.5"
            :z-index="20"
        />
        <GmapCircle
            :key="generateKey('sc', index)"
            v-for="(a, index) in this.sales"
            :options="a.circleOptions"
            :center="a.position"
            :radius="1.5"
            :z-index="20"
        />
        <GmapCircle
            ref="addingCircle"
            v-if="this.mouseOverMap && (this.addingLeads || this.removingLeads)"
            :options="this.addLeadsCircleOptions"
            :center="this.lastCirclePosition"
            :radius="this.addRemoveLeadsRadius"
            :z-index="20"
        />
      </GmapMap>
    </div>
    <div
      class="document-draft-container"
      v-if="generationStep === 2">
      <div class="document-draft-margin">
        <div
          ref="documentContent"
          class="document-draft">
          <div class="image-container">
            <div
              ref="imageScaleContainer"
              :style="documentImageScaleStyle">
            <v-img
                class="document-image"
                :width="1280"
                :height="1280"
                :src="documentImageUri"
            ></v-img>
              <svg
                class="svg-overlay"
                width="1280"
                height="1280">
                <rect x="0" y="0" width="1280" height="1280" fill="#000000" fill-opacity="0.3"/>
                <path
                    v-if="svgClusterOutlinePath"
                    :d="svgClusterOutlinePath"
                    fill="none"
                    :stroke-width="(svgCircleRadius * 0.75) + svgCircleOutlineWidth * 2"
                    stroke="white"
                />

                <path
                  v-if="svgClusterOutlinePath"
                  :d="svgClusterOutlinePath"
                  fill="none"
                  :stroke-width="svgCircleRadius * 0.75"
                  stroke="#B41412"
                />

                <circle
                  :key="generateKey('svgp', index)"
                  v-for="(p, index) in this.svgPoints"
                  :cx="p.cx"
                  :cy="p.cy"
                  :r="svgCircleRadius"
                  :stroke-width="svgCircleOutlineWidth"
                  stroke="white"
                  fill="#EA4335"/>

                <circle
                    :key="generateKey('svgsp', index)"
                    v-for="(p, index) in this.salesPoints"
                    :cx="p.cx"
                    :cy="p.cy"
                    :r="svgCircleRadius"
                    :stroke-width="svgCircleOutlineWidth"
                    stroke="white"
                    fill="#00b700"/>
              </svg>
            </div>
          </div>
          <div class="text-container">
            <div
              :key="generateKey('std', index)"
              class="street-info-container"
              v-for="(s, index) in this.clusterAddressInfo">
              <div class="street-name address-line">{{s.st}}</div>
              <div
                class="street-number-column-container"
              >
                <div
                  :key="generateKey('stnc', index)"
                  class="street-number-column"
                  v-for="(nrl, index) in s.nrl">
                  <div
                      class="address-line"
                      :class="{ 'sale': a.sale }"
                      :key="generateKey('stn', index)"
                      v-for="(a, index) in nrl">
                      {{a.nr}}{{a.tv}}
                    <span class="zipcode address-line">{{a.zc}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DistrictsService from "@/services/districts.service.js";
import {ClusterMaker} from "@/computation/clusterMaker.js";
import Utils from "@/computation/utils.js";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const latCorrectionMultiplier = 147 / 90;
const MONTHS_TO_GO_BACK = 3;
const CLUSTER_PRIMARY = "#ff594c";
const CLUSTER_SECONDARY = "#B41412";
const CLUSTER_OUTLINE_OFFSET = 20;
const REMAINDER_PRIMARY = "#0059ff";
const REMAINDER_SECONDARY = "#0242b7";
const DEFAULT_BRUSH_RADIUS = 2000000;
const START_ZOOM_LEVEL = 8;
const BASE_SVG_ZOOM = 1.4190673828125;
const BASE_CIRCLE_RADIUS = 0.00005340576171875;

export default {
  name: "DistrictMap",
  components: {},
  data: () => ({
    center: {lat: 51.97765118, lng: 5.32806885},
    storedCenter: {lat: 51.97765118, lng: 5.32806885},
    placingMarker: false,
    markerPosition: null,
    potentialLeads: null,
    sales: null,
    remainingLeads: null,
    districtPolygons: null,
    svgPoints: null,
    salesPoints: null,
    imageCenterAndZoom: null,
    addressInfoMap: null,
    clusterAddressInfo: null,
    generationStep: 0,
    generatingDistrict: false,
    generatingDocument: false,
    done: false,

    aantalLopers: 0,
    teWerkenUren: 0,
    minutenPerAdres: 0,
    laatstGelopen: 0,
    addressCount: 0,
    zoomLevel: START_ZOOM_LEVEL,
    storedZoomLevel: START_ZOOM_LEVEL,

    showCursor: 'none',
    mouseDown: false,
    mouseOverMap: false,

    svgCircleRadius: 0,
    svgCircleOutlineWidth: 0,
    svgClusterOutlinePath: "",

    clusterSize: "",
    addingLeads: false,
    removingLeads: false,
    toTransferLeads: [],
    lastCirclePosition: {lat: 0, lng: 0},
    addRemoveLeadsPosition: false,
    addRemoveLeadsRadius: DEFAULT_BRUSH_RADIUS / Math.pow(2, START_ZOOM_LEVEL),
    addLeadsCircleOptions: {
      strokeOpacity: 1,
      fillOpacity: 0,
      clickable: false,
      strokeColor: CLUSTER_SECONDARY,
      fillColor: CLUSTER_PRIMARY
    },
    removeLeadsCircleOptions: {
      strokeOpacity: 1,
      fillOpacity: 0,
      clickable: false,
      strokeColor: REMAINDER_SECONDARY,
    },

    documentName: "",
    documentNameRules: [
      v => !!v || "Minstens 3 karakters, alleen letters, cijfers, spaties, - en _",
      v => {
        const regex = /^[a-zA-Z0-9-_ ]{3,}$/;
        return regex.test(v) ? true : "Minstens 3 karakters, alleen letters, cijfers, spaties, - en _";
      }
    ],
    documentImageUri: "",
    canDownloadDocument: false,
    documentImageScaleStyle: {
      transform: "scale(1)"
    },

    clusterColors: [
      CLUSTER_PRIMARY,
    ],
    polygonOptions: {
      strokeColor: CLUSTER_PRIMARY,
    },
    mapOptions: {
      tilt: 0,
      gestureHandling: 'auto',
      minZoom: 1,
      styles: [
        {
          featureType: "administrative",
          elementType: "labels",
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels',
          stylers: [{visibility: 'off'}]
        }
      ],
    },
    form: {
      valid: false,
      rules: {
        aantalLopers: [
          v => !!v || "Aantal lopers is verplicht",
          v => {
            const num = parseInt(v);
            return !isNaN(num) && num > 0;
          }
        ],
        teWerkenUren: [
          v => !!v || "Te werken uren is verplicht",
          v => {
            const num = parseFloat(v);
            return !isNaN(num) && num > 0;
          }
        ],
        minutenPerAdres: [
          v => !!v || "Minuten per adres verplicht",
          v => {
            const num = parseFloat(v);
            return !isNaN(num) && num > 0;
          }
        ],
        laatstGelopen: [
          v => !!v || "Laatst gelopen voor is verplicht",
          v => {
            const regex = /^\d{4}-\d{2}-\d{2}$/;
            return regex.test(v);
          }
        ]
      },
      fields: {
        aantalLopers: 0,
        teWerkenUren: 0,
        minutenPerAdres: 0,
      }
    },
  }),
  created() {
    this.aantalLopers = 1;
    this.teWerkenUren = 4;
    this.minutenPerAdres = 2;
    this.laatstGelopen = this.getPastDate(MONTHS_TO_GO_BACK);
  },
  watch: {
    aantalLopers(newVal) {
      this.form.fields.aantalLopers = newVal;
      this.evaluateFormValidity();
      this.calculateAddressCount();
    },
    teWerkenUren(newVal) {
      this.form.fields.teWerkenUren = newVal;
      this.evaluateFormValidity();
      this.calculateAddressCount();
    },
    minutenPerAdres(newVal) {
      this.form.fields.minutenPerAdres = newVal;
      this.evaluateFormValidity();
      this.calculateAddressCount();
    },
    laatstGelopen(newVal) {
      this.form.fields.laatstGelopen = newVal;
      this.evaluateFormValidity();
    },
    documentName() {
      this.evaluateDocumentName();
    }
  },
  methods: {
    generateKey(prefix, key) {
      return prefix + key;
    },
    calculateAddressCount() {
      this.addressCount = parseInt(this.aantalLopers)
        * parseFloat(this.teWerkenUren)
        * (60 / parseFloat(this.minutenPerAdres))
    },
    placeMarker() {
      this.placingMarker = true;
      this.markerPosition = null
      this.evaluateFormValidity();
    },
    cancelPlaceMarker() {
      this.placingMarker = false;
    },
    updateMarkerPosition() {
      this.markerPosition = {
        lng: this.$refs.districtMarker.$markerObject.position.lng(),
        lat: this.$refs.districtMarker.$markerObject.position.lat()
      }
    },
    handleCenterChanged(event) {
      this.storedCenter = {
        lng: event.lng(),
        lat: event.lat(),
      };
    },
    handleZoomChanged(event) {
      const zoomScaleRatio = Math.pow(2, event)
      this.storedZoomLevel = event;
      this.addRemoveLeadsRadius = DEFAULT_BRUSH_RADIUS / zoomScaleRatio;
    },
    handleMouseEnter() {
      this.mouseOverMap = true;
    },
    handleMouseLeave() {
      this.mouseOverMap = false;
    },
    handleMouseDown(event) {
      if(event.button === 0) {
        this.mouseDown = true;

        if (this.generationStep === 1) {
          if (this.addRemoveLeadsPosition) {
            if (this.addingLeads) {
              this.testToAddLeads();
              this.addLeadsCircleOptions.fillOpacity = 0.2;
            }
            if (this.removingLeads) {
              this.testToRemoveLeads();
              this.addLeadsCircleOptions.fillOpacity = 0.2;
            }
          }
        }
      }
    },
    handleMapClick(event) {
      if(this.placingMarker) {
        if(!this.markerPosition) {
          this.markerPosition = {
            lng: event.latLng.lng(),
            lat: event.latLng.lat(),
          }
          this.placingMarker = false;
          this.evaluateFormValidity();
        }
      }
    },
    handleMapRightClick() {
      if(this.generationStep === 1) {
        if(this.addingLeads || this.removingLeads) {
          this.stopAddingAndRemovingLeads();
        }
      }
    },
    handleMapDragStart() {
      if(this.generationStep === 1) {
        if(this.addingLeads || this.removingLeads) {
          this.mapOptions.gestureHandling = 'none';
        }
      }
    },
    handleMapDragEnd() {
      this.mouseDown = false;
      this.mapOptions.gestureHandling = 'auto';
      this.addLeadsCircleOptions.fillOpacity = 0;

      if(this.generationStep === 1) {
        this.lastCirclePosition = {...this.addRemoveLeadsPosition};
        if(this.addingLeads || this.removingLeads) {
          if(this.toTransferLeads.length) {
            if(this.addingLeads) {
              this.potentialLeads = [...this.potentialLeads, ...this.toTransferLeads];
              this.remainingLeads = this.remainingLeads.filter(rl => !rl.remove);
            } else if(this.removingLeads) {
              this.remainingLeads = [...this.remainingLeads, ...this.toTransferLeads];
              this.potentialLeads = this.potentialLeads.filter(rl => !rl.remove);
            }
          }
          this.toTransferLeads = [];

          if(this.districtPolygons && this.districtPolygons.length) {
            this.districtPolygons[0].cluster = this.potentialLeads;

            let polygon = [];
            for(let j = 0; j < 5; j++) {
              const offset = Utils.offsetPolygon(Utils.openGift(this.potentialLeads),-(CLUSTER_OUTLINE_OFFSET + j * 5) * 0.0000147);
              if(offset.length) {
                polygon = offset[0];
                j = 5;
              }
            }

            this.districtPolygons[0].polygon = polygon.map(p => ({lng: p.x, lat: p.y / latCorrectionMultiplier}))
          }
        }
      }
    },
    handleMapMouseMove(event) {
      this.addRemoveLeadsPosition = {
        lng: event.latLng.lng(),
        lat: event.latLng.lat(),
      }
      if(this.generationStep === 1) {
        if(this.addingLeads || this.removingLeads) {
          this.$refs.addingCircle.$circleObject.setCenter(event.latLng);
        }

        if(this.mouseDown) {
          if (this.addingLeads) {
            this.testToAddLeads();
          }
          if(this.removingLeads) {
            this.testToRemoveLeads();
          }
        }
      }
    },
    testToAddLeads() {
      const normalizedSelectorPosition = {
        x: this.addRemoveLeadsPosition.lng,
        y: this.addRemoveLeadsPosition.lat * latCorrectionMultiplier,
      }

      let updateClusterSize = false

      this.remainingLeads.forEach(rl => {
        if(!rl.remove) {
          if (Utils.calculateDistance(rl, normalizedSelectorPosition) < this.addRemoveLeadsRadius * 0.0000147) {
            rl.circleOptions.strokeColor = CLUSTER_PRIMARY;
            rl.circleOptions.fillColor = CLUSTER_PRIMARY;
            this.toTransferLeads.push({...rl});
            rl.remove = true;

            updateClusterSize = true;
          }
        }
      })

      if(updateClusterSize) {
        this.calculateClusterSize();
      }
    },
    testToRemoveLeads() {
      const normalizedSelectorPosition = {
        x: this.addRemoveLeadsPosition.lng,
        y: this.addRemoveLeadsPosition.lat * latCorrectionMultiplier,
      }

      let updateClusterSize = false;
      let removeCounter = 0;

      this.potentialLeads.forEach(rl => {
        if(!rl.remove && this.potentialLeads.length - removeCounter > 3) {
          if (Utils.calculateDistance(rl, normalizedSelectorPosition) < this.addRemoveLeadsRadius * 0.0000147) {
            rl.circleOptions.strokeColor = REMAINDER_PRIMARY;
            rl.circleOptions.fillColor = REMAINDER_PRIMARY;
            this.toTransferLeads.push({...rl})
            rl.remove = true;

            updateClusterSize = true;
            removeCounter++;
          }
        }
      })

      if(updateClusterSize) {
        this.calculateClusterSize();
      }
    },
    generateDistricts() {
      if (this.form.valid) {
        this.potentialLeads = [];
        this.districtPolygons = [];
        this.generatingDistrict = true;

        DistrictsService.generateDistricts(
          this.addressCount,
          this.markerPosition.lng,
          this.markerPosition.lat,
          this.laatstGelopen
        ).then(r => {
          this.generatingDistrict = false;
          if(r && r.data && r.data.length && r.data.length >= this.addressCount) {
            this.processResult(r)
          } else {
            this.$fire({
              position: 'top-end',
              type: 'warning',
              text: 'Onvoldoende geschikte adressen gevonden. Probeer een andere locatie',
              showConfirmButton: false,
              timer: 2000
            })
          }
        }).catch(error => {
          this.generatingDistrict = false;
          this.$fire({
            position: 'top-end',
            type: 'warning',
            text: 'Er is iets fout gegaan. Probeer een andere locatie',
            showConfirmButton: false,
            timer: 2000
          })
          console.log(error);
        })
      } else {
        this.$fire({
          position: 'top-end',
          type: 'warning',
          text: 'Niet alle verplichte velden zijn (correct) ingevuld',
          showConfirmButton: false,
          timer: 2000
        })
      }
    },
    evaluateFormValidity() {
      this.form.valid = this.$refs.districtMakerForm.validate() && this.markerPosition
    },
    processResult(r) {
      const normalizedMarkerPosition = {
        x: this.markerPosition.lng,
        y: this.markerPosition.lat * latCorrectionMultiplier
      }

      let distanceSortedResults = r.data.filter(d => {
          return(r.sales.findIndex(s => {
            const sToevoeging = s.toevoeging === null ? "" : s.toevoeging;
            return d.postcode.toLowerCase() === s.postcode.toLowerCase()
              && d.huisnummer.toLowerCase() === s.huisnummer.toLowerCase()
              && d.toevoeging.toLowerCase() === sToevoeging.toLowerCase()
          }) === -1)
        })
        .map(d => {
          const normalizedPosition = {
            x: d.longitude,
            y: d.latitude * latCorrectionMultiplier,
          }

          return {
            ...d,
            lng: d.longitude,
            lat: d.latitude,
            x: normalizedPosition.x,
            y: normalizedPosition.y,
            cd: Utils.calculateDistance(normalizedPosition, normalizedMarkerPosition)
          }
        })
        .sort((a, b) => a.cd - b.cd)

      let remainingLeadsMap = new Map(distanceSortedResults.map((r) => [r.id, r]));

      const triangles = Utils.triangulate(distanceSortedResults)
      const graph = Utils.convertTrianglesToGraph(triangles);
      const clump = Utils.createClump(distanceSortedResults[0].id, this.addressCount, graph);

      const cleanedClump = clump.map(p => ({
        id: p.id,
        lat: p.lat,
        lng: p.lng,
        position: {
          lng: p.longitude,
          lat: p.latitude
        },
        x: p.x,
        y: p.y,
        circleOptions: {
          strokeOpacity: 1,
          fillOpacity: 1,
          clickable: false
        },
        straat: p.straat,
        postcode: p.postcode,
        huisnummer: p.huisnummer,
        toevoeging: p.toevoeging
      }))

      const clumpTriangles = Utils.triangulate(cleanedClump)
      const clumpNodeMap = Utils.convertTrianglesToGraph(clumpTriangles);
      const clumpClusterMaker = new ClusterMaker(parseInt(this.form.fields.aantalLopers))
      const clumpClusters = clumpClusterMaker.fit(Array.from(clumpNodeMap.values())).clusters;
      const balancedClusters = Utils.balanceClusters(clumpClusters, clumpNodeMap);

      let potentialLeads = [];
      let districtPolygons = [];
      balancedClusters.forEach((c, i) => {
        c.forEach(p => {
          remainingLeadsMap.delete(p.id);
          p.circleOptions.strokeColor = this.clusterColors[i % this.clusterColors.length];
          p.circleOptions.fillColor = this.clusterColors[i % this.clusterColors.length];
          potentialLeads.push(p);
        })

        let polygon = [];
        for(let j = 0; j < 5; j++) {
          const offset = Utils.offsetPolygon(Utils.openGift(c),-(CLUSTER_OUTLINE_OFFSET + j * 5) * 0.0000147);
          if(offset.length) {
            polygon = offset[0];
            j = 5;
          }
        }

        districtPolygons.push({
          cluster: c[0] ? c[0].cluster : null,
          polygon: polygon.map(p => ({lng: p.x, lat: p.y / latCorrectionMultiplier})),
          polygonOptions: {
            strokeOpacity: 1,
            strokeWidth: 2,
            fillOpacity: 0,
            fillColor: "#000000",
            strokeColor: CLUSTER_SECONDARY,
            gestureHandling: 'none',
          }
        })
      })

      const remainingLeads = Array.from(remainingLeadsMap.values()).map(p => ({
        id: p.id,
        lat: p.lat,
        lng: p.lng,
        position: {
          lng: p.longitude,
          lat: p.latitude
        },
        x: p.x,
        y: p.y,
        circleOptions: {
          strokeOpacity: 1,
          fillOpacity: 1,
          clickable: false,
          strokeColor: REMAINDER_PRIMARY,
          fillColor: REMAINDER_PRIMARY
        },
        straat: p.straat,
        postcode: p.postcode,
        huisnummer: p.huisnummer,
        toevoeging: p.toevoeging
      }))

      const sales = r.sales.map(s => {
        return {
          id: s.id,
          lng: s.longitude,
          lat: s.latitude,
          position: {
            lng: s.longitude,
            lat: s.latitude
          },
          circleOptions: {
            strokeOpacity: 1,
            fillOpacity: 1,
            clickable: false,
            strokeColor: "#00b700",
            fillColor: "#00b700"
          },
          straat: s.straat,
          postcode: s.postcode,
          huisnummer: s.huisnummer,
          toevoeging: s.toevoeging === null ? "" : s.toevoeging
        }
      })

      this.districtPolygons = districtPolygons;
      this.potentialLeads = potentialLeads;
      this.remainingLeads = remainingLeads;
      this.sales = sales;

      this.calculateClusterSize();
      this.generationStep = 1;
    },
    handleDistrictHover(clusterIndex) {
      this.districtPolygons.forEach(d => {
        if(d.cluster !== clusterIndex) {
          d.polygonOptions.strokeOpacity = 0.1;
        }
      })

      this.potentialLeads.forEach(p => {
        if(p.cluster !== clusterIndex) {
          p.circleOptions.strokeOpacity = 0.1;
          p.circleOptions.fillOpacity = 0.1;
        }
      })
    },
    handleDistrictOut() {
      this.districtPolygons.forEach(d => {
        d.polygonOptions.strokeOpacity = 1;
      })

      this.potentialLeads.forEach(p => {
          p.circleOptions.strokeOpacity = 1;
          p.circleOptions.fillOpacity = 1;
      })
    },
    decreaseGenerationStep() {
      this.generationStep--;
      this.stopAddingAndRemovingLeads();

      if(this.done) {
        this.startOver();
      }

      if(this.generationStep === 1) {
        this.center = {...this.storedCenter};
        this.zoomLevel = this.storedZoomLevel;
      }
    },
    getPastDate(monthsToGoBack) {
      if(isNaN(monthsToGoBack)) {
        monthsToGoBack = 0;
      }
      const today = new Date();

      const xMonthsAgo = new Date(today);
      xMonthsAgo.setMonth(today.getMonth() - monthsToGoBack);

      return this.formatDate(xMonthsAgo);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    handleAddLeadsButton() {
      this.addingLeads = true;
      this.removingLeads = false;

      this.addLeadsCircleOptions.strokeColor = CLUSTER_SECONDARY;
      this.addLeadsCircleOptions.fillColor = CLUSTER_PRIMARY;
    },
    handleRemoveLeadsButton() {
      this.removingLeads = true;
      this.addingLeads = false;

      this.addLeadsCircleOptions.strokeColor = REMAINDER_SECONDARY;
      this.addLeadsCircleOptions.fillColor = "#0059ff";
    },
    stopAddingAndRemovingLeads() {
      this.addingLeads = false;
      this.removingLeads = false;
      this.mapOptions.gestureHandling = 'auto';
    },
    calculateClusterSize() {
      const virtualSize = this.potentialLeads.length + (this.toTransferLeads.length * (this.removingLeads ? -1 : 1));
      const clusterDifference = virtualSize - this.addressCount
      let suffix = clusterDifference !== 0 ? "(" : "";
      suffix += clusterDifference > 0 ? "+" : "";
      suffix += clusterDifference !== 0 ? clusterDifference : "";
      suffix += clusterDifference !== 0 ? ")" : "";
      this.clusterSize = `${virtualSize} ${suffix}` ;
    },
    draftDocument() {
      this.generationStep = 2;
      this.documentName = "";
      this.calculateImageCenterAndZoom();

      this.generateAddressInfo();
      this.documentImageUri = this.generateStaticMapUrl();
      this.generateSvgPoints();
    },
    generateStaticMapUrl() {
      let url = `https://maps.googleapis.com/maps/api/staticmap?size=640x640&scale=2&maptype=satellite&center=${this.imageCenterAndZoom.lat},${this.imageCenterAndZoom.lng}&zoom=${Math.floor(this.imageCenterAndZoom.zoom)}&format=png&style=feature:poi|element:labels|visibility:off&style=feature:transit|element:labels|visibility:off&key=AIzaSyAx4xB9rBdGx_u-uhtQktdRvBZovpZOq6w`;

      return url;
    },
    generateSvgPoints() {
      const multiplier = BASE_SVG_ZOOM * Math.pow(2, Math.floor(this.imageCenterAndZoom.zoom))

      this.svgCircleRadius = BASE_CIRCLE_RADIUS * Math.pow(2, Math.floor(this.imageCenterAndZoom.zoom));
      this.svgCircleOutlineWidth = this.svgCircleRadius / 3.5;

      this.svgPoints = this.potentialLeads.map(p => {
        return {
          cx: 640 + (p.lng - this.imageCenterAndZoom.lng) * multiplier,
          cy: 640 + (p.lat * latCorrectionMultiplier - this.imageCenterAndZoom.lat * latCorrectionMultiplier) * -multiplier
        }
      });

      this.salesPoints = this.sales.filter(s => this.addressInfoMap.has(s.straat))
          .map(p => {
        return {
          cx: 640 + (p.lng - this.imageCenterAndZoom.lng) * multiplier,
          cy: 640 + (p.lat * latCorrectionMultiplier - this.imageCenterAndZoom.lat * latCorrectionMultiplier) * -multiplier
        }
      });

      let convertedPath = this.districtPolygons[0].polygon.map(p => `${640 + (p.lng - this.imageCenterAndZoom.lng) * multiplier} ${640 + (p.lat * latCorrectionMultiplier - this.imageCenterAndZoom.lat * latCorrectionMultiplier) * -multiplier}`);

      this.svgClusterOutlinePath = `M${convertedPath.join("L")}z`
    },
    generateAddressInfo() {
      this.addressInfoMap = new Map();

      this.potentialLeads.forEach(l => {
        const address = {
          nr: l.huisnummer,
          tv: (l.toevoeging ? ` ${l.toevoeging}` : ""),
          zc: l.postcode,
          sale: false
        }
        if(this.addressInfoMap.has(l.straat)) {
          this.addressInfoMap.get(l.straat).push(address)
        } else {
          this.addressInfoMap.set(l.straat, [address])
        }
      });

      this.sales.forEach(l => {
        const address = {
          nr: l.huisnummer,
          tv: (l.toevoeging ? ` ${l.toevoeging}` : ""),
          zc: "(klant)",
          sale: true
        }
        if(this.addressInfoMap.has(l.straat)) {
          this.addressInfoMap.get(l.straat).push(address)
        }
      });

      const addressInfoList = [];

      this.addressInfoMap.forEach((a, s) => {
        const sortedList = a.sort((a, b) => {
          if(a.nr === b.nr) {
            return a.tv.localeCompare(b.tv);
          } else {
            return a.nr - b.nr;
          }
        })

        const addressColumnCount = Math.ceil(sortedList.length / 4);
        const columns = [];
        for (let i = 0; i < sortedList.length; i += addressColumnCount) {
          columns.push(sortedList.slice(i, i + addressColumnCount));
        }

        addressInfoList.push({
          st: s,
          nrl: columns
        })
      })

      this.clusterAddressInfo = addressInfoList.sort((a, b) => a.st.localeCompare(b.st));
    },
    evaluateDocumentName() {
      const regex = /^[a-zA-Z0-9-_ ]{3,}$/;
      this.canDownloadDocument = regex.test(this.documentName);
    },
    generateDocument() {
      this.generatingDocument = true;

      const toExcludeAddresses = this.potentialLeads.map(l => {
        return {
          id: l.id,
          longitude: l.lng,
          latitude: l.lat
        }
      })

      DistrictsService.excludeAddresses(toExcludeAddresses, this.laatstGelopen)
        .then(r => {
          if(r.exclusionKey && r.exclusionKey.length) {
            this.renderDocument(r.exclusionKey);
          } else {
            this.generatingDocument = false;
            this.$fire({
              position: 'top-end',
              type: 'warning',
              text: '(Een aantal van) de geselecteerde adressen zijn niet meer beschikbaar. Ga terug en plaats de marker ergens anders',
              showConfirmButton: false,
              timer: 2000
            })
          }
        }).catch(error => {
          this.generatingDocument = false;
          this.$fire({
            position: 'top-end',
            type: 'warning',
            text: 'Er is iets fout gegaan.',
            showConfirmButton: false,
            timer: 2000
          })
          console.log(error);
        })
    },
    renderDocument(exclusionKey) {
      const contentToRender = this.$refs.documentContent;
      html2canvas(contentToRender, {allowTaint: true, useCORS: true}).then(canvas => {

        const margin = 25;
        const pageWidth = 210;
        const pageHeight = 297;
        const imgWidth = pageWidth - margin * 2;
        const imgHeight = canvas.height * imgWidth / canvas.width;

        const marginCanvas = document.createElement("canvas");
        marginCanvas.width = pageWidth;
        marginCanvas.height = margin;
        const marginCtx = marginCanvas.getContext('2d');
        marginCtx.fillStyle = 'white';
        marginCtx.fillRect(0, 0, marginCanvas.width, marginCanvas.height);

        let heightLeft = imgHeight;
        let position = 0;

        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(canvas, 'PNG', margin, position + margin, imgWidth, imgHeight, "", "FAST");
        pdf.addImage(marginCanvas, 'PNG', 0, pageHeight - margin, pageWidth, margin, "", "FAST");
        heightLeft -= pageHeight - margin * 2;

        pdf.text(exclusionKey, margin /2, margin / 2)

        while(heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage()
          pdf.addImage(canvas, 'PNG', margin, position + margin, imgWidth, imgHeight, "", "FAST");
          pdf.addImage(marginCanvas.toDataURL('image/png'), 'PNG', 0, 0, pageWidth, margin, "", "FAST");
          pdf.addImage(marginCanvas.toDataURL('image/png'), 'PNG', 0, pageHeight - margin, pageWidth, margin, "", "FAST");
          heightLeft -= pageHeight - margin * 2;
        }

        pdf.save(this.documentName + '.pdf');
        this.generatingDocument = false;
        this.done = true;
      })
    },
    calculateImageCenterAndZoom() {
      let latMin = Number.MAX_SAFE_INTEGER;
      let latMax = Number.MIN_SAFE_INTEGER;
      let lngMin = Number.MAX_SAFE_INTEGER;
      let lngMax = Number.MIN_SAFE_INTEGER;

      this.potentialLeads.forEach(p => {
        latMin = Math.min(latMin, p.lat);
        latMax = Math.max(latMax, p.lat);
        lngMin = Math.min(lngMin, p.lng);
        lngMax = Math.max(lngMax, p.lng);
      })

      const GLOBE_WIDTH = 256;
      const lngDif = lngMax - lngMin;
      const latDif = (latMax - latMin) * latCorrectionMultiplier;

      let angle = Math.max(lngDif, latDif)
      if (angle < 0) {
        angle += 360;
      }

      this.imageCenterAndZoom = {
        lat: (latMin + latMax) / 2,
        lng: (lngMin + lngMax) / 2,
        zoom: Math.log(640 * 360 / angle / GLOBE_WIDTH) / Math.LN2
      }

      const scaleDecimal = (this.imageCenterAndZoom.zoom - Math.floor(this.imageCenterAndZoom.zoom)) / 2
      this.documentImageScaleStyle.transform = `scale(${1 + (scaleDecimal > 0.1 ? scaleDecimal : 0)})`;
    },
    startOver() {
      this.potentialLeads = null;
      this.sales = null;
      this.remainingLeads = null;
      this.districtPolygons = null;
      this.generationStep = 0;
      this.generatingDistrict = false;
      this.generatingDocument = false;
      this.done = false;

      this.center = {...this.storedCenter};
      this.zoomLevel = this.storedZoomLevel;
    }
  },
}
</script>

<style scoped>


.input-row {
  height: 90px;
}

.document-draft-container {
  height: 800px;
  overflow-y: auto;
  background: #727272;
  padding: 20px;
}

.document-draft-margin {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 128px;

  background: white;
  box-shadow: 3px 3px 5px #2b333e ;
}

.document-draft {
  height: fit-content;
  width: 1280px;
  font-size: 30px;
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 1250px;
}

.svg-overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.address-line {
  line-height: 40.3px;
}

.sale {
  color: green;
  font-weight: bold;
}

.street-name {
  font-weight: bold;
}

.street-number-column-container {
  display: flex;
  flex-direction: row;
}

.street-number-column {
  width: 25%;
  padding-left: 2%;
}

.zipcode {
  font-size: 20px;
  line-height: 20px;
}

#addLeadsButton {
  background-color: #EA4335 !important;
}

#stopAddLeadsButton {
  background-color: #B41412 !important;
}

#removeLeadsButton {
  background-color: #0059ff !important;
}

#stopRemoveLeadsButton {
  background-color: #0045c2 !important;
}

</style>