import httpClient from "@/services/httpClient";
import authHeader from "@/services/auth-header";

const endpoint = 'lead/';


class LeadService {
    async getLeads() {
        return await httpClient.get(endpoint + 'all', {headers: authHeader()}).then(res => {
            return res.data
        });
    }
    async getDuurzaamCountOverview() {
        return await httpClient.get(endpoint + 'getDuurzaamCountOverview', {headers: authHeader()}).then(res => {
            return res.data
        });
    }

    async createLead(lead) {
        return await httpClient
            .post(endpoint + 'createLead',
                {lead},
                {headers: authHeader()}
            ).then(response => {
                return response;
            })
    }

    async getLeadOverview() {
        return await httpClient
            .get(endpoint + 'leadOverview',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getUserOverview(date) {
        return await httpClient
            .get(endpoint + 'userOverview?date=' + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getUserOverviewKpi(date) {
        return await httpClient
            .get(endpoint + 'userOverviewKpi?date=' + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getTeamOverview(date) {
        return await httpClient
            .get(endpoint + 'teamOverview?date=' + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getTeamOverviewKpi(date) {
        return await httpClient
            .get(endpoint + 'teamOverviewKpi?date=' + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getUserPeriodCount(team, period) {
        return await httpClient
            .get(endpoint + 'userPeriodCount?team=' + team + "&period=" + period,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getUserStats(date, user) {
        return await httpClient
            .get(endpoint + 'userStats?user=' + user + "&date=" + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getTeamStats(date, team) {
        return await httpClient
            .get(endpoint + 'teamStats?team=' + team + "&date=" + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getTeamOverviewLopers(team, date) {
        return await httpClient
            .get(endpoint + 'teamOverviewLopers?team=' + team + "&date=" + date,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getUsersFilteredOnX() {
        return await httpClient
            .get(endpoint + 'listOfUsersPerTeam',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getCreditPerUser(user, date, period) {
        return await httpClient
            .get(endpoint + 'getCreditsByUser?user=' + user + "&date=" + date + "&period=" + period,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async registerUserWorkedWithoutLeads(firstName, lastName) {
        return await httpClient
            .get(endpoint + 'workedWithoutLeads?first_name=' + firstName + '&last_name=' + lastName,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

}

export default new LeadService();