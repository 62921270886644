<template>
  <v-container>
    <v-dialog v-model="dialog" :width="width">
      <v-data-table
          :headers="headers"
          :items="getTeamOverviewLopers"
          class="elevation-6"
          item-key="name">
        <template v-slot:item.credit="{item}">

          <v-chip @click="getCreditLeads(item.name, item.creation_at__date)">

            {{ item.credit }}

          </v-chip>


        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialogCredit" :width="width">
      <v-data-table
          :headers="headersCredit"
          :items="getCreditsUser"
          class="elevation-6"
          item-key="id">

      </v-data-table>
    </v-dialog>

    <v-sheet class="d-inline-block ml-8"
             v-for="(item,i) in this.monthWeeklyDaily"
             :key="i"
    >
      <v-card-title>Week {{ i }}</v-card-title>
      <v-card-text>
        <v-timeline
            dense
        >
          <v-timeline-item
              v-for="a in item"
              :key="a.creation_at__date"
              small
          >
            <div>
              <div class="font-weight-normal">
                <b><u>{{ a.creation_at__date }}</u></b>
              </div>
              <div>Lead(s): {{ a.leads }}</div>
              <div>Credit(s): {{ a.credit }}</div>
              <div>Netto lead(s): <strong>{{ a.netto }}</strong></div>
              <div>{{ a.lopers }}
                <v-icon @click="getTeamOverviewDetailLopers(a.user_id__team__team, a.creation_at__date)">
                  mdi-account-group
                </v-icon>
              </div>

            </div>
          </v-timeline-item>
        </v-timeline>
        <v-banner>
           <span>
              Totaal: <strong>{{ getTotalBruto(item) }} bruto lead(s)</strong>
            </span>
          <br>
          <span>
              Totaal: <strong>{{ getTotalNetto(item) }} netto lead(s)</strong>
            </span>
          <br>
          <span>
            Totaal: <strong>{{ getTotalCredit(item) }} credit lead(s)</strong>
            </span>
        </v-banner>
      </v-card-text>
    </v-sheet>

  </v-container>
</template>

<script>
export default {
  name: "TeamLeadStats",
  props: ['monthWeeklyDaily'],
  data: () => ({
    dialog: false,
    dialogCredit: false,
    headers: [
      {text: 'Naam', value: "name"},
      {text: 'Leads', value: 'leads'},
      {text: 'Credit(s)', value: 'credit'}
    ],
    headersCredit: [
      {text: 'Reden', value: "reden"},
    ]
  }),
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 350
        case 'sm':
          return 450
        case 'md':
          return 550
        case 'lg':
          return 700
        case 'xl':
          return 800
      }
      return 0;
    },
    getCreditsUser(){
      let creditList = this.$store.getters["leads/getCreditPerUser"];
      return creditList != null ? creditList : [];
    },

    getTeamOverviewLopers() {
      return this.$store.getters["leads/getTeamOverviewLopers"] != null
          ? this.$store.getters["leads/getTeamOverviewLopers"].team_overview_lopers
          : null
    }
  },
  methods: {
    async getCreditLeads(user, date) {
      let period = 'today';
      await this.$store.dispatch("leads/getCreditPerUser",{user, date, period})
      this.dialogCredit = true;
    },
    getTotalBruto(week) {
      let total = [];
      Object.entries(week).forEach(([key, val]) => {
        key
        total.push(val.leads) // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num
      }, 0);
    },
    getTotalNetto(week) {
      let total = [];
      Object.entries(week).forEach(([key, val]) => {
        key
        total.push(val.netto) // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num
      }, 0);
    },
    getTotalCredit(week) {
      let total = [];
      Object.entries(week).forEach(([key, val]) => {
        key
        total.push(val.credit) // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num
      }, 0);
    },
    async getTeamOverviewDetailLopers(team, date) {
      await this.$store.dispatch("leads/getTeamOverviewLopers", {team, date});
      this.dialog = true;
    }
  }
}
</script>

<style scoped>
.a {
  width: auto;
}
</style>