<template>
  <div>
    <v-container class="mt-0 pt-0">
      <!--    <v-banner align="center">{{ this.currDate }}</v-banner>-->
      <v-layout align-center justify-space-around row class="pb-5">
        <v-card-title class="text-muted">Overzicht {{ this.getMonthName(this.currDate) }}</v-card-title>
        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getKpi.afspraken }}
                  </div>

                  <h4 v-if="getKpi.afspraken < 1" class="d-flex justify-start">Afspraak</h4>
                  <h4 v-else class="d-flex justify-start">Afspraken</h4>

                </v-col>
                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-book-account-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getKpi.sale }}
                  </div>
                  <h4 v-if="getKpi.sale < 1" class="d-flex justify-start">Live Sale</h4>
                  <h4 v-else class="d-flex justify-start">Live Sales</h4>
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-briefcase-check-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getOverallPipeLineData[1] }}
                  </div>
                  <h4 class="d-flex justify-start">In Verwerking</h4>
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-progress-check</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

      </v-layout>
      <v-divider/>

    </v-container>

    <v-layout align-center justify-space-around row class="pt-2 pb-2 mb-2 pl-13 pr-13 mb-8">

      <v-flex md7 justify="end">
        <v-flex md8>
          <v-card-title class="text-muted">Algemeen Overzicht</v-card-title>
        </v-flex>
        <v-select filled dense rounded
                  v-model="selectedYear"
                  :items="getYears()"
                  label="Jaar"
        ></v-select>
      </v-flex>

      <v-flex md6 class="p-3">
        <v-card elevation="10" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-2 d-flex justify-start">
                  {{ Number(getKpi.sale_per).toFixed(2) }}
                </div>
                <h4 class="d-flex justify-start">Algemeen Sale</h4>
              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-percent</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex md6 class="p-3">
        <v-card elevation="10" class="rounded-card">
          <v-container fluid>
            <v-row>
              <v-col>
                <div class="primary--text display-2 d-flex justify-start">
                  {{ Number(getYearlySalePer).toFixed(2) }}
                </div>
                <h4 class="d-flex justify-start">Jaarlijks Sale</h4>
              </v-col>

              <v-col align-self="center" class="icons">
                <v-icon size="48">mdi-percent</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="getBarChartData" class="rounded-graph">
            <BarChartAccountManagerSale :data="getBarChartData" :label="getBarChartLabel"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="getMonthName" class="rounded-graph">
            <LineChartAccountManagerSaleNoLegend :title="'Sale %'" :data="getDataLineChart" :label="getLabelLineChart"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="getKpi" class="rounded-graph" @click="getOveralPipelineStatsByDate()">
            <PieChart :title="'Gehele Pipeline'" :data="getOverallPipeLineData" :labels="getOverallPipeLineLabels"
                      :colors="getOverallPipeLineColors"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="getTimeFrameDate" class="rounded-graph">
            <PieChart :title="'Sales Frame'" :data="getTimeFrameDate" :labels="getTimeFrameLabel"
                      :colors="getTimeFrameColor"/>
          </v-container>
        </v-col>
      </v-flex>
    </v-layout>

    <v-divider/>

    <v-container class="mt-0 pt-0">
      <v-card>
        <v-card-title class="text-muted">
          Maandelijks
        </v-card-title>

        <v-data-table v-if="this.getPerMonth"
                      :headers="headersMonth"
                      :items="getPerMonth"
                      class="elevation-6"
                      item-key="month"
        >

          <template v-slot:item.date="{item}">
            {{ getMonthName(item.date) }}
          </template>

          <template v-slot:item.sale_per="{item}">

            {{ Number(item.sale_per).toFixed(2) }} %
            <span v-html="liveSaleArrowUpPer(item.live_sale, item.afspraken)"></span>

          </template>

          <template v-slot:item.sale="{item}">

            {{ item.sale }}
            <span v-html="liveSaleArrowUp(item.live_sale)"></span>

          </template>

          <template v-slot:item.pipeline="{item}">

            <v-container @click="getPipelineStatsByDate(item.date)">

              <HorizontalProgressDataTable :item="item"
                                           :height="45" :width="200"/>
            </v-container>

          </template>

          <template slot="body.append">

            <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}" class="font-weight-bold">
              <v-layout justify-center>
                <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}" v-text="'Totaal'"/>
              </v-layout>

            <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
              <td class="font-weight-bold" v-text="'Afspraken: '"/>
              <v-layout justify-center>
                <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                    v-text="getTotaalAfsprakenPerJaar"/>
              </v-layout>
            </tr>

            <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
              <td class="font-weight-bold" v-text="'Pipeline: '"/>
              <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}">
                <v-layout justify-center>
                  <HorizontalProgressDataTable :item="getTotaalPipeline"
                                               :height="45" :width="200"/>
                </v-layout>
              </td>
            </tr>

            <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
              <td class="font-weight-bold" v-text="'Sale %: '"/>
              <v-layout justify-center>
                <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row text-right':isMobile}"
                    v-text="Number(getYearlySalePer).toFixed(2) + ' %'"/>
              </v-layout>
            </tr>

            <tr v-else>

              <td v-text="'Totaal'" class="font-weight-bold"/>
              <td v-text="getTotaalAfsprakenPerJaar"/>
              <td>
                <br/>
                <HorizontalProgressDataTable :item="getTotaalPipeline"
                                             :height="45" :width="200"/>
              </td>
              <td v-text="getTotaalSalesPerJaar"/>
              <td v-text="Number(getYearlySalePer).toFixed(2) + ' %'"/>

            </tr>

          </template>

        </v-data-table>
      </v-card>
    </v-container>


    <v-dialog
        v-model="statussesDialog"
        max-width="290"
    >
      <v-card>
        <v-card-actions v-for="s in getPipelineStatusses" :key="s.id">

          <v-btn
              class="mb-2 mt-2"
              outlined
              @click="selectedStatus = s.code; pipelineDialog=true"
              color="primary "
              width="100%"

          >
            {{ s.status }} ({{ s.num }})
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="overalStatussesDialog"
        max-width="290"
    >
      <v-card>
        <v-card-actions v-for="s in getOveralPipelineStatusses" :key="s.id">

          <v-btn
              class="mb-2 mt-2"
              outlined
              @click="selectedStatus = s.code; overalPipelineDialog=true"
              color="primary "
              width="100%"
          >
            {{ s.status }} ({{ s.num }})
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="pipelineDialog"
    >
      <v-data-table v-if="getPipelineStats"
                    :headers="headersPipeline"
                    :items="getPipelineStats"
                    class="elevation-6"
                    item-key="month"
                    :search="search"
                    :custom-filter="filterText"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Zoeken"
              class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.id="{item}">
          <v-chip outlined @click="goToSolarWebsite(item.id)">
            {{ item.id }}
          </v-chip>
        </template>


      </v-data-table>


    </v-dialog>

    <v-dialog
        v-model="overalPipelineDialog"
    >
      <v-data-table v-if="getOveralPipelineStats"
                    :headers="headersPipeline"
                    :items="getOveralPipelineStats"
                    class="elevation-6"
                    item-key="month"
                    :search="search"
                    :custom-filter="filterText"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Zoeken"
              class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.id="{item}">
          <v-chip outlined @click="goToSolarWebsite(item.id)">
            {{ item.id }}
          </v-chip>
        </template>


      </v-data-table>


    </v-dialog>

  </div>
</template>

<script>
import {Helper as helper} from "@/helper";
import HorizontalProgressDataTable from "@/views/viz/components/HorizontalProgressDateTable";
import PieChart from "@/views/viz/components/PieChart";
import BarChartAccountManagerSale from "@/views/viz/components/accountManager/BarChartAccountManagerSale";
import LineChartAccountManagerSaleNoLegend
  from "@/views/viz/components/accountManager/LineChartAccountManagerSaleNoLegend";

export default {
  name: "AccountManagerKpi",
  components: {
    LineChartAccountManagerSaleNoLegend,
    BarChartAccountManagerSale, PieChart, HorizontalProgressDataTable
  },
  props: ['accountManager'],
  data: () => ({
    currDate: new Date().getTime(),
    selectedYear: new Date().getFullYear(),
    selectedStatus: '',
    search: '',
    headersMonth: [
      {text: 'Maand', value: 'date'},
      // {text: 'Netto', value: 'netto'},
      {text: 'Afspraken', value: 'afspraken'},
      // {text: 'Openstaand', value: 'in_progress'},
      {text: 'Pipline', value: 'pipeline', width: "40%", align: 'center'},
      {text: 'Sale', value: 'sale'},
      {text: 'Sale %', value: 'sale_per'},
    ],
    pipelineDialog: false,
    overalPipelineDialog: false,
    statussesDialog: false,
    overalStatussesDialog: false,
    // headersStatusses: [
    //   {text: 'In verwerking', 'value': 'in_progress'},
    //   {text: 'Verwerkt', 'value': 'progressed'},
    //   {text: 'Sale', 'value': 'sale'},
    //   {text: 'Verzette afspraak', 'value': 'verzetten'}
    // ],
    headersPipeline: [
      // {text: 'Initiale', value: 'initials'},
      // {text: 'Tussenvoegsel', value: 'infix'},
      {text: 'Achternaam', value: 'last_name'},
      {text: 'Straat', value: 'street'},
      {text: 'Postcode', value: 'zipcode'},
      {text: 'Huisnummer', value: 'house_number'},
      {text: 'Stad', value: 'city'},
      {text: 'Status', value: 'lead_status'},
      {text: 'Laatst geüpdated', value: 'request_date_edit__date'},
      {text: 'Adviesgesprek', value: 'datum_adviesgesprek'},
      {text: 'SolarId', value: 'id'},
    ],
  }),
  watch: {
    'accountManager'() {
      this.selectedYear = new Date().getFullYear();
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    getBarChartData() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear).map(q => q.sale) : 0;
    },
    getBarChartLabel() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear)
          .filter(qq => new Date(qq.date).getTime() < this.currDate)
          .map(q => this.getMonthName(q.date)) : 0;
    },
    getTimeFrameDate() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].time_frame;

      let r = []

      if (q) {
        r.push(q.direct)
        r.push(q.one_week)
        r.push(q.one_two)
        r.push(q.two_three)
        r.push(q.three_four)
        r.push(q.four_eight)
        r.push(q.eight)
      }

      return r;
    },
    getTimeFrameLabel() {
      return ["Direct", "Binnen 1 week", "Tussen week 1 en week 2", "Tussen week 2 en week 3",
        "Tussen week 3 en week 4", "Tussen week 5 en week 8", "Ouder dan 8 weken"];
    },
    getTimeFrameColor() {
      return [
        '#f56e22',
        '#57ac91',
        '#c77d94',
        '#9e84c9',
        '#458cff',
        '#458cff',
        '#1d4e9e'
      ];
    },
    getKpi() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].global_stats;
      return q != null ? q : 0;
    },
    getOverallPipeLineData() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].pipeline_overal_stats;
      let r = []

      if (q) {
        r.push(q.sale)
        r.push(q.in_progress)
        r.push(q.verzetten)
        r.push(q.progressed)
        r.push(q.cancel_lead)
      }

      return r;
    },
    getOverallPipeLineLabels() {
      return ['Sale', 'In verwerking', 'Verzetten', 'Verwerkt', 'Cancelled'];
    },
    getOverallPipeLineColors() {
      return [
        '#f56e22',
        '#57ac91',
        '#c77d94',
        '#9e84c9',
        '#458cff',
      ];
    },
    getPerMonth() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear) : 0;
    },

    getLabelLineChart() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear)
          .filter(qq => new Date(qq.date).getTime() < this.currDate)
          .map(q => this.getMonthName(q.date)) : 0;
    },
    getDataLineChart() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month


      let gf = []
      if (q) {
        q.filter(qq => new Date(qq.date).getTime() < this.currDate)
            .filter(qq => qq.jaar === this.selectedYear)
            .map(q => gf.push(Number(q.sale_per).toFixed(2)))

        gf = {
          data: gf,
          fill: false,
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          borderWidth: 1.3
        }
      }

      return Array.of(gf);
    },

    getPipelineStatusses() {
      let q = this.$store.getters['solar/getPipelineStats'];

      return q != null ? q.pipeline_stats != null ? q.pipeline_stats.num_statusses : [] : [];
    },
    getOveralPipelineStatusses() {
      let q = this.$store.getters['solar/getPipelineStats'];

      return q != null ? q.pipeline_overal_stats != null ? q.pipeline_overal_stats.num_statusses : [] : [];
    },
    getPipelineStats() {
      let q = this.$store.getters['solar/getPipelineStats'];
      let b = this.selectedStatus;

      return q != null ? q.pipeline_stats != null && b !== "" ? q.pipeline_stats[b] : [] : [];
    },
    getOveralPipelineStats() {
      let q = this.$store.getters['solar/getPipelineStats'];
      let b = this.selectedStatus;

      return q != null ? q.pipeline_overal_stats != null && b !== "" ? q.pipeline_overal_stats[b] : [] : [];
    },
    getTotaalAfsprakenPerJaar() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.afspraken)
              .reduce((a, b) => a + b, 0)
          : 0;

    },
    getTotaalSalesPerJaar() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.sale)
              .reduce((a, b) => a + b, 0)
          : 0;

    },
    getTotaalPipeline() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      let sale = q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.sale)
              .reduce((a, b) => a + b, 0)
          : 0;

      let in_progress = q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.in_progress)
              .reduce((a, b) => a + b, 0)
          : 0;

      let progressed = q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.progressed)
              .reduce((a, b) => a + b, 0)
          : 0;

      let cancelled = q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.cancel_lead)
              .reduce((a, b) => a + b, 0)
          : 0;

      let verzetten = q != null
          ? q.filter(qq => qq.jaar === this.selectedYear)
              .map(w => w.verzetten)
              .reduce((a, b) => a + b, 0)
          : 0;


      return {
        'cancel_lead': cancelled,
        'sale': sale,
        'in_progress': in_progress,
        'progressed': progressed,
        'verzetten': verzetten
      };
    },
    getYearlySalePer() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      if (q) {
        let sale_per = Number(q.filter(qq => qq.jaar === this.selectedYear)
            .map(w => w.sale)
            .reduce((a, b) => a + b, 0) / q.filter(qq => qq.jaar === this.selectedYear)
            .map(w => w.afspraken)
            .reduce((a, b) => a + b, 0) * 100).toFixed(2);

        return sale_per;
      }

      return null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs'
    }

  },
  methods: {
    async init() {
      let accountManager = this.accountManager;
      if (accountManager) {
        await this.$store.dispatch("solar/fetchAccountManagerByNameKpi", {accountManager});
      } else {
        await this.$store.dispatch("solar/fetchAccountManagerKpi");
      }
    },
    getYears() {
      let q = this.$store.getters['solar/getAccountManagerKpi'].per_month;
      return q != null ? q.map(qq => new Date(qq.date).getFullYear()) : [];
    },
    getMonthName(date) {
      return helper.extractMonth(date);
    },
    liveSaleArrowUp(n) {
      return n > 0 ? '<span style="color:#008000">⬆</span>' + "+" + n : null;
    },
    liveSaleArrowUpPer(n, m) {
      return n > 0 ? '<span style="color:#008000">⬆</span>' + "+" + Number((n * 100) / m).toFixed(2) : null;
    },
    async getPipelineStatsByDate(date) {
      let name = this.accountManager;
      if (name) {
        await this.$store.dispatch('solar/fetchPipelineStatsByName', {name, date});
      } else {
        await this.$store.dispatch('solar/fetchPipelineStats', {date});
      }
      this.statussesDialog = true;
    },
    async getOveralPipelineStatsByDate() {
      let name = this.accountManager;
      if (name) {
        await this.$store.dispatch('solar/fetchPipelineStatsByName', {name});
      } else {
        await this.$store.dispatch('solar/fetchPipelineStats', {});
      }
      this.overalStatussesDialog = true;
    },
    goToSolarWebsite(id) {
      let url = 'https://dakprofijt.2solar.nl/profile/rid/' + id;
      window.open(url, '_blank').focus();
    },
    filterText(value, search) {
      const regex = /\(\w.+\)/i;

      return search != null && typeof value === 'string' &&
          value.toString().replace(regex, "").toLocaleLowerCase().indexOf(search) !== -1
    },
  }
}
</script>

<style scoped>

.rounded-card {
  border-radius: 16px;
}

/*#dialog{*/
/*  background: #f9f6f3d4;*/
/*}*/

.rounded-graph {
  background: #fffffff2;
  border-radius: 16px;
}

.icons {
  padding: inherit;
  margin-bottom: 25px;
}
</style>