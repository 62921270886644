<template>
  <div class="pt-2 pb-2 mb-2 pl-13 pr-13 mb-8 rounded-card">
    <v-flex>
      <v-dialog
          v-model="dialogLoading"
          persistent
          width="350"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-title primary-title class="justify-center">
            {{ this.loadingText }}
            <v-progress-circular
                :size="50"
                dark
                :width="5"
                color="white"
                indeterminate
            ></v-progress-circular>
          </v-card-title>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-layout align-center justify-space-around row id="graphCard">
      <v-row class="text-muted justify-content-around pb-4">
        <v-col class="p-2">
          Algemene gegevens
        </v-col>
      </v-row>
      <v-flex class="mt-0 pt-0 mb-0 pb-0" md3>
        <v-col class="mt-0 pt-0 mb-0 pb-0">
          <v-container>
            <PieChart :title="'Sale Frame'" :data="getTimeFrameDateAlgemeen" :labels="getTimeFrameLabel"
                      :colors="timelineColors"/>
          </v-container>
        </v-col>
        <v-divider/>
      </v-flex>

      <v-flex class="mt-0 pt-0 mb-0 pb-0" md6>
        <v-col class="mt-0 pt-0 mb-0 pb-0">
          <v-container>
            <LineChartAccountManagerSaleNoLegend :title="'Live Sale'" :data="getLiveSaleData"
                                                 :label="getLiveSaleLabel"></LineChartAccountManagerSaleNoLegend>
          </v-container>
        </v-col>
        <v-divider/>
      </v-flex>

      <v-flex class="mt-0 pt-0 mb-0 pb-0" md3>
        <v-col class="mt-0 pt-0 mb-0 pb-0">
          <v-container>
            <PieChart :title="'Pipeline'" :data="Object.values(this.getPipeLine)"
                      :labels="Object.keys(this.getPipeLine)"
                      :colors="timelineColors"/>
          </v-container>
        </v-col>
        <v-divider/>
      </v-flex>
    </v-layout>

    <v-layout justify-start row>
      <v-row class="text-muted justify-content-around pb-2">
        <v-col>
          Adviseur's overzicht
        </v-col>
      </v-row>

      <v-flex class="p-3" md3 v-for="i in this.getUserOverview" :key="i.username">
        <v-card @click="openAccountManagerPage(i)" class="rounded-card" elevation="1" hover ripple outlined>
          <v-col>
            <v-row>
              <v-badge
                  v-if="i.stats.daily_live_sale"
                  color="primary"
                  :content="i.stats.daily_live_sale"
                  overlap
                  class="font-weight-33"
              >
              </v-badge>

              <v-col cols="8" class="p-0">
                <v-card-title class="pb-0 fix">
                  {{ i.user }}
                </v-card-title>
              </v-col>

              <v-col cols="4" class="p-0">
                <v-card-subtitle class="pb-0">
                  {{ Number(i.stats.sale_per).toFixed(2) }}
                  <v-icon size="13">mdi-percent</v-icon>
                </v-card-subtitle>
              </v-col>
            </v-row>

            <v-divider class="mt-6" inset/>
            <v-card-text class="">Live:</v-card-text>

            <v-row no-gutters class="text-center align-center justify-center">

              <v-col class="text-body-1" col="4">
                {{ i.stats.afspraken }}
                <v-icon size="25">mdi-handshake-outline</v-icon>
              </v-col>

              <v-col class="text-body-1" col="4">
                {{ i.stats.sale }}
                <span style="font-size: 125%; color:#008000">⬆</span>
              </v-col>
              <v-col class="text-body-1" col="4">
                {{ i.stats.in_progress }}
                <v-icon size="25">mdi-progress-check</v-icon>
              </v-col>

            </v-row>

            <v-row class="text-center align-center justify-center">

              <v-col sm="5">
                <PieChartOverview :height="150" :title="'Pipeline'"
                                  :data="getOverallPipeLineData(i.pipe_line)"
                                  :labels="pipeLineLabels"
                                  :colors="pipeLineColors"/>
              </v-col>
              <v-col class="fill-height" sm="2" v-if="!isMobile">
                <v-divider
                    class="dividerHeight"
                    vertical
                ></v-divider>
              </v-col>
              <v-col sm="5">
                <PieChartOverview :height="150" :title="'Sale Frame'"
                                  :data="getTimeFrameDateUser(i.time_frame)"
                                  :labels="getTimeFrameLabel"
                                  :colors="timelineColors"/>
              </v-col>

            </v-row>

            <v-row>
              <v-col md4 xs2>
                <LineChartAccountManagerSaleNoLegend height="200px" :title="'Sale %'" :data="getSalePerData(i)"
                                                     :label="getSalePerLabel(i)"></LineChartAccountManagerSaleNoLegend>
              </v-col>
            </v-row>

          </v-col>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog v-model="accountManagerStatsDialog">
      <v-card>

        <v-toolbar dark
                   dense
                   color="primary">
          {{ this.selectedAccountManagerName }}
          <v-spacer/>
          <v-card-actions>
            <v-btn
                text
                @click="accountManagerStatsDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-toolbar>

        <AccountManagerKpi :account-manager="selectedAccountManagerUsername"/>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {Helper as helper} from "@/helper";
import LineChartAccountManagerSaleNoLegend
  from "@/views/viz/components/accountManager/LineChartAccountManagerSaleNoLegend";
import PieChartOverview from "@/views/viz/components/accountManager/PieChartOverview";
import PieChart from "@/views/viz/components/PieChart";
import AccountManagerKpi from "@/views/viz/AccountManagerKpi";

export default {
  name: "AccountManagerGeneralOverview",
  components: {
    AccountManagerKpi,
    PieChart,
    PieChartOverview,
    LineChartAccountManagerSaleNoLegend
  },
  data: () => ({
    currDate: new Date().toISOString().substr(0, 10),
    accountManagerStatsDialog: false,
    selectedAccountManagerUsername: null,
    selectedAccountManagerName: null,
    loadingText: "Gegevens worden verzameld",
    dialogLoading: true,
    badgeShow: true,
    dailySale: [],
    timelineColors: [
      '#f56e22',
      '#57ac91',
      '#c77d94',
      '#9e84c9',
      '#458cff',
      '#458cff',
      '#1d4e9e'
    ],
    pipeLineLabels:
        ['Sale', 'In verwerking', 'Verzetten', 'Verwerkt', 'Cancelled'],
    pipeLineColors:
        [
          '#f56e22',
          '#57ac91',
          '#c77d94',
          '#9e84c9',
          '#458cff',
        ],
  }),
  beforeCreate() {
    this.dialogLoading = true;
  },
  async created() {
    await this.$store.dispatch('solar/fetchGeneralOverviewAccountManager');

    this.dailySale = this.$store.getters['solar/getGeneralOverviewAccoutManager'].user_overview.map(q => q.stats.daily_live_sale);

    setInterval(() => {
      this.$store.dispatch('solar/fetchGeneralOverviewAccountManager').then(resp => {
        let newList = resp.user_overview.map(q => q.stats.daily_live_sale);

        if (this.newListHasMoreDailyLiveSales(this.dailySale, newList)) {
          this.dailySale = newList;
          this.playSound();
        }
      });
    }, 600000); // 10 min

    this.dialogLoading = false;
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
    getUserOverview() {
      let b = this.$store.getters['solar/getGeneralOverviewAccoutManager'].user_overview;

      if (b) {
        b.sort(function (a, b) {
          if (a.stats.daily_live_sale || b.stats.daily_live_sale) {
            return b.stats.daily_live_sale - a.stats.daily_live_sale;
          }
          return b.stats.sale - a.stats.sale;
        });

        b.map(q => q.stats_monthly.pop())
      }

      return b;
    },
    getPipeLine() {
      let q = this.$store.getters['solar/getGeneralOverviewAccoutManager'].pipe_line;
      return q != null ? q : {};
    },
    getLiveSaleData() {
      let q = this.$store.getters['solar/getGeneralOverviewAccoutManager'].live_sale;

      if (q) {
        // remove current month so a decending line is not shown
        q.pop()

        let gg = {
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          fill: false,
          categoryPercentage: 0.2,
          barPercentage: 0.2,
          data: q.map(qq => qq.live_sale)
        }
        return [gg];
      }

      return [];
    },
    getLiveSaleLabel() {
      let q = this.$store.getters['solar/getGeneralOverviewAccoutManager'].live_sale;

      if (q) {


        return q.map(qq => new Date(qq.date).getFullYear() + " " + this.getMonthName(qq.date));
      }

      return null;
    },
    getTimeFrameDateAlgemeen() {
      let q = this.$store.getters['solar/getGeneralOverviewAccoutManager'].time_frame;

      let r = []

      if (q) {
        r.push(q.direct)
        r.push(q.one_week)
        r.push(q.one_two)
        r.push(q.two_three)
        r.push(q.three_four)
        r.push(q.four_eight)
        r.push(q.eight)
      }

      return r;
    },
    getTimeFrameLabel() {
      return ["Direct", "Binnen 1 week", "Tussen week 1 en week 2", "Tussen week 2 en week 3",
        "Tussen week 3 en week 4", "Tussen week 5 en week 8", "Ouder dan 8 weken"];
    },
  },
  methods: {
    getSalePerLabel(user) {
      let q = user.stats_monthly;

      if (q) {

        return q.map(qq => new Date(qq.date).getFullYear() + " " + this.getMonthName(qq.date));
      }

      return null;
    },
    getSalePerData(user) {
      let q = user.stats_monthly;

      if (q) {
        let gg = {
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          fill: false,
          categoryPercentage: 0.2,
          barPercentage: 0.2,
          data: q.map(qq => Number(qq.sale_per).toFixed(2))
        }

        return [gg];
      }

      return [];
    },
    playSound() {
      let audio = new Audio(require("@/assets/noti.mp3"));
      audio.play();
    },
    openAccountManagerPage(user) {
      this.accountManagerStatsDialog = true;
      this.selectedAccountManagerUsername = user.username;
      // this.selectedAccountManagerFirstName = user.user
      this.selectedAccountManagerName = user.user;

    },
    getMonthName(date) {
      return helper.extractMonth(date);
    },
    getTimeFrameDateUser(time_frame_data) {
      let q = time_frame_data;
      let r = [];

      if (q) {
        r.push(q.direct)
        r.push(q.one_week)
        r.push(q.one_two)
        r.push(q.two_three)
        r.push(q.three_four)
        r.push(q.four_eight)
        r.push(q.eight)
      }

      return r;
    },
    getOverallPipeLineData(q) {
      let r = []

      if (q) {
        r.push(q.sale)
        r.push(q.in_progress)
        r.push(q.verzetten)
        r.push(q.progressed)
        r.push(q.cancel_lead)
      }

      return r;
    },
    newListHasMoreDailyLiveSales(oldList, newList) {
      return Array.isArray(oldList) &&
          Array.isArray(newList) &&
          newList.reduce((a, b) => a + b, 0) > oldList.reduce((a, b) => a + b, 0);
    }
  }
}
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
}

.fix {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}

.dividerHeight {
  height: 110px;
}

#graphCard {
  background: rgba(255, 255, 255, 0.98);
  border-radius: 16px;
}

</style>
