<template>
  <v-container class="mt-0 pt-0">
    <v-date-picker
        v-model="picker"
        type="month"
        width="100%"
        @change="updateDate"
        no-title
    ></v-date-picker>

    <v-data-table v-if="this.getTeamDetailOverview"
                  :headers="headers"
                  :items="getTeamDetailOverview"
                  class="elevation-6"
                  item-key="user_id__team__team"
                  :search="search"
                  :custom-filter="filterText"
                  @item-expanded="updateTeam"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Zoeken"
            class="mx-4"
        ></v-text-field>
      </template>


      <template v-slot:item.netto="{item}">

        {{ item.netto }}

      </template>

      <template v-slot:item.days="{item}">

        {{ item.days }}

      </template>

      <template v-slot:item.afspraak="{item}">


        {{ item.afspraak }}

      </template>

      <template v-slot:item.sale="{item}">

        {{ item.sale }}

      </template>

      <template v-slot:item.afspraak_per="{item}">

        {{ roundPercentage(item.afspraak_per) }} %

      </template>

      <template v-slot:item.sale_per="{item}">

        {{ roundPercentage(item.sale_per) }} %

      </template>

      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">

        </td>
      </template>

      <template slot="body.append">

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}" class="font-weight-bold">
          <v-layout justify-center>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}" v-text="'Totaal'"/>
          </v-layout>

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
          <td class="font-weight-bold" v-text="'Netto: '"/>
          <v-layout justify-center>
            <v-chip>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                v-text="this.getTotalField('netto')"/>
                </v-chip>
          </v-layout>
        </tr>

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
          <td class="font-weight-bold" v-text="'Dagen: '"/>
          <v-layout justify-center>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                v-text="this.getTotalField('days')"/>
          </v-layout>
        </tr>

        <tr v-if="isMobile" :class="{'v-data-table__mobile-table-row':isMobile}">
          <td class="font-weight-bold" v-text="'Afspraken: '"/>
          <v-layout justify-center>
            <td :colspan="`${isMobile?6:1}`" :class="{'v-data-table__mobile-row':isMobile}"
                v-text="this.getTotalField('afspraak')"/>
          </v-layout>
        </tr>

        <tr v-if="this.isMobile" :class="{'v-data-table__mobile-table-row':this.isMobile}">
          <td class="font-weight-bold" v-text="'Sale: '"/>
          <v-layout justify-center>
            <td :colspan="`${this.isMobile?6:1}`" :class="{'v-data-table__mobile-row':this.isMobile}"
                v-text="this.getTotalField('sale')"/>
          </v-layout>
        </tr>

        <tr v-if="this.isMobile" :class="{'v-data-table__mobile-table-row':this.isMobile}">
          <td class="font-weight-bold" v-text="'Afspraak %: '"/>
          <v-layout justify-center>
            <td :colspan="`${this.isMobile?6:1}`" :class="{'v-data-table__mobile-row':this.isMobile}"
                v-text="Number(this.getTotalPer('afspraak')).toFixed(2)"/>
          </v-layout>
        </tr>

        <tr v-if="this.isMobile" :class="{'v-data-table__mobile-table-row':this.isMobile}">
          <td class="font-weight-bold" v-text="'Sale %: '"/>
          <v-layout justify-center>
            <td :colspan="`${this.isMobile?6:1}`" :class="{'v-data-table__mobile-row':this.isMobile}"
                v-text="Number(this.getTotalPer('sale')).toFixed(2)"/>
          </v-layout>
        </tr>

        <tr v-else>

          <td v-text="'Totaal'" class="font-weight-bold"/>

          <td  v-text="this.getTotalField('netto')"/>


          <td v-text="this.getTotalField('days')"/>


          <td v-text="this.getTotalField('afspraak')"/>

          <td v-text="this.getTotalField('sale')"/>

          <td v-text="Number(this.getTotalPer('afspraak')).toFixed(2)"/>

          <td v-text="Number(this.getTotalPer('sale')).toFixed(2)"/>


        </tr>

      </template>


    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: "TeamOverviewKpi",
  components: {},
  data() {
    return {
      expanded: [],
      curTeam: '',
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      singleExpand: true,
      search: '',
      headers: [
        {text: 'Team', value: 'user_id__team__team'},
        {text: 'Netto leads', value: 'netto'},
        {text: 'Aantal dagen', value: 'days'},
        {text: 'Afspraak', value: 'afspraak'},
        {text: 'Sale', value: 'sale'},
        {text: 'Afspraak %', value: 'afspraak_per'},
        {text: 'Sale %', value: 'sale_per'},
      ],
    }
  },
  async created() {
    let date = this.picker;
    await this.$store.dispatch("leads/getTeamOverviewKpi", {date});
  },
  computed: {
    getTeamDetailOverview() {
      return this.$store.getters['leads/teamOverviewKpi'].team_overview != null
          ? this.$store.getters['leads/teamOverviewKpi'].team_overview.details_overview
          : null;
    },
    getScores() {
      return this.$store.getters["leads/teamOverviewKpi"].team_overview != null
          ? this.$store.getters["leads/teamOverviewKpi"].team_overview
          : null;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === 'xs'
    },
  },
  methods: {
    roundPercentage(percentage) {
      return percentage !== null ? parseFloat(percentage).toFixed(2) : 0;
    },
    async refreshList(date) {
      await this.$store.dispatch("leads/getTeamOverviewKpi", {date});
    },
    filterText(value, search) {
      return search != null && typeof value === 'string' &&
          value.toString().toLocaleLowerCase().indexOf(search) !== -1
    },
    updateTeam(a) {
      this.curTeam = a.item.user_id__team__team;
      // update team details by date
      this.getTeamStats()
    },
    updateDate(a) {
      this.picker = a;
      this.refreshList(this.picker);
      this.getTeamStats();
    },
    getColorAvgDay(avgDayNum) {

      let a = this.getTeamDetailOverview;

      let max = Math.max.apply(Math, a.map(function (o) {
        return o.days;
      }))

      let min = Math.min.apply(Math, a.map(function (o) {
        return o.days;
      }))

      let avg = this.calcAverage(a.map(aa => aa.days))

      return this.calcColor(max, min, avg, avgDayNum);
    },
    async getTeamStats() {
      let date = this.picker;
      let team = this.curTeam;

      await this.$store.dispatch('leads/getTeamStats', {team, date});
    },
    getTotalField(fieldName) {
      return this.getTeamDetailOverview.map(q => q[fieldName]).reduce((a, b) => a + b, 0)
    },
    getTotalPer(fieldName){
      return (this.getTeamDetailOverview.map(q => q[fieldName]).reduce((a, b) => a + b, 0) * 100) / this.getTotalField('netto')
    }
  }
}
</script>

<style scoped>

</style>