<template>
  <div id="l">
    <iframe
        id="solarHan"
        title=""
        allowtransparency="true"
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTfqY9XGgIyVfFj0FMnvSKWB2ZXawnbxIyNm2sFaXafwMxrA9ZDH1ll6vILPo--Wz7nC62nwsRDqDN7/pubhtml?gid=1377584931&single=true"
        frameborder="0"
        style="width: 100%;
        height:100%;
        border:none;"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "OnlineLeads",
}
</script>

<style scoped>
#l{
 height: inherit;
}
</style>