import axios from 'axios';

// TODO: comment the other way around for production
const baseUrl = 'https://portaal.dakprofijt.nl/api';
// const baseUrl = 'http://127.0.0.1:8000/api';

const httpClient = axios.create({
    baseURL: baseUrl
});

const errorInterceptor = error => {
    // check if it's a server error
    if (!error.response) {
        return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
        case 400:
            console.error(error.response.status, error.message);
            break;

        case 401: // authentication error, logout the user
            localStorage.removeItem('user');
            window.location.href = "/login";
            break;

        case 404:
            return Promise.reject(error);

        case 500:
            return Promise.reject(error);

        default:
            console.error(error.response.status, error.message);
    }
    return Promise.reject(error);
}

const responseInterceptor = response => {
    switch (response.status) {
        case 200:
            break;
        // any other cases
        default:
        // default case
    }

    return response;
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;
