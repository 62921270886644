import authHeader from "@/services/auth-header";
import httpClient from "@/services/httpClient";

const endpoint = 'solar/';

class SolarService {
    postCsv(csv) {
        let config = authHeader();
        config['Content-Type'] = 'multipart/form-data;'
        httpClient.post(endpoint + 'upload',
            csv,
            {headers: config},
        )
            .then(response => {
                return response.data;
            })
    }

    async getGeoCords(mobile, start, end, currStatus) {
        return await httpClient
            .get(endpoint + 'geocords?limit=' + mobile + '&start_date=' + start + '&end_date=' + end + '&solar_keys=' + currStatus,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async forceUpdateLeadUpdateStatus() {
        return await httpClient
            .get(endpoint + 'forceLeadUpdate',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getLeadUpdateStatus() {
        return await httpClient
            .get(endpoint + 'leadUpdateStatus',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getKpiOverview(startDate, endDate) {
        return await httpClient
            .get(endpoint + 'kpiOverview?startDate=' + startDate + '&endDate=' + endDate,
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getKpiOverviewGlobal() {
        return await httpClient
            .get(endpoint + 'kpiOverviewGlobal',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getAccountManagerKpi(accountManager) {
        if (accountManager) {
            return await httpClient
                .get(endpoint + 'accountManagerKpi?accountManager=' + accountManager,
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        } else {
            return await httpClient
                .get(endpoint + 'accountManagerKpi',
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        }
    }

    async getTeleTeamKpi(user) {
        if (user) {
            return await httpClient
                .get(endpoint + 'teleTeamKpi?user=' + user,
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        } else {
            return await httpClient
                .get(endpoint + 'teleTeamKpi',
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        }
    }

    async getTeleTeamStats(user, date) {
        if (user) {
            return await httpClient
                .get(endpoint + 'teleTeamKpiStats?user=' + user + "&date=" + date,
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        } else {
            return await httpClient
                .get(endpoint + 'teleTeamKpiStats?date=' + date,
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        }
    }

    async getPipelineStats(name, date) {
        if (name) {
            return await httpClient
                .get(endpoint + 'pipelineStats?date=' + date + '&name=' + name,
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        } else {
            return await httpClient
                .get(endpoint + 'pipelineStats?date=' + date,
                    {headers: authHeader()})
                .then(response => {
                    return response.data;
                })
        }
    }

    async getActiveAccountManagers() {
        return await httpClient
            .get(endpoint + 'activeAccountManagers',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getActiveTeleTeamUsers() {
        return await httpClient
            .get(endpoint + 'activeTeleTeamUsers',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getAnalyzeAccountManager() {
        return await httpClient
            .get(endpoint + 'accountManagerAnalyze',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getGeneralOverviewAccountManager() {
        return await httpClient
            .get(endpoint + 'accountManagerGeneralOverview',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }

    async getTeleTeamOverview() {
        return await httpClient
            .get(endpoint + 'teleTeamGeneralOverview',
                {headers: authHeader()})
            .then(response => {
                return response.data;
            })
    }
}

export default new SolarService();