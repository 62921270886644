<template>
  <v-layout class="pl-5">

    <v-container v-if=duurzaamOverview id="overview" class="m-0 pr-0 pt-15 mt-7">
      <v-card class="rounded-2">
        <v-banner class="overview-text" single-line><v-icon class="overview-text">mdi-crown</v-icon>Most Valuable Players</v-banner>
        <v-list>
          <v-list-item v-for="i in mvps" :key="i.name">
            <v-list-item-title class="overview-text ma-1 pa-1" v-text="i.name"></v-list-item-title>
            <v-list-item-title class="overview-text" style="max-width: 50px" v-text="i.count"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
      <v-divider></v-divider>
      <v-card class="rounded-2">
        <v-banner  class="overview-text" single-line><v-icon>mdi-account-multiple</v-icon></v-banner>
        <v-list>
          <v-list-item v-for="i in rest" :key="i.name">
            <v-list-item-title class="ma-1 pa-1 overview-text-rest" v-text="i.name"></v-list-item-title>
            <v-list-item-title class="overview-text-rest" style="max-width: 50px" v-text="i.count"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>

    <v-container class="">
      <v-row v-if="isLoper">
        <v-col
            align="center"
            justify="center"
        >
          <lead-form :refTable="this.getRefTables"></lead-form>
        </v-col>
      </v-row>

      <v-row v-if="isDuurzaam">
        <div align="center">
          <v-row>
            <v-col
                cols="12"
                md="4"
            >

              <lead-form-duurzaam-zonnepaneel :refTable="this.getRefTables"></lead-form-duurzaam-zonnepaneel>
            </v-col>

            <v-col
                cols="12"
                md="4"
            >
              <lead-form-duurzaam-warmtepomp :ref-table="this.getRefTables"></lead-form-duurzaam-warmtepomp>
            </v-col>

            <v-col

                cols="12"
                md="4"
            >

              <lead-form-duurzaam-batterij :refTable="this.getRefTables"></lead-form-duurzaam-batterij>
            </v-col>
          </v-row>
        </div>
      </v-row>


      <v-banner class="pb-3" v-if=duurzaamOverview></v-banner>
      <v-banner v-if=!duurzaamOverview single-line rounded elevation="2" color="#FFFFFF8C"
                class="rounded black--text leadCountText" align="center">
        <h3>{{ leadsVanVandaagText }}: {{ totalLeads }}</h3>
      </v-banner>


      <v-row id="row">
        <lead-view v-for="lead in getNewCreatedLeads" :key="lead.postcode+lead.huisnummer" :lead="lead"></lead-view>
      </v-row>


    </v-container>
  </v-layout>
</template>
<script>


import LeadForm from "@/views/lead/LeadForm.vue";
import LeadFormDuurzaamWarmtepomp from "@/views/lead/LeadDuurzaamWarmtepomp.vue";
import LeadFormDuurzaamBatterij from "@/views/lead/LeadDuurzaamBatterij.vue";
import LeadFormDuurzaamZonnepaneel from "@/views/lead/LeadDuurzaamZonnepaneel.vue";
import LeadView from "@/views/lead/LeadView.vue";

export default {
  name: "Leads",
  components: {LeadView, LeadFormDuurzaamZonnepaneel, LeadFormDuurzaamBatterij, LeadFormDuurzaamWarmtepomp, LeadForm},

  async mounted() {
    await this.$store.dispatch('refTables/fetchAll');
    await this.$store.dispatch('leads/getAll')
    await this.$store.dispatch('leads/getDuurzaamCountOverview')
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/getUser"];
    },
    mvps(){
      return this.duurzaamOverview.slice(0, this.maxMvp).filter(a => a.count > 0);
    },
    rest(){
      return this.duurzaamOverview.filter(a => a.count > 0).filter(a => !this.mvps.includes(a));
    },
    duurzaamOverview() {
      let a = this.$store.getters["leads/getDuurzaamOverview"];

      if (a && a.duurzaam_overview) {
        return a.duurzaam_overview.sort(({count: a}, {count: b}) => b - a);
      }

      return null;

    },
    getRefTables() {
      return this.$store.getters["refTables/getRefs"];
    },
    getNewCreatedLeads() {
      let a = this.$store.getters["leads/leadList"];

      if(a.length){
        return a;
      }

      return null;
    },
    isLoper() {
      let user = this.currentUser;
      return user.groups != null ? user.groups.includes('lopers') : false;
    },
    isDuurzaam() {
      let user = this.currentUser;
      return user.groups != null ? user.groups.includes('duurzaam') : false;
    },
    totalLeads() {
      return this.$store.getters['leads/leadList'].length
    }
  },
  methods: {},
  data() {
    return {
      refTable: '',
      leadsVanVandaagText: 'Aantal leads vandaag',
      maxMvp: 2
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Poppins');

#row {

}

#overview {
  max-width: 21rem;
}

.leadCountText {
  font-family: Poppins;
}

.overview-text {
  font-family: Poppins;
  color: #2456e7;
}

.overview-text-rest {
  font-family: Poppins;
  color: #13293d;
}

</style>
