<template>
  <v-container class="mt-0 pt-0">
    <v-flex>
      <v-row justify="space-around">
        <v-col
            cols="10"
            sm="5"
        >
          <v-menu
              ref="menuStart"
              v-model="menuStart"
              :close-on-content-click="false"
              :return-value.sync="dateStart"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateStart"
                  label="Start datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateStart"
                type="month"
                no-title
                scrollable
                min="2020-04"
                :max="this.today"
                @input="menuStart = false; $refs.menuStart.save(dateStart); refreshListByDateRange()"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
            cols="10"
            sm="5"
        >
          <v-menu
              ref="menuEnd"
              v-model="menuEnd"
              :close-on-content-click="false"
              :return-value.sync="dateEnd"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateEnd"
                  label="Eind datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dateEnd"
                type="month"
                scrollable
                no-title
                :min="minDate"
                :max="this.today"
                @input="menuStart = false; $refs.menuEnd.save(dateEnd); refreshListByDateRange()"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-layout align-center justify-space-around row>
        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getFilteredOnDate.bruto }}
                  </div>
                  <h4 class="d-flex justify-start">Leads</h4>
                  <br/>
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-book-account-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ Number(getFilteredOnDate.netto_per).toFixed(2) }} %
                  </div>
                  <h4 class="d-flex justify-start">Netto Leads</h4>
                  {{ getFilteredOnDate.netto }} Totaal
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-book-check-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ Number(getFilteredOnDate.credit_per).toFixed(2) }} %
                  </div>
                  <h4 class="d-flex justify-start">Credit leads</h4>
                  {{ getFilteredOnDate.credit }} Totaal
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-book-cancel-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getFilteredOnDate.afspraken }}
                  </div>
                  <h4 class="d-flex justify-start">Afspraken</h4>
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-handshake-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getFilteredOnDate.sale }}
                  </div>
                  <h4 class="d-flex justify-start">Sales</h4>
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-briefcase-check-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-1 d-flex justify-start">
                    {{ getSalePer(getFilteredOnDate.afspraken, getFilteredOnDate.sale) }}
                  </div>
                  <h6 class="d-flex justify-start">Afspraak / Sales</h6>
                  <div class="primary--text display-1 d-flex justify-start">
                    {{ getSalePer(getFilteredOnDate.netto, getFilteredOnDate.sale) }}
                  </div>
                  <h6 class="d-flex justify-start">Netto lead / Sales</h6>
                </v-col>

                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-percent</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

      </v-layout>


      <v-container class="mt-0 pt-2">
        <v-divider/>
        <v-row class="text-muted justify-content-around pb-4">
          <v-col>
            Filter op basis van lead aangemaakt datum
          </v-col>
        </v-row>
        <v-data-table v-if="this.getProvince"
                      :headers="this.headersMonth"
                      :items="this.getProvince"
                      class="elevation-6"
                      item-key="lead_origin"
                      hide-default-footer
                      disable-pagination
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Provincies</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.netto_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.netto_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.afspraak_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.afspraak_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.afspraken_per="{item}">
            <v-chip
               outlined
            >
              {{ Number(item.afspraken_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.credit_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.credit_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.sale_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.sale_per).toFixed(2) }} %
            </v-chip>
          </template>

        </v-data-table>
      </v-container>

      <v-container class="mt-0 pt-0">

        <v-data-table v-if="this.getSources"
                      :headers="this.headersSource"
                      :items="this.getSources"
                      class="elevation-6"
                      item-key="lead_origin"
                      hide-default-footer
                      disable-pagination
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Bron</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.netto_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.netto_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.afspraken_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.afspraken_per).toFixed(2) }} %
            </v-chip>
          </template>
          <template v-slot:item.sale_per="{item}">
            <v-chip
                outlined
            >
           {{ Number(item.sale_per).toFixed(2) }} %
            </v-chip>
          </template>
          <template v-slot:item.credit_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.credit_per).toFixed(2) }} %
            </v-chip>
          </template>

<!--          <template v-slot:item.live_sale="{item}">-->
<!--            <span v-html="checkColor(item.live_sale)"></span>-->
<!--          </template>-->

        </v-data-table>
      </v-container>

      <v-container class="mt-0 pt-0">
        <v-data-table v-if="this.getGlobalTotalPerCampagne"
                      :headers="headersCampagne"
                      :items="getGlobalTotalPerCampagne"
                      class="elevation-6"
                      item-key="lead_origin"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Campagne</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.netto_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.netto_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.credit_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.credit_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.afspraken_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.afspraken_per).toFixed(2) }} %
            </v-chip>
          </template>

          <template v-slot:item.sale_per="{item}">
            <v-chip
                outlined
            >
              {{ Number(item.sale_per).toFixed(2) }} %
            </v-chip>
          </template>

        </v-data-table>
      </v-container>

    </v-flex>
  </v-container>
</template>

<script>
export default {
  name: "FilteredKpi",
  data: () => ({
    dateStart: "2020-04",
    dateEnd: new Date().toISOString().substr(0, 7),
    menuStart: false,
    menuEnd: false,
    today: new Date().toISOString().substr(0, 7),
    dialog: false,
    headersMonth: [
      {text: 'Provincie', value: 'province'},
      {text: '(Netto) Leads', value: 'netto'},
      // {text: 'Netto', value: 'netto'},
      {text: 'Netto %', value: 'netto_per'},
      {text: 'Credits', value: 'credit'},
      // {text: 'Credit %', value: 'credit_per'},
      {text: 'Afspraken', value: 'afspraken'},
       {text: 'Afspraak %', value: 'afspraken_per'},
      {text: 'Sale', value: 'sale'},
       {text: 'Sale %', value: 'sale_per'}
    ],
    headersSource: [
      {text: 'Bron', value: 'lead_origin'},
      // {text: 'Leads', value: 'bruto'},
      {text: '(Netto) Leads', value: 'netto'},
      {text: 'Netto %', value: 'netto_per'},
      {text: 'Credits', value: 'credit'},
      // {text: 'Credit %', value: 'credit_per'},
      {text: 'Afspraken', value: 'afspraken'},
      {text: 'Afspraak %', value: 'afspraken_per'},
      {text: 'Sale', value: 'sale'},
      {text: 'Sale %', value: 'sale_per'},
      // {text: 'Deze maand', value: 'live_sale'},
    ],
    headersCampagne: [
      {text: 'Website', value: 'campagne_id'},
      {text: 'Leads', value: 'bruto'},
      {text: '(Netto) Leads', value: 'netto'},
      {text: 'Netto %', value: 'netto_per'},
      {text: 'Credits', value: 'credit'},
      // {text: 'Credit %', value: 'credit_per'},
      {text: 'Afspraken', value: 'afspraken'},
      {text: 'Afspraak %', value: 'afspraken_per'},
      {text: 'Sale', value: 'sale'},
      {text: 'Sale %', value: 'sale_per'}
    ],
  }),
  computed: {
    minDate() {
      return this.dateStart;
    },
    getFilteredOnDate() {
      return this.$store.getters["solar/getKpiOverview"].leads_overview_filtered_on_date.leads_kpi;
    },
    getProvince() {
      return this.$store.getters["solar/getKpiOverview"].leads_overview_filtered_on_date.per_provincie;
    },
    getSources() {
      return this.$store.getters["solar/getKpiOverview"].leads_overview_filtered_on_date.per_source;
    },
    getGlobalTotalPerCampagne() {
      let t = this.$store.getters["solar/getKpiOverview"].leads_overview_filtered_on_date.per_campagne;
      return t != null ? t : 0;
    },
  },
  methods: {

    async refreshListByDateRange() {
      let startDate = this.dateStart;
      let endDate = this.dateEnd;
      await this.$store.dispatch("solar/fetchKpiOverview", {startDate, endDate});
    },
    checkColor(n){
      return  n  > 0 ? '<span style="color:#008000">⬆</span>' + n : n;
    },
    getSalePer(afspraken, sale){
      return Number((sale / afspraken) * 100).toFixed(2);
    }
  }
}
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
}

.icons {
  padding: inherit;
  margin-bottom: 25px;
  margin-left: 20px;
}

.persIcon {
  margin-left: 10%;
}
</style>
