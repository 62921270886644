<template>
  <v-container>
    <v-btn class="m-2" v-for="i in this.getUsers" :key="i.id" @click="insertXLead(i.first_name, i.last_name)">
      {{ i.first_name }} {{ i.last_name }}
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "WorkedWithoutLeads",
  mounted() {
    this.$store.dispatch('leads/getUsersPerTeamFilteredOnX');
  },
  computed: {
    getUsers() {
      let u = this.$store.getters["leads/getUsersFileteredOnX"];
      return u != null ? u.users : [];
    }
  },
  methods: {
    insertXLead(firstName, lastName) {
      this.$confirm("heeft geen geschreven leads vandaag", firstName + " " + lastName, 'confirm', "").then(() => {
        this.$store.dispatch('leads/registerUserWorkedWithoutLeads', {firstName, lastName});


      })
    }
  }
}
</script>

<style scoped>

</style>