import httpClient from "@/services/httpClient";
import authHeader from "@/services/auth-header";

class AuthService {
    async login(user) {

        let token;

        return await httpClient
            .post('auth/', {
                username: user.username,
                password: user.password
            })
            .then(response => {

                if (response.data.token) {
                    token = JSON.stringify(response.data);
                    localStorage.setItem('user', token);
                    return true;
                }
            });
    }

    async getUserData() {
        return await httpClient.get('auth/user', {headers: authHeader()})
            .then(res => {
            return res.data;
        })
    }

    logout() {
        localStorage.removeItem('user');
        window.location.href = '/' ;
    }
}

export default new AuthService();