<template>
  <v-container>
    <v-sheet class="d-inline-block ml-8"
             v-for="(item,i) in this.monthWeeklyDaily"
             :key="i"
    >
      <v-card-title>Week {{ i }}</v-card-title>
      <v-card-text align="center">
        <v-timeline
            dense
        >
          <v-timeline-item
              v-for="a in item"
              :key="a.creation_at__date"
              small
          >
            <div>
              <div class="font-weight-normal">
                {{ a.creation_at__date }}
              </div>
              <div>Lead(s): <strong>{{ a.leads }}</strong></div>
              <v-chip outlined @click="getCreditLeads(a.name, a.creation_at__date)">Credit(s): {{ a.credit }}</v-chip>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-banner>
            <span>
              Totaal: <strong>{{ getTotal(item) }}</strong>
            </span>
      </v-banner>
    </v-sheet>

    <v-dialog v-model="dialogCredit" :width="width">
      <v-data-table
          :headers="headersCredit"
          :items="getCreditsUser"
          class="elevation-6"
          item-key="id">

      </v-data-table>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "UserLeadStats",
  props: ['monthWeeklyDaily'],
  data: () => ({
    dialogCredit: false,
    headersCredit: [
      {text: 'Reden', value: "reden"},
    ]
  }),
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 350
        case 'sm':
          return 450
        case 'md':
          return 550
        case 'lg':
          return 700
        case 'xl':
          return 800
      }
      return 0;
    },
    getCreditsUser() {
      let creditList = this.$store.getters["leads/getCreditPerUser"];
      return creditList != null ? creditList : [];
    }
  },
    methods: {
      async getCreditLeads(user, date) {
        let period = 'today';
        await this.$store.dispatch("leads/getCreditPerUser",{user, date, period})
        this.dialogCredit = true;
      },
      getTotal(week) {
        let total = [];
        Object.entries(week).forEach(([key, val]) => {
          key
          total.push(val.leads) // the value of the current key.
        });

        return total.reduce(function (total, num) {
          return total + num
        }, 0);
      }
    }
  }

</script>

<style scoped>

</style>