<template>
  <div id="app">
    <v-app>
      <div v-if="(this.isIdle && this.currentUser && this.isLoper)">
        <Idle/>
      </div>

      <v-toolbar elevation="8" height="70" color="white" max-height="70">
        <a @click="$router.push('/')">
          <img class="mr-3" id="img-main" :src="require('../src/assets/new-logo-text.jpg')" height="40" alt=""/>
          <img class="mr-3" id="img-mobile" :src="require('../src/assets/logo-single.png')" height="60" alt=""/>
        </a>

        <div v-if="this.currentUser && this.currentUser.groups">
          <div v-if="this.isAdmin || this.isBeheer">
            <v-toolbar-title class="lead-text">
              {{ this.dashboardText }}
            </v-toolbar-title>
          </div>
          <div v-else-if="this.isCaptain">
            <v-toolbar-title class="lead-text">
              {{ this.captainDashboard }}
            </v-toolbar-title>
          </div>
          <div v-else-if="this.isLoper">
            <v-toolbar-title class="lead-text">
              {{ this.leadsText }}
            </v-toolbar-title>
          </div>
          <div v-else-if="this.isAccountManager">
            <v-toolbar-title class="lead-text">
              {{ this.accountManagers }}
            </v-toolbar-title>
          </div>
          <div v-else-if="this.isTeleTeam">
            <v-toolbar-title class="lead-text">
              {{ this.teleTeam }}
            </v-toolbar-title>
          </div>
        </div>

        <v-spacer/>
        <div v-if="this.currentUser && this.isAdmin">
            <Upload2SolarCsv/>
        </div>
        <v-spacer/>

        <div v-if="this.currentUser" class="navbar-nav ml-auto">

          <v-toolbar-items id="logout">

            <v-flex class="ma-2">
              {{ this.getFirstNameCurrentUser }} {{ this.getLastNameCurrentUser }}
            </v-flex>

            <v-icon @click="logOut()">mdi-export</v-icon>
          </v-toolbar-items>


        </div>
      </v-toolbar>

      <router-view/>

    </v-app>
    <v-footer color="#eee" padless>
      <v-col
          class="text-muted text-center"
          cols="12"
      >
       {{this.footerText}}
      </v-col>
    </v-footer>
  </div>
</template>

<script>

import Idle from "@/views/Idle";
import Upload2SolarCsv from "@/views/Upload2SolarCsv";

export default {
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        to, from,
            document.title = 'Dakprofijt - Dataportaal';
      }
    }
  },
  name: 'App',
  components: {Idle, Upload2SolarCsv},
  mounted() {
    // disable back to login screen if already logged in
    window.onpopstate = () => {
      if (window.localStorage.getItem("user") !== null) {
        this.$router.push("/");
      }
    };
  },
  async created() {
    if (this.currentUser) {
      await this.$store.dispatch('auth/getUserData');
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/getUser"];
    },
    isLoper() {
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('lopers') : false;
    },
    isAdmin() {
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('admin') : false;
    },
    isBeheer(){
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('admin') : false;
    },
    isCaptain(){
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('captain') : false;
    },
    isAccountManager(){
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('accountmngr') : false;
    },
    isTeleTeam(){
      let user = this.$store.getters["auth/getUser"];
      return user.groups != null ? user.groups.includes('teleteam') : false;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
    getFirstNameCurrentUser(){
      return this.currentUser.first_name;
    },
    getLastNameCurrentUser(){
      return this.currentUser.last_name;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$store.state.leads.leadList = [];
      this.$router.push('/login');
    },
  },
  data: () => ({
    footerText: "WRZ LLC © 2021 🔆",
    leadsText: 'Leads',
    dashboardText: 'Dashboard',
    captainDashboard:'Teams',
    teleTeam:'Tele Team',
    accountManagers:'Adviseur',
    dialog: false,
    file: null,
  })
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');

#app {
  background: url("assets/newbackground.jpeg") no-repeat;
  background-color: #161917;
  font-family: Poppins;
  background-size: cover;
}

/*.lead-text {*/
/*  color: #393939;*/
/*  font-family: raleway;*/
/*}*/

#img-main {
  display: block;
}

#img-mobile {
  display: none
}

@media all and (max-width: 499px) {
  #img-main {
    display: none;
  }

  #img-mobile {
    display: block;
  }

  #lead-text {
    display: none;
  }
}

</style>
