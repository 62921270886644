<template>
  <v-container class="mt-0 pt-0">
    <v-progress-linear
        :value ="progress"
        height="18"
        striped=true
        rounded=true

    >
      <v-card-subtitle>
      {{ Math.round(this.progress) }} %
      </v-card-subtitle>
    </v-progress-linear>
  </v-container>
</template>

<script>
export default {
  props: ['progress'],
  name: "MonthlyLeadProgressBar",
}
</script>

<style scoped>

</style>
