import Vue from 'vue';
import Vuex from 'vuex';

import {auth} from './auth.module';
import {refTables} from './refTables.module';
import {leads} from './lead.module';
import {solar} from "@/store/solar.module";
import {dashboard} from "@/store/dashboard.module";


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        refTables,
        leads,
        solar,
        dashboard
    }
});