<template>
  <div class="mt-0 pt-0 pb-0 mb-0">
    <v-layout align-center justify-space-around row >

      <v-flex class="pt-2 pb-2 mb-2 pl-13 pr-13" md12>
        <v-col  id="graphCard">

            <BarChartAccountManagerGeneral :data="getData('sale', 'general_overview')" :title="'Sale'"
                                           :labels="getLabel('general_overview')"/>
            <v-divider/>

        </v-col>
      </v-flex>


      <v-flex class="pt-2 pb-2 mb-2 pl-13 pr-13" md12>
        <v-col id="graphCard">

            <LineChartAccountManagerGeneralSale :title="'Sale %'" :data="getData('sale_per', 'general_overview')"
                                                :label="getLabel('general_overview')"></LineChartAccountManagerGeneralSale>
            <v-divider/>

        </v-col>
      </v-flex>



      <v-flex class="pt-2 pb-2 mb-2 pl-13 pr-13" md12>
        <v-col id="graphCard">

            <BarChartAccountManagerGeneral :data="getData('afspraken', 'general_overview')" :title="'Afspraken'"
                                           :labels="getLabel('general_overview')"/>
            <v-divider/>

        </v-col>
      </v-flex>



      <v-flex class="pt-2 pb-2 mb-2 pl-13 pr-13 mb-8" md12>
        <v-col id="graphCard">

            <LineChartAccountManagerGeneralSale :title="'Live Sale'" :data="getData('live_sale', 'live_monthly')"
                                                :label="getLabel('live_monthly')"></LineChartAccountManagerGeneralSale>

        </v-col>

      </v-flex>

    </v-layout>
  </div>
</template>

<script>


import BarChartAccountManagerGeneral from "@/views/viz/components/accountManager/BarChartAccountManagerGeneral";
import {Helper as helper} from "@/helper";
import LineChartAccountManagerGeneralSale from "@/views/viz/components/accountManager/LineChartAccountManagerGeneralSale";

export default {
  name: "AccountManagerAnalyzePage",
  components: { LineChartAccountManagerGeneralSale, BarChartAccountManagerGeneral},
  data: () => ({
    oo: [
      "#003f5c",
      "#2f4b7c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#ffa600"
    ],
    currDate: new Date().toISOString().substr(0, 10),
  }),
  async created() {
    await this.$store.dispatch('solar/fetchAnalyzeAccountManager');
  },
  computed: {

  },
  methods: {
    getLabel(endpoint) {
      let q = null

      if(endpoint === 'general_overview') {
        q = this.$store.getters['solar/getAccountMangerAnalyze'].general_overview;
      } else if(endpoint === 'live_monthly'){
        q = this.$store.getters['solar/getAccountMangerAnalyze'].live_monthly;
      }

      return this.contructLabels(q);
    },
    getMonthName(date) {
      return new Date(date).getFullYear() + " " + helper.extractMonth(date);
    },
    getData(option, endpoint) {
      let q = null;

      if(endpoint === 'general_overview') {
         q = this.$store.getters['solar/getAccountMangerAnalyze'].general_overview;
      } else if(endpoint === 'live_monthly'){
        q = this.$store.getters['solar/getAccountMangerAnalyze'].live_monthly;
      }

      return this.constructDate(q, option);
    },
    constructDate(q, option) {
      let cc = 0;
      let gg = [];
      if (q) {
        Object.entries(q.monthly).forEach(k => {
          let dataSet = [];

          k[1].forEach(a => {
            if (option.includes('_per')) {
              dataSet.push(Number(a[option]).toFixed(2));
            } else {
              dataSet.push(a[option]);
            }
          });

          let g = {
            label: k[0],
            data: dataSet,
            borderColor: this.oo[cc],
            backgroundColor: this.oo[cc],
            fill: false,
            categoryPercentage: 0.8,
            barPercentage: 0.8
          }

          gg.push(g);

          cc++;

        })
      }
      return gg;
    },
    contructLabels(q) {
      let dates = new Set()

      if (q) {
        Object.values(q.monthly).forEach(i => i.map(y => {
          dates.add(this.getMonthName(y.date));
        }));
      }

      return Array.from(dates);
    }
  }
}
</script>

<style scoped>
#graphCard {
  background: rgba(255, 255, 255, 0.96);
  border-radius: 16px;
}


.icons {
  padding: inherit;
  margin-bottom: 25px;
  margin-left: 20px;
}

.persIcon {
  margin-left: 10%;
}
</style>