import httpClient from "@/services/httpClient";
import authHeader from "@/services/auth-header";

const endpoint = "districts/";

class DistrictsService {
    async generateDistricts(addressCount, longitude, latitude, beforeDate) {
        return await httpClient.get(endpoint
            + 'generateDistricts?addressCount=' + addressCount
            + "&longitude=" + longitude
            + "&latitude=" + latitude
            + "&beforeDate=" + beforeDate,
            {headers: authHeader()})
        .then(res => res.data)
    }

    async excludeAddresses(addresses, beforeDate) {
        return await httpClient.post(endpoint + "excludeAddresses",
            {addresses: addresses, beforeDate: beforeDate},
            {headers: authHeader()})
            .then(res => res.data)
    }

    async clearExcludedAddresses(exclusionKey) {
        return await httpClient.post(endpoint + 'clearExcludedAddresses',
            {exclusionKey: exclusionKey},
            {headers: authHeader()})
            .then(res => res)
    }
}

export default new DistrictsService();