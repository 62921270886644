<template>
  <div>
    <v-container class="mt-0 pt-0">
      <v-layout align-center justify-space-around row class="pt-2 pb-3">

        <v-card-title class="text-muted">Overzicht {{ this.getMonthName(this.currDate) }}</v-card-title>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getKpi.sale }}
                  </div>

                  <h4 v-if="getKpi.sale <= 2" class="d-flex justify-start">Sale</h4>
                  <h4 v-else class="d-flex justify-start">Sales</h4>

                </v-col>
                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-book-account-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>


        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getKpi.afspraken }}
                  </div>

                  <h4 v-if="getKpi.afspraken < 1" class="d-flex justify-start">Afspraak</h4>
                  <h4 v-else class="d-flex justify-start">Afspraken</h4>

                </v-col>
                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-book-account-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getBonus }},-
                  </div>
                  <h4 class="d-flex justify-start">Bonus</h4>
                </v-col>
                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-currency-eur</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>


        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ getKpi.working_hours }}
                  </div>
                  <h4 class="d-flex justify-start">Gewerkte uren</h4>
                </v-col>
                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-account-clock-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex md4 class="p-2">
          <v-card elevation="10" class="rounded-card">
            <v-container fluid>
              <v-row>
                <v-col>
                  <div class="primary--text display-2 d-flex justify-start">
                    {{ Number(getKpi.conversie).toFixed(2) }} %
                  </div>
                  <h4 class="d-flex justify-start">Conversie</h4>
                </v-col>
                <v-col align-self="center" class="icons">
                  <v-icon size="48">mdi-account-convert-outline</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
      <v-divider/>
    </v-container>


    <v-layout align-center justify-space-around row class="pt-2 pb-2 mb-2 pl-13 pr-13 mb-8">

      <v-flex md8 justify="end">
        <v-flex md4>
          <v-card-title class="text-muted">Algemeen overzicht</v-card-title>
        </v-flex>
        <v-select filled dense rounded
                  v-model="selectedYear"
                  :items="getYears()"
                  label="Jaar"
        ></v-select>
      </v-flex>


      <v-flex md6>
        <v-col>
          <v-container v-if="this.getBarChartDataSale" id="graphCard">
            <BarChartTeleTeam :data="this.getBarChartDataSale" :label="this.getLabelChart" :title="'Sale'"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="getDataLineChartSalePer" id="graphCard">
            <LineChartTeleTeam :data="this.getDataLineChartSalePer" :label="this.getLabelChart" :title="'Sale %'"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="this.getBarChartDataAfspraken" id="graphCard">
            <BarChartTeleTeam :data="this.getBarChartDataAfspraken" :label="this.getLabelChart" :title="'Afspraken'"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-flex md6>
        <v-col>
          <v-container v-if="getDataLineChartSalePer" id="graphCard">
            <LineChartTeleTeam :data="this.getDataLineChartConversion" :label="this.getLabelChart"
                               :title="'Conversie'"/>
          </v-container>
        </v-col>
      </v-flex>

      <v-divider class="mt-3"/>
    </v-layout>


    <v-container class="mt-0 pt-4 mb-5">
      <v-card>
        <v-card-title class="text-muted">
          Maandelijks
        </v-card-title>
        <v-data-table v-if="this.getPerMonth"
                      :headers="headersMonth"
                      :items="getPerMonth"
                      class="elevation-6"
                      item-key="planning_date"
                      show-expand
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      @item-expanded="getTeleUser"
        >

          <template v-slot:header.bonus="{  }">
            <v-icon size="15">mdi-currency-eur</v-icon>
          </template>

          <template v-slot:item.planning_date="{item}">
            {{ getMonthName(item.planning_date) }}
          </template>

          <template v-slot:item.sale="{item}">

            {{ item.sale }}
            <span v-html="liveSaleArrowUp(item.live_sale)"></span>

          </template>

          <template v-slot:item.bonus="{item}">

            <v-chip outlined>
              {{ calcBonus(item.bonus) }},-
            </v-chip>


          </template>

          <template v-slot:item.sale_per="{item}">

            <v-chip outlined>
              {{ Number(item.sale_per).toFixed(2) }} %
            </v-chip>


          </template>

          <template v-slot:item.conversie="{item}">

            {{ Number(item.conversie).toFixed(2) }} %

          </template>


          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">

              <TeleTeamStats :monthWeeklyDaily="getStats"/>
            </td>
          </template>


        </v-data-table>
      </v-card>
    </v-container>


  </div>

</template>

<script>
import {Helper as helper} from "@/helper";
import TeleTeamStats from "@/views/viz/components/TeleTeamStats";
import BarChartTeleTeam from "@/views/viz/components/teleTeam/BarChartTeleTeam";
import LineChartTeleTeam from "@/views/viz/components/teleTeam/LineChartTeleTeam";


export default {
  name: "TelefonistKpi",
  components: {TeleTeamStats, BarChartTeleTeam, LineChartTeleTeam},
  props: ['teleUser'],
  data: () => ({
    expanded: [],
    currDate: new Date().toISOString().substr(0, 10),
    singleExpand: true,
    selectedYear: new Date().getFullYear(),
    headersMonth: [
      {text: 'Maand', value: 'planning_date'},
      {text: 'Afspraken', value: 'afspraken'},
      {text: 'Sales', value: 'sale'},
      {text: 'Sale % ', value: 'sale_per'},
      {text: 'Gewerkte dagen', value: 'working_days'},
      {text: 'Gewerkte uren', value: 'working_hours'},
      {text: 'Conversie', value: 'conversie'},
      {text: 'Bonus', value: 'bonus'},
      {text: 'Details', value: 'data-table-expand'},
    ],
  }),
  watch: {
    'teleUser'() {
      this.selectedYear = new Date().getFullYear();
      this.init();
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    getBarChartDataSale() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear).map(q => q.sale) : 0;
    },
    getBarChartDataAfspraken() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear).map(q => q.afspraken) : 0;
    },
    getLabelChart() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear).map(q => this.getMonthName(q.planning_date)) : 0;

    },
    getDataLineChartConversion() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;


      let gf = []
      if (q) {
        q.filter(qq => qq.jaar === this.selectedYear).map(q => gf.push(Number(q.conversie).toFixed(2)))

        gf = {
          data: gf,
          fill: false,
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          borderWidth: 1.3
        }
      }

      return Array.of(gf);
    },
    getDataLineChartSalePer() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;


      let gf = []
      if (q) {
        q.filter(qq => qq.jaar === this.selectedYear).map(q => gf.push(Number(q.sale_per).toFixed(2)))

        gf = {
          data: gf,
          fill: false,
          borderColor: '#8b4018',
          backgroundColor: '#ed6d28',
          borderWidth: 1.3
        }
      }

      return Array.of(gf);
    },

    getKpi() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].global_stats;
      return q != null ? q : 0;
    },
    getPerMonth() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;
      return q != null ? q.filter(qq => qq.jaar === this.selectedYear) : 0;
    },
    getBonus() {
      return this.getKpi.sale * 10;
    },
    getStats() {
      let q = this.$store.getters['solar/getTeleTeamStats'];

      return q != null ? q.stats : [];
    }

  },
  methods: {
    calcBonus(bonus) {
      return bonus * 10;
    },
    async init() {
      let teleUser = this.teleUser;
      if (teleUser) {
        await this.$store.dispatch("solar/fetchTeleTeamKpiByName", teleUser);
      } else {
        await this.$store.dispatch("solar/fetchTeleTeamKpi");
      }
    },
    getYears() {
      let q = this.$store.getters['solar/getTeleTeamKpi'].per_month;
      return q != null ? q.map(qq => new Date(qq.planning_date).getFullYear()) : [];
    },
    getMonthName(date) {
      return helper.extractMonth(date);
    },
    async getTeleUser(a) {
      let name = this.teleUser;
      let date = a.item.planning_date;

      if (name) {
        await this.$store.dispatch("solar/fetchTeleTeamStatsByName", {name, date});
      } else {
        await this.$store.dispatch("solar/fetchTeleTeamStats", {date});
      }

    },
    liveSaleArrowUp(n) {
      return n > 0 ? '<span style="color:#008000">⬆</span>' + "+" + n : null;
    },
  }
}
</script>

<style scoped>
.rounded-card {
  border-radius: 20px;
}

#graphCard {
  background: rgba(255, 255, 255, 0.96);
  border-radius: 16px;
}

/*#dialog{*/
/*  background: #f9f6f3d4;*/
/*}*/

.icons {
  padding: inherit;
  margin-bottom: 25px;

}
</style>