import RefTablesService from '../services/refTables.service';

export const refTables = {
    state: {
        allTables: []
    },
    namespaced: true,
    actions: {
        fetchAll({commit},) {
            return RefTablesService.getRefTables().then(
                tables => {
                    //this.allTables = tables;
                    commit('SET_REFS', tables);
                },
                error => {

                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        SET_REFS(state, tables) {
            state.allTables = tables;
        }
    },
    getters: {
        getRefs: state => {
            return state.allTables;
        }
    }
}