<script>

import {Line} from "vue-chartjs";

export default {
  extends: Line,
  name: "LineChartAccountManagerSaleNoLegend",
  props: ['data', 'label', 'title'],
  watch: {
    'data'() {
      this.render()
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
          {
            labels: this.label,
            datasets: this.data,
          }, {
            responsive: true,
            maintainAspectRatio: false,
            options: {
              scales: {
                yAxes: [{
                  gridLines: {
                    display: true
                  }
                }],
                xAxes: [{
                  gridLines: {
                    display: true
                  }
                }]
              },
              legend: {
                display: true
              },

            },
            title: {
              display: true,
              text: this.title,
              fontFamily: 'Poppins'
            },
            legend: {
              display: true,
              position: 'top',
              fullWidth: true,
              labels: {
                boxWidth: 10,
                padding: 10,
                fontFamily: 'Poppins'
              }
            },
            hover: {
              mode: 'index',
            },
          }
      );

    }
  }
}
</script>

<style scoped>

</style>



