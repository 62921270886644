<template>
  <div>
    <v-container>
      <v-flex>
        <v-dialog
            v-model="dialog"
            persistent
            width="350"
        >
          <v-card
              color="primary"
              dark
          >
            <v-card-title primary-title class="justify-center">
              {{ this.loadingText }}
              <v-progress-circular
                  :size="50"
                  dark
                  :width="5"
                  color="white"
                  indeterminate
              ></v-progress-circular>
            </v-card-title>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogWarning"
            width="350"
        >
          <v-card
              color="primary"
              dark
          >
            <v-card-title primary-title class="justify-center">
              {{ this.warning }}
            </v-card-title>
          </v-card>
        </v-dialog>
      </v-flex>

      <div v-if="this.isAdminOrBeheer && !this.isMobile" title class="d-flex">

        <v-row justify="space-around">
          <!--          <v-col cols="12"-->
          <!--                 sm="3"-->
          <!--                 class="p-1">-->
          <!--            <v-btn @click="showMarkerMap = !showMarkerMap">Markers</v-btn>-->
          <!--          </v-col>-->
          <v-col
              cols="12"
              sm="6"
              class="p-1"
          >
            <v-menu
                ref="menuStart"
                v-model="startMenu"
                :close-on-content-click="true"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="startDate"
                    label="Start datum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="startDate"
                  type="month"
                  no-title
                  scrollable
                  min="2020-04"
                  :max="this.today"
                  @input="startMenu = false;  $refs.menuStart.save(startDate); "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col

              cols="12"
              sm="6"
              class="p-1"
          >
            <v-menu
                ref="menuEnd"
                v-model="endMenu"
                :close-on-content-click="true"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="endDate"
                    label="Eind datum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="endDate"
                  type="month"
                  no-title
                  scrollable
                  min="2020-04"
                  :max="this.today"
                  @input="endMenu = false;  $refs.menuEnd.save(endDate); "
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
              cols="12"
              sm="4"
              class="p-1">

            <v-autocomplete
                v-model="currStatus"
                :items="this.getStatusses"
                dense
                chips
                clearable
                multiple
                deletable-chips
                small-chips
                class="ma-2"
                label="Lead status"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12"
                 sm="4"
                 class="p-1">
            <v-autocomplete
                v-model="currAdviseur"
                :items="this.getAdviseur"
                dense
                chips
                clearable
                multiple
                deletable-chips
                small-chips
                class="ma-2"
                label="Adviseur"
            ></v-autocomplete>

          </v-col>
          <v-col cols="12"
                 sm="4"
                 class="p-1">
            <v-autocomplete
                v-model="currBron"
                :items="this.getBron"
                dense
                chips
                clearable
                multiple
                deletable-chips
                small-chips
                class="ma-2"
                label="Bron"
            ></v-autocomplete>

          </v-col>
        </v-row>

        <!--      <v-autocomplete-->
        <!--          v-model="currOrigin"-->
        <!--          :items="this.getOrigin"-->
        <!--          dense-->
        <!--          chips-->
        <!--          clearable-->
        <!--          deletable-chips-->
        <!--          multiple-->
        <!--          small-chips-->
        <!--          class="ma-2"-->
        <!--          label="Lead origin"-->
        <!--      ></v-autocomplete>-->
      </div>
    </v-container>

    <div v-if="showMarkerMap">
      <GmapMap
          :center="this.center"
          :zoom="7"
          mapTypeId='hybrid'
          style='width:100%;  height: 800px;'
      >
        <GmapCluster
            :maxZoom="10"
            :zoomOnClick="true"
        >

          <GmapMarker
              :key="index"
              v-for="(m, index) in this.getGeo"
              :position="m.points"
              :clickable="true"
              :draggable="false"
              @click="openMarker(m.id)"
              @closeclick="openMarker(null)"
              :icon="m.icon"

          >
            <GmapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === m.id"
            >
              <div class="pb-2 pr-2">
                <div>
                  Lead datum: <b>{{ m.request_date }}</b>
                </div>
                <div v-if="m.datum_adviesgesprek && isAdminOrBeheer">
                  Adviesgesprek: <b>{{ m.datum_adviesgesprek }}</b>
                </div>
<!--                                        <div v-if="isAdminOrBeheer">-->
<!--                                          Laatst geüpdated: <b>{{ m.updated_at }}</b>-->
<!--                                        </div>-->
                <div>
                  Lead status: <b>{{ m.lead_status }}</b>
                </div>
<!--                                        <div>-->
<!--                                          Huisnummer: <b>{{ m.house_number }}</b>-->
<!--                                        </div>-->
<!--                                        <div>-->
<!--                                          Lead eigenaar: <b>{{ m.lead_owner }}</b>-->
<!--                                        </div>-->
                <div v-if="isAdminOrBeheer">
                  Adviseur: <b>{{ m.account_manager }}</b>
                </div>
                <div v-if="isAdminOrBeheer">
                  Bron: <b>{{ m.lead_origin }}</b>
                </div>
                <div v-if="isAdminOrBeheer">
                  SolarId:
                  <b @click="goToSolarWebsite(m.solar_id)">
                    <u>{{ m.solar_id }}</u>
                  </b>
                </div>

              </div>
            </GmapInfoWindow>
          </GmapMarker>
        </GmapCluster>
      </GmapMap>
    </div>

    <div v-if="!showMarkerMap">
      <div style='width:100%;  height: 900px;'>
        <vue-google-heatmap :points="this.getGeo"
                            :height="900"
                            :lat=center.lat
                            :lng=center.lng
                            :initial-zoom="8.2"
                            :map-type="mapType"
                            :options="{maxIntensity: 6.5}"
        />

      </div>
    </div>
  </div>
</template>

<script>
// import VueGoogleHeatmap from 'vue-google-heatmap';

export default {
  name: 'AnalyzeMap',
  components: {},
  data: () => ({
    startDate: "2024-01",
    showMarkerMap: true,
    mapType: "satellite",
    endDate: new Date().toISOString().substr(0, 7),
    startMenu: false,
    endMenu: false,
    today: new Date().toISOString().substr(0, 7),
    loadingText: "Markers worden ingeladen...",
    warning: "Niet beschikbaar op mobiel",
    openedMarkerID: null,
    dialog: false,
    dialogWarning: false,
    currStatus: [],
    currAdviseur: [],
    currBron: [],
    // currOrigin: [],
    center: {lat: 51.97765118, lng: 5.32806885}
  }),
  watch: {
    'startDate'() {
      this.getData()
    },
    'endDate'() {
      this.getData()
    },
    'currStatus'() {
      this.getData()
    },
    // 'currBron'() {
    //   this.getData()
    // },
    // 'currAdviseur'() {
    //   this.getData()
    // }
  },
  async mounted() {

  },
  async created() {
    this.dialog = true;

    if (this.$store.getters['solar/getGeoCords'].length === 0) {
      await this.getData();
      this.dialog = false;
    }

    if (this.isMobile) {
      this.dialogWarning = true;
    }

    this.dialog = false;
  },
  computed: {
    minDate() {
      return this.dateStart;
    },
    isMobile() {
      return this.$isMobile();
    },
    isAdminOrBeheer() {
      return this.$store.getters['auth/getUser'].groups.includes('admin', 'beheer');
    },
    getGeo() {
      if (this.isMobile) {
        return [];
      } else {

        let q = this.$store.getters['solar/getGeoCords'].geo;

        if (q) {
          if (this.showMarkerMap) {
            if (this.currAdviseur.length) {
              q = q.filter(qq => this.currAdviseur.includes(qq.account_manager));
            }

            if (this.currBron.length) {
              q = q.filter(qq => this.currBron.includes(qq.lead_origin));
            }

            return q;
          }

          if (!this.showMarkerMap) {
            if (this.currAdviseur.length) {
              q = q
                  .filter(qq => this.currAdviseur.includes(qq.account_manager))
                  .map(qq => qq.points);
            } else if (this.currBron.length) {
              q = q
                  .filter(qq => this.currBron.includes(qq.lead_origin))
                  .map(qq => qq.points);
            } else {
              q = q.map(qq => qq.points);
            }

            return q;
          }

        }
        return []
      }
    },
    getStatusses() {
      let q = this.$store.getters['solar/getGeoCords'];

      // if (q.geo) {
      //   if (this.currStatus.length) {
      //     q = q.geo.filter(qq => this.currStatus.includes(qq.lead_status));
      //   }
      //
      //   if (this.currAdviseur.length) {
      //     q = q.geo.filter(qq => this.currAdviseur.includes(qq.account_manager));
      //   }
      //
      //   if (this.currBron.length) {
      //     q = q.geo.filter(qq => this.currBron.includes(qq.lead_origin));
      //   }
      // }

      return q.solar_keys
    },
    // getOrigin() {
    //   let q = this.$store.getters['solar/getGeoCords'];
    //
    //   if (this.currStatus.length) {
    //     q = q.filter(qq => this.currStatus.includes(qq.lead_status));
    //
    //   }
    //
    //   if (this.currAdviseur.length) {
    //     q = q.filter(qq => this.currAdviseur.includes(qq.account_manager));
    //   }
    //
    //
    //   return q.filter(w => w.lead_origin != null).map(w => w.lead_origin);
    // },
    getAdviseur() {
      let q = this.$store.getters['solar/getGeoCords'].geo;
      if (q) {

        // if (this.currAdviseur.length) {
        //   console.log()
        //   q = q.filter(qq => this.currAdviseur.includes(qq.account_manager));
        // }
        //
        // if (this.currBron.length) {
        //   q = q.filter(qq => this.currBron.includes(qq.lead_origin));
        // }

        return q.filter(w => w.account_manager != null).map(w => w.account_manager);
      }
      return [];
    },
    getBron() {
      let q = this.$store.getters['solar/getGeoCords'].geo;
      if (q) {

        return q.filter(w => w.lead_origin != null).map(w => w.lead_origin);


        // if (this.currAdviseur.length) {
        //   q = q.geo.filter(qq => this.currAdviseur.includes(qq.account_manager));

        // }
      }

      return [];

    }
  },
  methods: {
    openMarker(id) {
      this.openedMarkerID = id;
    },
    goToSolarWebsite(id) {
      let url = 'https://dakprofijt.2solar.nl/profile/rid/' + id;
      window.open(url, '_blank').focus();
    },
    async getData() {
      let start = this.startDate;
      let end = this.endDate;
      let f = "false";
      let currStatus = this.currStatus;

      await this.$store.dispatch('solar/fetchGeoCords', {f, start, end, currStatus});
    }
  }
}
</script>
