<template>
  <div />
</template>

<script>
export default {
  name: "Idle",
  data() {
    return {
      time: 1000 * 30 * 2, // 1 min

    }
  },

  created() {
    this.$fire({
      icon: 'error',
      text: 'Je wordt binnen 1 minuut uitgelogd..',
      confirmButtonColor: this.$vuetify.theme.themes.light.primary,
      confirmButtonText: 'Ingelogd blijven'
    }).then(() => {
    })
    const timerId = setInterval(() => {
      this.time -= 1000;

      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

      if (this.time < 1) {
        clearInterval(timerId);

        this.$store.dispatch('auth/logout');
        this.$router.push('login')
        this.$fire({
          icon: 'error',
          text: 'Inactief voor te lange tijd.. Je bent uitgelogd..',
          confirmButtonColor: this.$vuetify.theme.themes.light.primary,
          confirmButtonText: 'OK'
        }).then(() => {

        })

      }
    }, 1000);
  }
};
</script>