<script>

import {Bar} from "vue-chartjs";

export default {
  extends: Bar,
  name: "BarChartTeleTeamNoLegend",
  props: ['data', 'label', 'title'],
  watch: {
    'data'() {
      this.render()
    }
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      this.renderChart(
          {
            labels: this.label,
            datasets: this.data,
          },
          {
            responsive: true, maintainAspectRatio: false,
            scales: {
              xAxes: [{
                categoryPercentage: 0.6,
                barPercentage: 0.6
              }],
              yAxes: [{
                ticks: {
                  stepSize: 5,
                }
              }]
            },
            tooltips: {
              mode: 'index',
              enabled: true,
              titleAlign: 'center',
              footerAlign: 'center'
            },
            title: {
              display: true,
              text: this.title,
              fontFamily: 'Poppins'
            },
            legend: {
              onClick: (e) => e.stopPropagation(),
              display: false,
              position: 'bottom',
              fullWidth: true,
              labels: {
                boxWidth: 10,
                padding: 10,
                fontFamily: 'Poppins'
              }
            }
          }
      );

    }
  }
}
</script>

<style scoped>

</style>
