import Dashboard from '../services/dashboard.service'

export const dashboard = {
    state: {
        quote : {}
    },
    namespaced: true,
    actions: {
        fetchDailyQuotes({commit},) {
            return Dashboard.getDailyQuote().then(
                d => {
                    commit('SET_QUOTE', d);
                    return Promise.resolve(d);
                }
            )
        },
    },
    mutations: {
        SET_QUOTE(state, quote){
            state.quote = quote;
        }

    },
    getters: {
        getQuote: state => {
            return state.quote;
        }
    }
}