<script>
import {Bar} from "vue-chartjs";

export default {
  extends: Bar,
  props: ['progress'],
  mounted() {
    this.renderChart(
        {
          labels: this.progress[0],
          datasets: this.progress[1]
        },
        {
          responsive: true, maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
              categoryPercentage: 0.4,
              barPercentage: 0.8
            }],
            yAxes: [{
              stacked: true
            }],
          }, tooltips: {
            mode: 'index',
            enabled: true,
            titleAlign: 'center',
            footerAlign: 'center',
            bodyFontFamily: 'Poppins',
            callbacks: {
              footer: ((tooltipItems, datas) => {
                let idx = datas.labels.indexOf(tooltipItems[0].label);
                return "Totaal:" + " " + datas.datasets
                    .flatMap(f => f.data[idx])
                    .filter(f => f != null)
                    .reduce((a, b) => a + b, 0);
              })
            },
          }
        }
    );
  }
};
</script>
<style scoped>

</style>
