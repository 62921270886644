<template>
  <div>
    <v-form ref="districtMakerForm" lazy-validation>
      <v-row
          justify="space-around"
          class="input-row">
        <v-col
            cols="1"
            sm="4"
            class="p-1"
        >
          <div>Hier kun je een gegenereerde wijk verwijderen. Kopieer de code linksboven in het gegenereerde document en plak deze in het tekstveld.</div>
        </v-col>
        <v-col
            cols="1"
            sm="4"
            class="p-1"
        >
          <v-text-field
              v-model="exclusionKey"
              :rules="exclusionKeyRules"
              label="Code"
          ></v-text-field>
        </v-col>
        <v-col
            cols="1"
            sm="2"
            class="p-1"
        >
          <v-btn
              id="queryButton"
              elevation="2"
              large
              color="primary"
              :disabled="!exclusionKey"
              @click="deleteDistrict()"
          >
            {{"Verwijder wijk"}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DistrictsService from "@/services/districts.service.js";

export default {
  name: "RemoveDistrict",
  components: {},
  data: () => ({
    exclusionKey: "",
    exclusionKeyRules: [
      v => !!v || "Code staat bovenin het pdf-bestand",
      v => {
        const regex = /^[a-zA-Z0-9-]{3,}$/;
        return regex.test(v) ? true : "Ongeldige code. Controleer op spaties";
      }
    ],
  }),
  methods: {
    deleteDistrict() {
      DistrictsService.clearExcludedAddresses(this.exclusionKey)
          .then(() => {
            this.$fire({
              position: 'top-end',
              type: 'warning',
              text: 'De wijk is verwijderd',
              showConfirmButton: false,
              timer: 2000
            })
          }).catch(error => {
        this.generatingDocument = false;
        this.$fire({
          position: 'top-end',
          type: 'warning',
          text: 'Er is iets fout gegaan.',
          showConfirmButton: false,
          timer: 2000
        })
        console.log(error);
      })
    }
  }
}
</script>

<style scoped>

</style>