import authHeader from './auth-header';
import httpClient from "@/services/httpClient";

const refTables = 'ref_tables/';


class RefTablesService {
    async getRefTables() {
        return await httpClient.get(refTables + 'refs', {headers: authHeader()}).then(res => {return res.data});
    }
}

export default new RefTablesService();